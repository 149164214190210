@import "../../../_mixins/variables";
@import "../../../_mixins/mixins";

.DepartmentsSettings {
  .table {
    tbody {
      tr {
        &.add {
          background-color: rgba($color-secundary, 0.1);
        }
        &.editing {
          background-color: rgba($color-primary, 0.05);
        }
      }
    }
    th, td {
      text-align: center;
      &:first-child {
        text-align: left;
      }
    }

    th {
      &.name {
        width: 20vw;
      }
      &.office_phone {
        width: 20vw;
      }
      &.target_hourly_rate {
        width: 15vw;
      }
      &.company_code {
        width: 15vw;
      }
      &.state {
        width: 10vw;
      }
      &.division {
        width: 10vw;
      }
      &.city_code {
        width: 10vw;
      }
    }

    td {
      vertical-align: middle;
      position: relative;
      .form-group {
        margin: 0;
      }
    }

    .input-group {
      flex-wrap: inherit;
    }
    .input-group-prepend {
      .input-group-text {
        font-size: 0.85rem;
        line-height: 1;
      }
    }
  }
  .btn {
    display: block;
    &:focus {
      outline: none;
      box-shadow: none;
    }
    &.btn-add {
      padding-top: 0.2rem;
      padding-bottom: 0.14rem;
      &:disabled,
      &.disabled {
        opacity: 0.4;
      }
    }
    &.btn-save {
      color: $secundary-font-color;
      margin-right: 0.2rem;
      &:hover {
        color: $color-white;
        background-color: $secundary-font-color;
      }
      &:disabled,
      &.disabled {
        color: $secundary-font-color;
        background-color: transparent;
        opacity: 0.4;
      }
    }
    &.btn-cancel {
      color: rgba($primary-font-color, 0.35);
      font-size: 0.7rem;
      padding: 0.25rem 0.35rem;
      &:hover {
        color: $primary-font-color;
      }
    }
  }
}