@import "../../_mixins/variables";
@import "../../_mixins/mixins";

.table.events {
  th,
  td {
    text-align: center;
  }
  td {
    vertical-align: middle;
  }

  .name {
    text-align: left;
    width: 20%;
  }
  .customer,
  .venue {
    text-align: left;
    width: 12%;
  }
  .department,
  .event_number,
  .start_date,
  .status {
    text-align: left;
    width: 10%;
  }
  .number_days,
  .staff_totals {
    text-align: center;
    width: 5%;
  }
  .clone,
  .edit,
  .delete {
    width: 3%;
  }
}

.btn-add {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0.15em 0 0;
  width: 31px;
  height: 31px;
}

.events-dates-form {
  margin: .25em 0 0;
  .form-group {
    margin: 0;
    width: 46%;
  }
  .btn-add {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.15em 0 0;
    width: 35px;
    height: 35px;
  }
}

.filter-by-status {
  vertical-align: top;
  min-width: 200px;
  min-height: 50px;
}

.filter-by-section {
  vertical-align: top;
  min-width: 200px;
  min-height: 50px;
  margin-left: 32px;
}
