@import "../../../_mixins/variables";
@import "../../../_mixins/mixins";

.events-calendar-page {
  display: flex;
  flex-direction: column;
}

.calendar-page-body {
  h2 {
    text-align: center;
    color: $color-primary-dark;
  }
  .calendar-container {
    .calendar-header {
      float: left;
      padding: 0;
      width: 14.25%;
      text-align: center;
      color: $color-secundary;
      font-weight: 600;
    }
    .calendar-day-wide {
      float: left;
    }
    .wide-1 {
      width: 14.25%;
    }
    .wide-2 {
      width: 28.5%;
    }
    .wide-3 {
      width: 42.75%;
    }
    .wide-4 {
      width: 57%;
    }
    .wide-5 {
      width: 71.25%;
    }
    .wide-6 {
      width: 85.5%;
    }

    .current-day {
      background-color: $calendar-current-day;
    }
    .calendar-day {
      float: left;
      height: 240px;
      width: 14.25%;
      border: 1px solid $color-gray-lighter;

      .date {
        text-align: right;
        font-size: medium;
        font-weight: bolder;
        margin-top: 5px;
        margin-right: 5px;
      }
      .events {
        margin-bottom: 4px;
        a {
          color: black;
          font-weight: 600;
        }
        .department {
          float: left;
          width: 48%;
          padding: 4px;
          margin: 2px;
        }
        .labels {
          font-size: smaller;
          font-weight: 600;
          min-height: 16px;
          .dept {
           float: left; 
          }
          .status {
            float:right;
          }
        }
        .portland {
          background-color: $calendar-color-portland;
        }
        .seattle {
          background-color: $calendar-color-seattle;
        }
        .montana {
          background-color: $calendar-color-montana;
        }
        .eugene {
          background-color: $calendar-color-eugene;
        }
        .yakima {
          background-color: $calendar-color-yakima;
        }
        .spokane {
          background-color: $calendar-color-spokane;
        }
        .alaska {
          background-color: $calendar-color-alaska;
        }
        .corporate {
          background-color: $calendar-color-corporate;
        }
      }
      .view-all {
        font-size: smaller;
        text-align: right;
        font-weight: 200;
        
        .btn {
          padding: 0;
        }
      }
    }
  }
}

.modal-events-dates {
  .modal-body {
    .events {
      max-height: 800px;

      a {
        color: black;
        font-weight: 600;
        font-size:medium;
      }
      .department {
        padding: 10px;
        margin: 2px;
        width: 210px;
        float: left;
        min-height: 160px;
      }
      .labels {
        font-size: smaller;
        font-weight: 600;
        min-height: 16px;
        .dept {
         float: left; 
        }
        .status {
          float:right;
        }
      }
      .portland {
        background-color: $calendar-color-portland;
      }
      .seattle {
        background-color: $calendar-color-seattle;
      }
      .montana {
        background-color: $calendar-color-montana;
      }
      .eugene {
        background-color: $calendar-color-eugene;
      }
      .yakima {
        background-color: $calendar-color-yakima;
      }
      .spokane {
        background-color: $calendar-color-spokane;
      }
      .alaska {
        background-color: $calendar-color-alaska;
      }
      .corporate {
        background-color: $calendar-color-corporate;
      }
    }
  }
}
