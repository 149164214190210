@import "../../../_mixins/variables";
@import "../../../_mixins/mixins";

.preview-container {
    position: absolute;
    top: 0;
    left: 130px;
    float: right;
    border-color: transparent;
    width: 20px;
}

.fa-eye {
    color: rgba($color-primary, 0.7);
    cursor: pointer;
    height: 2.5rem;
    width: 2.5rem;
    font-size: 1rem;
    &:hover {
      color: $color-primary;
    }
  }
