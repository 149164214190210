@import "../../../_mixins/variables";
@import "../../../_mixins/mixins";

.notes-manager {
  .loader {
    color: $color-primary;
    font-size: 2rem;
    position: absolute;
    top: 30vh;
    left: 50%;
    width: 50px;
    text-align: center;
    margin-left: -25px;
  }

  .note-form {
    min-height: 388px;
    .form-group {
      &:first-child {
        margin-bottom: 1rem;
      }
    }
    .btn-cancel {
      margin-right: 0.85rem;
    }
  }

  .note-view {
    background-color: lighten($color-primary, 40);
    position: relative;
    padding: 1.8rem 1.5rem;
    min-height: 333px;

    &::before {
      content: '';
      background-color: $color-white;
      height: 2.3rem;
      width: 2.3rem;
      display: block;
      right: 0;
      top: 0;
      position: absolute;
      box-shadow: -2px 2px 2px rgba($color-black, 0.07);
      @include linear-gradient(45deg, lighten($color-primary, 40) 0%, lighten($color-primary, 40) 50%, $color-white 50%);
    }
    .title {
      font-size: 1.6rem;
    }
    .data {
      font-size: 1.25rem;
      font-weight: 300;
    }
    .date {
      font-size: 0.8rem;
      font-weight: 300;
      margin: 0 0 0.25rem;
    }
    .note-console {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      
      .author-info {
        padding-left: 1rem;
        font-weight: 300;
        font-size: 0.8rem;
        font-style: italic;
        b {
          font-weight: 400;
        }
      }
    }
    .fa-pencil {
      color: rgba($primary-font-color, 0.7);
      cursor: pointer;
      height: 2.5rem;
      width: 2.5rem;
      font-size: 1rem;
      &:hover {
        color: $primary-font-color;
      }
    }
  }

  .note-view-console {
    padding: 0.5rem 0;
  }
  .btn-create-note {
    color: rgba($primary-font-color, 0.7);
    i {
      margin-right: 0.25rem;
    }
    &:hover {
      color: $primary-font-color;
    }
  }

  .notes-card {
    text-align: center;
    padding: 0;
    position: relative;
    margin: 0 0.75rem 1.5rem;
    width: 39vw;
    min-height: 170px;
    @include respondTo-xsmall {
      width: 210px;
    }
    @include respondTo-small {
      width: 205px;
    }
    @include respondTo-large {
      width: 195px;
      width: 12vw;
    }
    @include respondTo-xlarge {
      width: 200px;
      width: 8.8vw;
    }
    &:hover,
    &.active {
      border-color: $color-primary; 
      background-color: rgba($color-primary, 0.05); 
    }
    p {
      margin: 0;
    }
    .fa-trash {
      color: rgba($color-gray, 0.85);
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      &:hover {
        color: $color-danger;
        cursor: pointer;
      }
    }
    .file-icon {
      color: lighten($primary-font-color, 20);
      font-size: 3rem;
      font-weight: 100;
      line-height: 1.3;
    }
    .file-name {
      font-weight: 600;
    }
    .file-description {
      font-size: 0.8rem;
      font-style: italic;
      min-height: 1.1rem;
    }
    .file-date {
      font-size: 0.75rem;
      font-weight: 300;
      margin: 0.2rem 0;
    }

    .file-info {
      cursor: pointer;
      height: 100%;
    }
  }
}