@import "../../_mixins/variables";
@import "../../_mixins/mixins";

.navbar {
  padding: 0 0 0.15rem;
  .navbar-brand {
    width: 70px;
    margin:0 0.5rem 0 0.65rem;
  }
  .navbar-content-left {
    margin-right: 0.8rem;

    .btn {
      background-color: transparent;
      color: $secundary-font-color;
    }

    .btn-success {
      background-color: transparent;
      border-color: transparent;
    }

    .btn-success:not(:disabled):not(.disabled):active:focus, 
    .btn-success:not(:disabled):not(.disabled).active:focus, 
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0;
    }
  }

  .navbar-nav { 
    flex-direction: row;
    .nav-item {
      font-size: 0.95rem;
      color: $color-secundary;
      line-height: 2.8rem;
      padding: 0.5rem 0.75rem 0;
      border-bottom: 0.15rem solid transparent;
      transition: all 0.4s;
      &:hover,
      &.active {
        color: $color-primary;
      }
    }
  }

  .profile-nav {
    .profile-nav-item {
      color: $secundary-font-color;
      display: inline-block;
      margin: 0 0.5rem;

      &.profile {

        span {
          display: inline-block;
        }
        .profile-name {
          font-size: 0.95rem;
          color: $secundary-font-color;
        }
        .profile-avatar {
          width: 1.6rem;
          margin-left: 0.5rem;
        }
      }

      &.logout {
        color: $color-white;
        background-color: rgba($color-secundary, 0.5);
        border-radius: 50%;
        margin: 0;
        text-align: center;
        width: 1.98rem;
        font-size: 0.98rem;
        line-height: 1.98rem;
        transition: background-color 0.8s;
        &:hover {
          background-color: $color-primary;
        }

      }
    }
  }
}

