
	@mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
    }

    @mixin transition($transition...) {
    -webkit-transition: $transition;
    -moz-transition: $transition;
    -ms-transition: $transition;
    -o-transition: $transition;
    transition: $transition;
    }

    @mixin keyframes($animation-name) {
      @-webkit-keyframes #{$animation-name} {
          @content;
      }
      @-moz-keyframes #{$animation-name} {
          @content;
      }
      @-ms-keyframes #{$animation-name} {
          @content;
      }
      @-o-keyframes #{$animation-name} {
          @content;
      }
      @keyframes #{$animation-name} {
          @content;
      }
    }

    @mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};
    }

    @mixin box-shadow($shadow...) {
    -moz-box-shadow:$shadow;
    -webkit-box-shadow: $shadow;
    box-shadow: $shadow;
    }

    // ==========================================================================
    //  LINEAR GRADIENT
    // ==========================================================================
      @mixin linear-gradient($direction, $color-stops...) {
        // Direction has been omitted and happens to be a color-stop
        @if is-direction($direction) == false {
          $color-stops: $direction, $color-stops;
          $direction: 180deg;
        }

        background: nth(nth($color-stops, 1), 1);
        background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
        background: linear-gradient($direction, $color-stops);
      }
      /// Test if `$value` is a valid direction
      /// @param {*} $value - Value to test
      /// @return {Bool}
      @function is-direction($value) {
        $is-keyword: index((to top, to top right, to right top, to right, to bottom right, to right bottom, to bottom, to bottom left, to left bottom, to left, to left top, to top left), $value);
        $is-angle: type-of($value) == 'number' and index('deg' 'grad' 'turn' 'rad', unit($value));

        @return $is-keyword or $is-angle;
      }
      /// Convert a direction to legacy syntax
      /// @param {Keyword | Angle} $value - Value to convert
      /// @require {function} is-direction
      /// @require {function} convert-angle
      /// @throw Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be a direction.;
      @function legacy-direction($value) {
        @if is-direction($value) == false {
          @error "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be a direction.";
        }

        $conversion-map: (
          to top          : bottom,
          to top right    : bottom left,
          to right top    : left bottom,
          to right        : left,
          to bottom right : top left,
          to right bottom : left top,
          to bottom       : top,
          to bottom left  : top right,
          to left bottom  : right top,
          to left         : right,
          to left top     : right bottom,
          to top left     : bottom right
        );

        @if map-has-key($conversion-map, $value) {
          @return map-get($conversion-map, $value);
        }

        @return 90deg - $value;
      }

    // ==========================================================================
    // BREAKPOINT
    // ==========================================================================

      $respondTo-xsmall:      480px;
      $respondTo-max-small:   767px;
      $respondTo-small:       768px;
      $respondTo-max-medium:  991px;
      $respondTo-medium:      992px;
      $respondTo-max-large:   1199px;
      $respondTo-large:       1200px;
      $respondTo-xlarge:      1900px;

      @mixin respondTo-xsmall {
        @media (min-width: $respondTo-xsmall) {
          @content;
        }
      }
      @mixin respondTo-small {
        @media (min-width: $respondTo-small) {
          @content;
        }
      }
      @mixin respondTo-medium {
        @media (min-width: $respondTo-medium) {
          @content;
        }
      }
      @mixin respondTo-large {
        @media (min-width: $respondTo-large) {
          @content;
        }
      }
      @mixin respondTo-xlarge {
        @media (min-width: $respondTo-xlarge) {
          @content;
        }
      }
      @mixin respondTo-max-small {
        @media (max-width: $respondTo-max-small) {
          @content;
        }
      }
      @mixin respondTo-max-medium {
        @media (max-width: $respondTo-max-medium) {
          @content;
        }
      }
      @mixin respondTo-xsmall-small {
        @media (min-width:$respondTo-xsmall) and (max-width:$respondTo-max-small){
          @content;
        }
      }
      @mixin respondTo-medium-large {
        @media (min-width:$respondTo-medium) and (max-width:$respondTo-max-large){
          @content;
        }
      }

      @mixin respondTo-mobile-landscape {
        @media (min-width:480px) and (max-width:850px) and (orientation : landscape){
          @content;
        }
      }

    // ==========================================================================
    // HEADING PRPERTIES
    // ==========================================================================

      @mixin heading_properties($font-size:initial) {
        margin: 0 0 0.42em;
        font-size: $font-size;
        color: $color-main;
        line-height: 1.3;
      }


