@import "../../../_mixins/variables";
@import "../../../_mixins/mixins";

.MessagesSettings {
  .form-wrapper {
    padding: 2rem 1rem;
  }

  .table.messages {
    tbody {
      tr {
        &.add {
          background-color: rgba($color-secundary, 0.1);
        }
        &.editing {
          background-color: rgba($color-primary, 0.05);
        }
      }
    }
    th,
    td {
      text-align: center;
    }
    tr.inactive {
      background-color: $color-accent;
    }
    td {
      vertical-align: top;
    }

    .title {
      text-align: left;
      width: 15%;
    }
    .content {
      text-align: left;
      width: 50%;
    }
    .background_color, .text_color {
      width: 8%;
    }
    .start_date, .end_date {
      width: 10%;
    }

    .edit,
    .delete {
      width: 3rem;
    }
  }
}