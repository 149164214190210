
@import "../../../_mixins/variables";
@import "../../../_mixins/mixins";

.ActivityHistory {
  .ActivityHistoryTable {
    th,
    td {
      &.created_date {
        width: 10%; 
      }
      &.user {
        width: 15%;
      }
      &.section {
        width: 10%;
      }
      &.subsection {
        width: 15%;
      }
      &.activity {
        width: 50%;
      }
    }
  }

  .table-console-left {
    padding: 0 0 1rem;
  }
}
