$primary-color-dark:   #0080C4;
$primary-color-light:  #8B909E;
$divider-color:        #F2F2F2;
$header-color: #AAAAAA;
$primary-button-color: #77BF66;
$secondary-text-color2:  #9A9A9A;
$position-event-border: #B5BBCD;

// *******************
// v2
// *******************
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i');
$primary-font-family: 'Source Sans Pro', sans-serif;

$primary-font-color: #4A4A4A;
$secundary-font-color: #6B779B;

$color-primary: #00B5F3;
$color-primary-dark:#0080C4 ;
$color-secundary: #6B779B;
$color-terciary: #ff8341;
$color-white: #FFFFFF;
$color-black: #000000;
$color-gray: #808080;
$color-gray-light: #F2F2F2;
$color-gray-lighter: #dee2e6;
$color-gray-lightest: #F9FAFA;
$color-red: #ff0000;
$color-green: #77BF66;
$color-accent: rgba($color-gray, 0.1);
$color-border-input: #ced4da;
$color-system-messages: #c8ebf7;

$btn-primary: #77BF66;
$color-danger: #dc3545;
$color-success: #77BF66;

$color-event-pre-event: #ffbc00;
$color-event-initiated: #77BF66;
$color-event-finished: #00B5F3;
$color-event-reconciled: #7c1bc7;
$color-event-closed: #a5a5a5;
$color-travel: #f1634b;
$color-overtime: #dc3545;
$color-overlap: #ff8341;
$color-volunteer: #77BF66;

$alternative-color-table: #e9ecef;

$color-not-notified: #cccccc;
$color-notified: #0099c6;
$color-accepted: #109618;
$color-rejected: #dc3912;

$calendar-current-day: #ced4da;
$calendar-color-portland: #ccd5ae;
$calendar-color-seattle: #fcbf49;
$calendar-color-montana: #e7c6ff;
$calendar-color-eugene: #e5e5e5;
$calendar-color-yakima: #faedcd;
$calendar-color-spokane:  #8ecae6;
$calendar-color-alaska: #ffc8dd;
$calendar-color-corporate: #e9edc9;