@import "../../../../../_mixins/variables";
@import "../../../../../_mixins/mixins";

.modal-checkin {
  text-align: center;
  padding: 0!important;

  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
  }
  .modal-header {
    border: none;
    .modal-title {
      color: $color-secundary;
    }
  }
  .modal-body {
    padding-bottom: 1.25rem;
    padding-top: 1.25rem;
    text-align: center;

    h1 {
      color: #6B779B;
      font-size: 2.3rem;
      font-weight: 600;
    }
    h2 {
      font-size: 1.3rem;
      font-weight: 600;
    }

    .equipment {
      border: 1px $primary-color-light solid;
      margin-top: 20px;
    }
  }
  .modal-footer {
    border: none;
  }
  .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    width: 380px;

    p {
      b {
        font-weight: 600;
      }
    }
  }

  .btn-success {
    background-color: $btn-primary;
    border-color: $btn-primary;
    padding: 0.375rem 2.75rem;
    width: 100%;
    &:hover {
      background-color: darken($btn-primary,  13);
    }
  }
  .btn-terciary {
    width: 100%;
  }
}
