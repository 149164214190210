@import "../../../_mixins/variables";
@import "../../../_mixins/mixins";

.RolesResourcesSettings {
  .table {
    th {
      text-align: center;
      color: $color-secundary;
      border-top: none;
      font-weight: 600;
      &.left-heading {
        text-align: left;
      }
    }
    td {
      padding: 0.75rem;
      vertical-align: top;
      border-top: 1px solid #dee2e6;
      
      &.access {
        text-align: center;
        color: $color-secundary;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }
}
