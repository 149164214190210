@import "../../../../_mixins/variables";
@import "../../../../_mixins/mixins";

.EventSummary {
  .StatsConsole {
    clear: both;
    margin: 1rem 0 2rem;
  }
}

table.EventSummaryList {
  th, td {
    text-align: center;

    &.staff_name,
    &.first_name,
    &.last_name {
      text-align: left;
    }
  }
}

.btn-print-pdf {
  float: right;
  color: $color-secundary;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 0.95em;
    i {
      margin-right: 0.4em;
    }
    &:hover {
      color: rgba($color-secundary, 0.65);
      color: $color-primary;
    }
}
