@import "../../../../../_mixins/variables";
@import "../../../../../_mixins/mixins";

.EventInfoSummary {
  .name {
    color: $color-primary-dark;
    font-size: 1.35rem;
    margin: 0 0.8rem 0.5rem 0;
  }
  .info {
    font-size: 0.85rem;
    font-weight: 400;
    margin-bottom: 0;
  }
}
