@import "../../../../_mixins/variables";
@import "../../../../_mixins/mixins";

.EventInfoForm {}

.EventInfoDates {
  .EventInfoDates-label {
    color: $color-secundary;
    font-size: 1.25rem;
    margin: 1rem 0;
  }

  .EventInfoDates-list {
    .options {
      width: 12%;
      text-align: left;
      .btn-add {
        margin-left: 10px;
      }
    }

    .form-group {
      margin: 0 auto;
    }
    label {
      display: none;
    }

    .day {
      text-align: left;
      width: 6%;
    }
    .date {
      width: 5%;
    }
    .positions {
      margin: 0 auto;
    }
    .numbers {
      float: left;
      width: 30%;
    }
    .clean {
      float: left;
      width: 10%;
    }
    th {
      text-align: center;
    }
    td {
      text-align: center;
    }
    .event-date-item {

      .event-date-info {
        min-width: 55%;
        h6 {
          color: $color-primary;
          margin: 0 0 0.25em;
          width: 17%;
        }
        p {
          margin: 0;
          width: 45%;
        }
      }
      .fa-trash {
        cursor: pointer;
        color: rgba($color-gray, 0.75);
        padding: 0.25rem 0.3rem;
        margin-left: 15px;
        &:hover {
          color: $color-danger;
          cursor: pointer;
        }
      }
      .fa-eraser {
        cursor: pointer;
        color: rgba($color-gray, 0.75);
        margin-left: 5px;
        &:hover {
          color: $color-danger;
          cursor: pointer;
        }
      }
    }
  }

  .EventInfoDates-range-alert {
    padding-top: 0;
    text-align: center;
    color: $color-danger;
    font-size: 0.88em;
  }

  .EventInfoDates-add {
    .btn.btn-datepicker {
      min-width: 155px;
      font-size: 0.8rem;
    }
  }
}
