
@import "../../../_mixins/variables";
@import "../../../_mixins/mixins";

.PaycomHistory {
  .PaycomHistoryTable {
    th,
    td {
      &.start_date {
        width: 13.5%;
      }
      &.end_date {
        width: 13.5%;
      }
      &.user {
        width: 13.5%;
      }
    }
  }
}
