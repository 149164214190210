@import "../../../../_mixins/variables";
@import "../../../../_mixins/mixins";

.EventReconciliation {
  .EventReconciliation-header {
    margin-bottom: 0.5em;

    .EventReconciliation-label {
      color: $color-secundary;
      text-transform: uppercase;
      font-size: 1.2em;
      font-weight: 400;
      margin: 0.5rem 0;
    }
  }
  .EventInfoSummary {
    border-bottom: none;
    .name {
      color: $color-primary-dark;
      font-size: 1.5em;
      margin: 0 0 0.1em;
      display: block;
    }
    .info {
      font-size: 1em;
      font-weight: 400;
      margin: 0;
      display: block;
    }
  }

  table.reconciliation {
    font-size: 0.9em;
    td, th { text-align: center; }
    td {
      vertical-align: middle;
      position: relative;
    }
    tr {
      &.reconciled {
        &.exported {
          .btn {
            &.checkbox {
              .fa-square {
                color: rgba($primary-font-color, 0.6);
              }
              .fa-check-square {
                color: rgba($primary-font-color, 0.8);
              }
            }
          }
          td.reconciled {
            .fa-check-square {
              color: rgba($primary-font-color, 0.6);
            }
          }
        }
        td {
          background-color:$color-gray-light;
        }
      }
    }
    p {
      margin: 0;
    }
    .form-group {
      margin: 0;
    }

    .staff_name {
      text-align: left;
      .staff-info {
        .avatar {
          width: 20px;
          margin-right: 0.5em;
        }
      }
    }
    .date-wrapper {
      width: 160px;
      margin: 0 auto;
      i {
        color: rgba($primary-font-color, 0.6);
        cursor: pointer;
        position: absolute;
        padding: 0.35em;
        right: -22px;
        top: 50%;
        font-size: 0.85em;
        margin-top: -10px;
        &:hover {
          color: $color-primary;
        }
      }
      .read-only {
        position: relative;
        p {
          &:first-child {
            margin-bottom: 0.25em;
          }
        }
        i {
          right: -15px;
        }
      }
      .editable {
        position: relative;
        .form-group {
          &:first-child {
            margin-bottom: 0.5em;
          }
        }
        .fa-save {
          color: $color-primary;
          top: 32%;
          &:hover {
            color: $color-success;
          }
          &.disabled {
            opacity: 0.7;
            color: rgba($primary-font-color, 0.6);
            cursor: default;
          }
        }
      }
    }

    .tardy {
      width: 80px;
    }
    th.note{
      text-align: center;
    }
    .note {
      width: 150px;
      text-align: left;
      p {
        font-size: 0.9em;
      }
    }

    td.pay_rate {
      min-width: 90px;
      .payrate-wrapper {
        i {
          color: rgba($primary-font-color, 0.6);
          cursor: pointer;
          font-size: 0.85em;
          width: 15px;
          line-height: 16px;
          z-index: 1;
          margin-left: 0.5em;
          position: absolute;
  
          &:hover {
            color: $color-primary;
          }
        }
      }
    }
  }
  .OptionsContainer {
    width: 100%;
    text-align: right;
    padding-bottom: 5px;
  }
  .btn-overlap {
    padding: 0;
  }

  .badge {
    color: $color-white;
    background-color: $color-secundary;
    border: 1px solid $color-secundary;
    border-radius: 0.35em;
    font-size: 0.6rem;
    padding: 0.4em 0.5em 0.2em;
    text-align: center;
    font-weight: 400;
    display: block;
    margin: 0 0.3em;
    min-width: 22px;
    display: inline-block;
    line-height: 1.2;

    &.badge-overlap {
      min-width: 33px;
      color: $divider-color;
      background-color: $color-overlap;
      border-color: transparent;

      i.fa-calendar-alt {
        font-size: 1.1em;
        font-weight: 800;
        span {
          font-weight: 800;
          font-family: $primary-font-family;
        }
      }
    }
  }
}

.event-datepicker {
  font-size: 0.95em;
  line-height: 0.95em;
  width: 335px;
}

.EventReconciliationToolBulkEndTime-modal {
  .modal-dialog {
    width: 360px;
  }
  .form-group {
    margin: 0.35em 0 0.5em;

    .event-datepicker {
      font-size: 0.95em;
      line-height: 0.95em;
      width: 335px;
    
      .react-datepicker__day,
      .react-datepicker__time-name {
        line-height: 1.6;
      }
      .react-datepicker__current-month,
      .react-datepicker-time__header {
        line-height: 1.2;
        font-size: 1.25em;
      }
    
      .react-datepicker__time-container {
        width: 92px;
        .react-datepicker__time {
          .react-datepicker__time-box {
            width: 92px;
            ul.react-datepicker__time-list {
              li.react-datepicker__time-list-item {
                display: flex;
                align-items: center;
              }
            }
          }
        }
      }
      .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
        right: 30%;
      }
    
      .react-datepicker__input-time-container
      .react-datepicker-time__input-container
      .react-datepicker-time__input input {
        width: 94px;
      }
    
      .react-datepicker__input-container input {
        background-size: 0.9em;
        font-size: 0.93em;
        text-align: center;
        padding-right: 1rem;
        padding-left: 0.28rem;
      }
    }
  }
}

