
@import "../../../_mixins/variables";
@import "../../../_mixins/mixins";

.ErrorHistory {
  .ErrorHistoryTable {
    th,
    td {
      &.created_date {
        width: 13.5%;
      }
      &.user {
        width: 12%;;
      }
    }
  }

  .btn-delete-logs {
    float: right;
    color: $color-secundary;
      text-transform: uppercase;
      font-weight: 400;
      font-size: 0.95em;
      i {
        margin-right: 0.4em;
      }
      &:hover {
        color: rgba($color-secundary, 0.65);
        color: $color-primary;
      }
  }
}
