
@import "../../../_mixins/variables";
@import "../../../_mixins/mixins";

.PayrollHistory {

  .PayrollHistoryTable {
    th,
    td {
      &.created_date {
        width: 13.5%;
      }
      &.department {
        text-align: center;
        width: 15%;;
      }
      &.user {
        width: 16%;;
      }
      &.reports_files {
        a {
          color: $color-secundary;
          padding: 0 1.5rem 0 0;

          &:hover {
            color: $color-primary;
            text-decoration: none;
          }
        }
      }
    }
  }
}
