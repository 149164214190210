@import "../../../../_mixins/variables";
@import "../../../../_mixins/mixins";

.EventStaff {
  &.form-wrapper {
    padding-top: 0.5rem;
  }
  .EventStaff-toolsbar {
    margin: 1.4em 0;
  }
      .btn-clone-position {
        color: $color-secundary;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 0.95em;
        i {
          margin-right: 0.4em;
        }
        &:hover {
          color: rgba($color-secundary, 0.65);
          color: $color-primary;
        }
      }

  .badge {
    color: $color-white;
    background-color: $color-secundary;
    border: 1px solid $color-secundary;
    border-radius: 0.35em;
    font-size: 0.6rem;
    padding: 0.4em 0.5em 0.2em;
    text-align: center;
    font-weight: 400;
    display: block;
    margin: 0 0.3em;
    min-width: 22px;
    display: inline-block;
    line-height: 1.2;

    &.badge-outline {
      color: $color-secundary;
      background-color: transparent;
      font-weight: 600;
    }
    &.badge-outline-blank {
      border: none;
      background-color: transparent;
      font-weight: 600;
    }
    &.badge-rating {
      min-width: 33px;
      .fa-star {
        font-size: 0.8em
      }
    }
    &.badge-placeholder {
      min-width: 33px;
    }
    &.badge-payrate {
      min-width: 40px;
    }
    &.badge-overtime {
      min-width: 33px;
      i.fa-clock {
        font-size: 1.1em;
        font-weight: 800;
        span {
          font-weight: 800;
          font-family: $primary-font-family;
        }
      }
    }
    &.badge-overlap {
      min-width: 33px;
      i.fa-calendar-alt {
        font-size: 1.1em;
        font-weight: 800;
        span {
          font-weight: 800;
          font-family: $primary-font-family;
        }
      }
    }
    &.badge-travel {
      border: none;
      background-color: transparent;
      color: $color-secundary;
      font-size: 1em;
      padding: 0.4em 0em 0.2em;
      i.fa-plane {
        transform: rotate(-45deg);
      } 
    }
    &.badge-volunteer {
      border: none;
      background-color: transparent;
      color: $color-volunteer;
      font-size: 1em;
      padding: 0.4em 0em 0.2em;
    }
  }

  .travel-staff {
    .badge {
      border-color: $color-travel;
      background-color: $color-travel;
      &.badge.badge-outline {
        color: $color-travel;
        background-color: transparent;
      }
      &.badge-travel {
        color: $color-travel;
        background-color: transparent;
      }
      &.badge-volunteer {
        color: $color-volunteer;
        background-color: transparent;
      }
    }
  }

  .overtime-staff {
    .badge {
      border-color: $color-overtime;
      background-color: $color-overtime;
      &.badge.badge-outline {
        color: $color-overtime;
        background-color: transparent;
      }
      &.badge-overtime {
        color: $divider-color;
        background-color: $color-overtime;
        border-color: transparent;
      }
      &.badge-overlap {
        color: $divider-color;
        background-color: $color-overlap;
        border-color: transparent;
      }
    }
  }

  .staff-member-info-overlay {
    $color-bg-overlay: lighten($color-gray, 40);
    background-color: $color-bg-overlay;
    box-shadow: 1px 1px 4px rgba($color-black, 0.5);
    color: lighten($primary-font-color, 2);
    color: $primary-font-color;
    position: absolute;
    left: -12px;
    top: 135%;
    width: 280px;
    z-index: 10;
    border-radius: 5px;
    padding: 1em;
    display: none;
    &:after, &:before {
      bottom: 100%;
      left: 3.65%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    
    &:after {
      border-bottom-color: $color-bg-overlay;
      border-width: 10px;
    }
    &:before {
      border-width: 10px;
    }

    .staff-member-info-overlay-name {
      font-size: 1.1em;
    }
    p {
      margin: 0.5em 0 0;
    }
    .travel {
      color: $color-travel;
      font-size: 1.1em;
      font-weight: 400;
      i.fa-plane {
        transform: rotate(-45deg);
        font-size: 0.88em;
      } 
    }

    .staff-members-badges {
      padding: 1em 0 0;
    }

    .badge {
      font-size: 0.85em;
      line-height: 1.4;
      padding: 0.3em 0.5em 0.3em;
    }
  }

  .css-yk16xz-control,
  .css-1pahdxg-control,
  .css-1fhf3k1-control {
    min-height: 32px;
  }
  .css-26l3qy-menu {
    margin-top: 0;
  }
}

// MODAL EVENT GLOBALS 
.modal-dialog {

  .modal-header {
    .modal-title {
      i { font-size: 0.85em; }
    }
  }

  .disclaimer {
    margin: 0.8em 0.5em 0;
    font-style: italic;
    font-size: 1em;
  }
  .select-style-default {
    border-bottom: 1px solid $color-gray-lighter;
  }
  .modal-footer {
    border-top: none;
  }
}

.modal-payrate {
  .modal-dialog {
    width: 340px;
  }
  .form-group {
    margin: 1em 0 1.2em;

    &.currency-input {
      .alert {
        bottom: -12px;
      }
    }
  }
  .label {
    .additional-rate-event {
      color: $color-danger;
    }
  }
}

.modal-clone {
  .modal-dialog { width: 350px; }
  .modal-body {
    .select-clone-date {
      border-bottom: 1px solid $color-gray-lighter;
    }
    .disclaimer {
      margin: 0.8em 0.5em 0;
      font-style: italic;
      font-size: 1em;
    }
    .warning {
      margin: 1em 0.5em 0;
      font-size: 1.2em;
      color: $color-danger;
      text-align: center;
    }
  }
}

.modal-print {
  .btn {
    min-width: 130px;
  }
}

.modal-bulk-time {
  label {
    color: $color-secundary;
    text-transform: uppercase;
    margin: 0;
    font-weight: 600;
  }
  .select-style-default {
    border-bottom: 1px solid $color-gray-lighter; 
  }
  .form-group {
    margin-bottom: 0;
  }
}

.modal-bulk-group {
  .modal-dialog {
    width: 380px;
  }
}

table.modal-table {
  font-size: 0.85em;
  width: 100%;
  tr {
    border-bottom: 1px solid $color-gray;
  }
  th {
    font-weight: bold;
    position: relative;
    text-align: center;
    vertical-align: middle;
    padding: 0.25rem 0.25rem;
  }
  td {
    position: relative;
    text-align: center;
    vertical-align: middle;
    padding: 0.25rem 0.25rem;
  }
}