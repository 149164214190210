@import "../../../_mixins/variables";
@import "../../../_mixins/mixins";

.dropzone-uploader {
  .dropzone {
    border: 2px dashed $color-secundary;
    color: $color-secundary;
    padding: 1.8rem 1rem;
    text-align: center;
    cursor: pointer;

    &:hover {
      background-color: rgba($color-secundary, 0.1);
    }
    &:focus {
      outline: none;
    }
    &.drag-hover {
      color: $color-primary;
      border-color: $color-primary;
      background-color: rgba($color-primary, 0.1);
    }
    &.drag-hover-error {
      color: $color-danger;
      border-color: $color-danger;
      background-color: rgba($color-danger, 0.1);
    }

    .fas {
      font-size: 1.5rem;
    }

    p {
      margin: 0;
    }
  }
  .list-group {
    margin: 0;
    .list-group-item {
      margin-top: 0.85rem;
      padding: 0.5rem 0.7rem;
      font-size: 0.8rem;
      text-align: left;
      i {
        margin-right: 0.35rem;
        font-size: 1rem;
      }
    }
  }

}

.files-manager {
  .loader {
    color: $color-primary;
    font-size: 2rem;
    position: absolute;
    top: 30vh;
    left: 50%;
    width: 50px;
    text-align: center;
    margin-left: -25px;
  }

  .file-card {
    text-align: center;
    padding: 0;
    position: relative;
    margin: 0 0.75rem 1.5rem;
    width: 39vw;
    min-height: 170px;
    @include respondTo-xsmall {
      width: 210px;
    }
    @include respondTo-small {
      width: 205px;
    }
    @include respondTo-large {
      width: 195px;
      width: 12vw;
    }
    @include respondTo-xlarge {
      width: 200px;
      width: 8.8vw;
    }
    &:hover {
      border-color: $color-primary;
      background-color: rgba($color-primary, 0.05);
    }
    p {
      margin: 0;
    }
    .fa-trash {
      color: rgba($color-gray, 0.85);
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      &:hover {
        color: $color-danger;
        cursor: pointer;
      }
    }
    .file-icon {
      color: lighten($primary-font-color, 20);
      font-size: 3rem;
      font-weight: 100;
    }
    .file-name {
      font-weight: 600;
    }
    .file-description {
      font-size: 0.8rem;
      font-style: italic;
      min-height: 1.1rem;
    }

    .file-info {
      cursor: pointer;
      height: 100%;
    }
  }
}
