@import "../../_mixins/variables";
@import "../../_mixins/mixins";

.customers-page {

  .table.customers {

    .department,
    .past_due_check,
    .red_flag, 
    .edit,
    .delete {
      text-align: center;
    }
    tr.alert {
      background-color: rgba($color-danger, 0.07);
    }
    td {
      vertical-align: middle;
      i {
        &.fas.fa-flag {
          color: $color-red;
        }
        &.far.fa-flag {
          color: rgba($primary-font-color, 0.5);
        }
      }
    }

    .phone{
      min-width: 7rem;
    }
    .edit,
    .delete {
      width: 3rem;
    }
  }
  .form-footer {
    padding: 1.5rem 0 2rem;
    text-align: right;
  }

  .personal-info-form {
    .fas.fa-flag {
      color: $color-red;
    }
    .far.fa-flag {
      color: rgba($primary-font-color, 0.7);
    }
  }

}