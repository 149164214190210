@import "../../../../../_mixins/variables";
@import "../../../../../_mixins/mixins";

.EventStaffPositions {
  width: 100%;
  margin: 0.4rem 0;

  .label {
    color: $color-secundary;
    font-size: 1.2em;
    text-transform: uppercase;
    margin: 0;
    i {
      font-size: 0.9em;
    }
  }
  .position-title {
    line-height: 10px;
    padding: 10px 0;
    float: left;
    margin-top: -3px;
  }
  .add-position-button {
    padding-left: 5px;
    display: flex;
  }
  .positions-select {
    clear: both;
  }
  .btn-add-position {
    color: $color-primary;
    background-color: transparent;
    border-color: transparent;
    &:hover {
      color: $color-white;
      background-color: $color-primary;
    }
  }
  .select-position {
    .css-yk16xz-control,
    .css-1pahdxg-control {
      box-shadow: none;
    }
    >div {
      border: none;
      .css-62g3xt-dummyInput {
        position: absolute;
      }
    }

    .css-tlfecz-indicatorContainer {
      color: $color-primary;
    }
    .css-1okebmr-indicatorSeparator {
      display: none;
    }
    .css-1hwfws3 {
      padding-right: 0;
    }

    .position-option{
      .position-option-info {
        .name {
          font-size: 1.15em;
        }
        .position {
          font-size: 0.85em;
          color: $color-gray;
        }
        .counter {
          text-align: left;
        }
      }
    }
  }

  .position-option-info {
    width: 100%;
    .name {
      font-size: 1.25em;
      font-weight: 600;
    }
    i {
      color: $color-secundary;
    }
    .counter {
      width: 73px;
      text-align: right;
    }
    .count {
      color: $color-secundary;
      padding: 0 0 0 0.25em;
      &::after {
        content: '|';
        padding: 0 0.25em;
      }
    }
    .total {
      color: rgba($color-gray, 0.6);
    }
  }

  .position-option {
    //BASE
    &.css-1n7v3ny-option {}
    //ACTIVE
    &.css-9gakcf-option {
      background-color: $color-primary;
      .position-option-info {
        .count,
        i {
          color: $color-white;
        }
        .total {
          color: rgba($color-white, 0.5);
        }
      }
    }
    //HOVER
    &.css-1n7v3ny-option {
      background-color: rgba($color-primary, 0.25);
    }

    &.isFull {
      background-color: rgba($color-success, 0.25);
      //BASE
      &.css-yt9ioa-option {}
      //ACTIVE
      &.css-9gakcf-option {
        background-color: $color-primary;
      }
      //HOVER
      &.css-1n7v3ny-option {
        background-color: rgba($color-primary, 0.25);
        background-color: rgba($color-success, 0.6);
      }
    }
  }
}
