@import "../../../_mixins/mixins";
@import "../../../_mixins/variables";

.empty-data {
  text-align: center;
  margin: 5em 0 0;
  width: 100%;

  .image-wrapper {
    margin: 0 auto 1.5rem;
  }

  .empty-data-title {
    color: $color-secundary;
    font-size: 1.15rem;
    font-weight: 600;
  }
  .empty-data-copy {
    color: lighten($primary-font-color, 35);
    font-size: 0.9rem;
  }

  &.type-dashboard {
    .image-wrapper {
      width: 160px;
      img {
        margin-left: -2.5rem;
      }
    }
  }

  &.type-files,
  &.type-notes {
    .image-wrapper {
      width: 170px;
    }
  }

  &.type-datatable {
    min-height: 25vw;
    .image-wrapper {
      width: 115px;
    }
  }
}
