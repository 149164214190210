@import "../../../_mixins/mixins";
@import "../../../_mixins/variables";


.table-loading {
  min-height: 40vh;
  .loader {
    color: $color-primary;
    font-size: 1.5rem;
  }
}