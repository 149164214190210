@import "../../_mixins/variables";
@import "../../_mixins/mixins";

.ReportsPage {
  .ReportsPageHeader{

    .btn-back {
      color: rgba($color-secundary, 0.5);
      padding: 0.5em 0.25em 0.5em 0;
      margin-right: 0.3em;
      line-height: 1;
      &:hover {
        color: $color-primary-dark;
      }
    }

  }

  .ReportsList {
    .ReportsList-header {
      text-align: center;
      padding:2.5rem 0 1rem;
      h2 {
        color: $color-primary-dark;
        font-size: 1.65rem;
      }
    }
    .ReportsList-wrapper {
      margin: 0 auto;
      width: 960px;
      .ReportsList-item {
        background-color: $color-gray-light;
        border-radius: 0.25rem;
        border: none;
        box-shadow: 1px 1px 4px rgba($color-black, 0.25);
        color: $color-secundary;
        text-align: center;
        font-size: 1.15rem;
        margin-bottom: 1.5em;
        min-height: 145px;
        width: 210px;
        border-bottom: 7px solid transparent; 
        border-top: 7px solid transparent; 
  
        i {
          font-size: 1.45em;
          margin-bottom: 0.25em;
        }
  
        &:hover {
          color: $color-primary;
          border-top: 7px solid $color-primary; 
          border-bottom: 7px solid transparent; 
        }
        &.selected {
          color: $color-primary;
        }
  
      }
    }

    @media (max-width: 768px) {
      .ReportsList-wrapper {
        margin: 0 auto;
        width: 460px;
      }
    }
  }

  .ReportToolFilters {
    padding: 0.8em 0;
    min-height: 82px;
    // FILTERS
    .filters {
      .filter-item {
        width: 140px;
        margin-right: 2em;
        label {
          margin: 0;
          color: rgba($primary-font-color, 0.5);
        }
        .react-datepicker-wrapper {
          margin-top: 0.55rem;
        }
        .form-group {
          margin: 0;
        }

      }
    }

    .btn-generate-report {
      width: 110px;
    }

  }

  .ReportToolFiltersConfirmation {
    margin: auto;
    text-align: center;
    width: 90%;
    .btn {
      margin-right: 1em;
      margin-bottom: 0.5em;
      &.btn-close {
        display: none;
      }
    }
    .ReportToolFiltersConfirmation-message {
      font-size: 1.15rem;
      margin-bottom: 20px;
    }
    .ReportToolFiltersConfirmation-message-confirmation {
      font-size: 1.15rem;
      margin-top: 20px;
    }
    .ReportToolFiltersConfirmation-badge-wrapper {
      border: 1px rgb(165, 164, 164) solid;
      margin: 1em 0 0.25em; 
      padding-bottom: 1em;
      padding-top: 1em;
      padding-left: 1em;
      .badge {
        margin-right: 0.8em;
        margin-bottom: 0.8em;
        font-size: 0.9em;
        font-weight: 400;
        line-height: 1.2;
        padding: 0.3em 0.7em;
      }
      .header {
        font-size: 1.1em;
        font-weight: bold;
      }
      .name {
        text-align: left;
      }
      .button {
        text-align: right;
      }
    }
    .ReportToolPreviewConfirmation-badge-wrapper {
      border: 1px rgb(165, 164, 164) solid;
      margin: 1em 0 0.25em; 
      padding-bottom: 1em;
      padding-top: 1em;
      .badge {
        margin-right: 0.8em;
        margin-bottom: 0.8em;
        font-size: 0.9em;
        font-weight: 400;
        line-height: 1.2;
        padding: 0.3em 0.7em;
      }
      .header {
        font-size: 1.1em;
        font-weight: bold;
      }
      .name {
        text-align: left;
      }
    }
  }

  .ReportResults {
    border: 1px solid $color-secundary;
    border-radius: 5px;
    margin-top: 1.5em;
    padding-bottom: 3em;
    overflow: hidden;

    .ReportResults-header {
      background-color: $color-gray-light;
      text-align: right;
      .btn {
        margin: 0 0.5rem 0 0;
        i {
          margin-right: 0.25rem;
        }
      }
      .exportfiles-wrapper {
        position: relative;
        display: inline-block;
        .dropdown {
          position: absolute;
          background-color: $color-gray-light;
          list-style: none;
          margin: 0;
          padding: 0 1em;
          z-index: 10;
          min-width: 100%;
          right: 0.5em;
          li {
            cursor: pointer;
            margin: 0.6em 0;
            &:hover {
              color: $color-primary;
            }
          }
        }
      }
    }

    .ReportResults-body {
      .ReportResults-info {
        margin: 1.5rem 0;
        p {
          margin: 0 0 0.5rem;
        }

        .cms-logo {
          width: 110px;
        }
      }
    }

    table.report {
      th, td {
        text-align: right;
      }
      td,
      th {
        &:first-child {
          text-align: left;
          + td, + th {
            text-align: left;
          }
        }
      }
      th {
        border-top: none;
        color: $color-secundary;
        font-weight: 600;
      }
      tr {
        &.total {
          font-weight: 600;
        }
        &.region {
          font-weight: 600;
  
          td {
            border-top: 1px solid rgba($color-secundary, 0.7);
          }
        }
      }

      &.with-total {
        tr {
          &:last-child {
            font-weight: 700;
          }
        }
      }
    }

  }

}

@media print {
  .ReportResults-body {
    background-color: $color-white;
    height: 100%;
    padding: 50px 80px;
    .ReportResults-info {
      margin-bottom: 30px;
      p {
        margin: 0 0 7px;
      }
    }
    table {
      text-align: right;
      th {
        border-top: none;
        color: rgba($primary-font-color, 0.5);
        color: lighten($primary-font-color, 40);
        font-weight: 600;
      }
      td,
      th {
        &:first-child {
          text-align: left;
          + td, + th {
            text-align: left;
          }
        }
      }
      &.with-total {
        tr {
          &:last-child {
            font-weight: 700;
          }
        }
      }
    }
  }
}
