@import "../../_mixins/variables";
@import "../../_mixins/mixins";

.applicant-page {

  .table.applicants {
    th,
    td {
      text-align: center;
    }
    td {
      vertical-align: middle;
    }
    .application_date,
    .first_name,
    .last_name {
      text-align: left;
      width: 20%;
    }
    .edit {
      width: 3rem;
    }
  }

  .form-footer {
    padding: 1.5rem 0 2rem;
    text-align: right;
  }

  .row-details{
    margin-bottom: 30px;
  }

  .applicant-navigation  {
    border: 1px solid rgba($primary-font-color, 0.4);
    border-radius: 5px;
    padding-top: 20px;
    margin-bottom: 20px;
    height: 100px;
    width: 100%;

    .applicant-navigation-item {
      padding: 0.8em 0 0.55em;
      display: flex;
    }
    .text-right {
      margin-right: 20px;
      margin-left: 20px;
    }
    .text-left {
      margin-right: 20px;
      margin-left: 20px;
    }
    .float-center {
      margin-left: 50px;
      text-align: center;
      width: 300px;
    }
    .float-left {
      text-align: left;
      width: auto;
    }
    .float-right {
      text-align: right;
      width: auto;
    }
  }

  .table.applicants {
    th,
    td {
      text-align: center;
    }
    td {
      vertical-align: middle;
    }

    .application_date {
      text-align: left;
      width: 15%;
    }
    .first_name,
    .last_name {
      text-align: left;
      width: 15%;
    }
    .department {
      width: 10%;
    }
    .contact_phone {
      width: 10%;
    }
    .email {
      width: 20%;
    }
    .status {
      width: 10%;
    }
    .edit {
      width: 3rem;
    }
  }

  .applicant-table {
    width: 95%;

    tr {
      text-align: center;
      border: 1px $position-event-border solid;
    }
    td {
      padding: 10px;
    }
  }
}
