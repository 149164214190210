@import "../../_mixins/variables";
@import "../../_mixins/mixins";

.venues-page {

  .table.venues {

    th,
    td {
      text-align: center;
      vertical-align: middle;

      &.name {
        text-align: left;
        width: 23vw;
      }
      &.edit,
      &.delete {
        width: 3rem;
      }
    }
    
  }
  .form-footer {
    padding: 1.5rem 0 2rem;
    text-align: right;
  }

  .venue-configuration-form {

    .configurations-list-card {
      .add-configuration {
        background-color: rgba($color-secundary, 0.15);
        padding: 1rem 1rem 0;
      }
      .card-title {
        color: $color-secundary;
        font-size: 0.98rem;
        font-weight: 600;
      }
      .form-group {
        margin-bottom: 1.5rem;
      }
      .list-group {
        .list-group-item {
          padding: 0;
          cursor: pointer;
          &.active {
            color: $color-primary;
            cursor: default;
            background-color: transparent;
            border-color: $color-primary;
            box-shadow: inset 0px 0px 3px $color-primary;
          }

          .item-info {
            padding: 0.75rem 0.35rem 0.75rem 0.8rem;
            width: 100%;
          }

          .configuration-title {
            width: 50%;
          }
          .configuration-badges {
            text-align: right;
            width: 50%;
          }
          
          .badge {
            font-weight: 400;
            font-size: 0.7rem;
            line-height: 1.3;
            margin-right: 0.35rem;
          }

          .list-group-item-console {
            padding-right: 0.35rem;
          }
        }
      }
      .fa-trash {
        cursor: pointer;
        color: rgba($color-gray, 0.5);
        padding: 0.25rem 0.35rem;
        &:hover {
          color: $color-danger;
          cursor: pointer;
        }
      }
    }
    .configuration-position-list {
      min-height: 40vh;
      .table-console {
        padding: 0.25rem 0.25rem 1rem;
      }
    }

    .table {
      &.venues-configuration {
        th,
        td {
          text-align: center;
          vertical-align: middle;

          &.assignment_name {
            text-align: left;
          }
        }

        td {
          &.assignment_name {
            width: 12vw;
          }
          &.edit,
          &.delete {
            width: 3rem;
          }
        }
      }
    }

    
  }
  
}
.modal-configuration-position-form {
  .form-group-label {
    margin-top: 0;
  }
  .checkbox {
    min-height: 3rem;
  }
  .input-radio-qty {
    .form-group {
      margin: 0;
    }
  }
  .modal-footer {
    border: none;
    padding-top: 0;
  }
}
