@import "../../../../../_mixins/variables";
@import "../../../../../_mixins/mixins";

.EventStatus {
  .EventStatus-label {
    color: lighten($primary-font-color, 28);
    font-size: 0.85em;
    margin: 0;
    text-align: right;
    text-transform: uppercase;
  }

  .EventStatus-info {
    text-align: right;
    margin-top: 0em;
  }
  .EventStatus-value {
    color: $color-secundary;
    margin: 0 0 0 0.2em;
    font-size: 1.05em;
    line-height: 1.5;
    &.pre-event {
      color: $color-event-pre-event;
    }
    &.initiated {
      color: $color-event-initiated;
    }
    &.finalized {
      color: $color-event-finished;
    }
    &.reconciled {
      color: $color-event-reconciled;
    }
    &.closed {
      color: $color-event-closed;
    }
  }

  .EventStatus-icon {
    color: lighten($primary-font-color, 35);
    color: $color-white;
    background-color: $color-secundary;
    border-radius: 3px;
    width: 34px;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1em;
    margin-left: 0.5em;
    &.pre-event {
      background-color: $color-event-pre-event;
    }
    &.initiated {
      background-color: $color-event-initiated;
    }
    &.finalized {
      background-color: $color-event-finished;
    }
    &.reconciled {
      background-color: $color-event-reconciled;
    }
    &.closed {
      background-color: $color-event-closed;
    }
  }

  .EventStatusCountdown {
    color: lighten($primary-font-color, 28);
    font-size: 0.78em;
    .EventStatusCountdown-number {
      background-color: $color-white;
      border: 1px solid $color-gray-lighter;
      border-radius: 3px;
      padding: 0.25em 0.3em;
      text-align: center;
      display: inline-block;
      margin: 0 0.25em;
      line-height: 10px;
    }
    .EventStatusCountdown-label {
      color: $color-primary;
    }
  }

}
