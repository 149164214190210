@import "../../../../_mixins/variables";
@import "../../../../_mixins/mixins";

.EventInvoicing {
  .add-expense {
    background-color: $color-gray-light;
    padding: 1rem 1rem 0;
  }
  .card-title {
    color: $color-secundary;
    font-size: 0.98rem;
    font-weight: 600;
  }
}

table.EventInvoicingList, 
table.EventInvoicingTotal {
  th, td {
    text-align: left;

    &.total_amount,
    &.total_label {
      font-weight: bolder;
    }

    &.total_label {
      text-align: right;
    }

    &.created_date,
    &.description {
      width: 35%;
    }
    &.total_amount,
    &.amount {
      width: 30%;
    } 

    &.total_label {
      width: 32%;
    }

    &.delete {
      width: 10%;
    }
  }
}

.row-print {
  height: 40px;
}
.btn-print-pdf {
  float: right;
  color: $color-secundary;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 0.95em;
    i {
      margin-right: 0.4em;
    }
    &:hover {
      color: rgba($color-secundary, 0.65);
      color: $color-primary;
    }
}
