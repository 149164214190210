@import "../../../_mixins/variables";
@import "../../../_mixins/mixins";

.StatesSettings {

  .StatesSettingsList {
    .access {
      font-weight: 600;
      cursor: pointer;
    }
  }

  .StatesSettingsCard {
    margin-bottom: 1rem;
    height: 60px;

    .card-title {
      float: left;
      width: 93%;
    }

    .card-access {
      float: left;
      width: 3%;
    }
  }

  .active {
    background-color: $alternative-color-table;
  }
}
