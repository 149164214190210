@import "../../_mixins/variables";
@import "../../_mixins/mixins";

.staff-page {

  .form-wrapper {
    padding: 2rem 1rem;
    &.staff-licenses-form {
      padding-bottom: 6.5rem;
    }
    &.staff-cms-info {
      padding-bottom: 4rem;
    }
  }

  .table.staff {
    th,
    td {
      text-align: center;
    }
    tr.inactive {
      background-color: $color-accent;
    }
    td {
      vertical-align: middle;
    }
    .thumbnail {
      text-align: center;
      width: 2.5rem;
      padding: 0 0.25rem;
      vertical-align: middle;
    }

    .first_name,
    .last_name {
      text-align: left;
    }

    .edit,
    .delete {
      width: 3rem;
    }
  }



  
  // ** Staff Forms
  // *******************************************************

  .form-footer {
    padding: 1.5rem 0 2rem;
    text-align: right;
  }

  // POSITIONS PAY RATES SECTION
  .staff-psotions-payrates-form {
    padding-top: 0;
    .form-group-label {
      margin: 0 0 1.5rem;
    }
    .card {
      margin-bottom: 2rem;
      &.disabled {
        opacity: 0.5;
      }

      .card-body {
        .checkbox {
          position: absolute;
          right: 0.6rem;
          top: 0.5rem;
          height: auto;
          label {
            padding-left: 1rem;
          }
        }
      }
      .card-link {
        color: $color-primary;
        padding: 0;
        &:hover {
          color: darken($color-primary, 10);
        }
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
      .payrate-form-group {
        
        .form-group {
          margin-bottom: 1.5rem;
          margin-top: 1.3rem;
        }

        .alert {
          bottom: -0.8rem;
        }
      }
    }

  }

  // AVAILABILITY FORM
  .staff-availability-form {
    .table {
      text-align: center;
      th {
        color: $color-secundary;
        border-top: none;
        font-weight: 600;
        &.hour-head-icon {
          border-bottom-color: transparent;
        }
        &.hour-head {
          cursor: pointer;
        }
      }
      td {
        border: none;
        &.day {
          color: $color-secundary;
          font-weight: 600;
          cursor: pointer;
        }
      }
      tr {
        &:first-child {
          td {
            padding-top: 0.85rem;
          }
        }
      }
    }
    .fas {
      cursor: pointer;
    }
    .fa-check {
      color: $color-success;
      font-size: 1rem;
    }
    .fa-circle {
      color: lighten($color-gray, 18);
      font-size: 0.5rem;
    }
  }

  // DATEPICKER
  .react-datepicker {
    font-size: 0.75rem;
  }
  .react-datepicker__day, 
  .react-datepicker__time-name {
    line-height: 1.6;
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header {
    line-height: 1.2;
    font-size: 1.2em;
  }
  
  .react-datepicker__time-container {
    width: 92px;
    .react-datepicker__time {
      .react-datepicker__time-box {
        width: 92px;
        ul.react-datepicker__time-list {
          li.react-datepicker__time-list-item {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
  .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 30%;
  }
  
  .react-datepicker__input-container input {
    background-size: 1.1rem;
    font-size: 0.93rem;
    text-align: center;
    padding-right: 1rem;
    padding-left: 0.28rem;
  }

}
.modal-staff-payrates-history-logs {
  .table {
    thead {
      th {
        color: $color-secundary;
        font-weight: 600;
        border-top: none;
        border-bottom: 2px solid rgba($color-secundary, 0.7);
        tr {
          display: block;
        }
      }
    }
  }
  .scroll-wrapper {
    overflow-x: auto;
    height: 400px;
  }
}
.modal-staff-paycom-sync {
  .disclaimer {
    text-align: center;
    font-style: italic;
  }
  .table {
    thead {
      th {
        color: $color-secundary;
        font-weight: 600;
        border-top: none;
        border-bottom: 2px solid rgba($color-secundary, 0.7);
        tr {
          display: block;
        }
      }
    }
  }
  .scroll-wrapper {
    overflow-x: auto;
    height: 150px;
  }
}

// RATING FORM
.staff-rating {
  .google-visualization-table-table {
    font-family: 'Source Sans Pro', sans-serif;
  }
  .google-visualization-table-table th {
    font-family: 'Source Sans Pro', sans-serif;
  }
  .google-visualization-table-td {
    text-align: center;
  }
  td.google-visualization-table-type-number, .google-visualization-table-type-number {
    text-align: center;
  }

  .RatingSummary {
    border-radius: 0.25rem;
    border: none;
    box-shadow: 1px 1px 4px rgba($color-black, 0.25);
    color: $color-secundary;
    margin-bottom: 1.5em;
    min-height: 285px;
    text-align: left;
    width: 100%;
    border-bottom: 7px solid transparent; 

    .rating-chart-container {
      border: 3px solid #fff;
      padding: 20px;
      display: flex;
    }
    .rating-chart {
      float: left;
      width: 25%;
    }
  }

  .table {
    th {
      color: $color-secundary;
      border-top: none;
      font-weight: 600;
    }
    th.date, th.hours, th.rating, th.pay_rate, th.amount,
    th.salary, th.tardy, th.no_show, th.short_notice, th.cancel {
      text-align: center;
    }
    td {
      text-align: left;
    }
    td.date, td.hours, td.rating, td.pay_rate, td.amount, 
    td.salary, td.tardy, td.no_show, td.short_notice, td.cancel {
      text-align: center;
    }
    td.note {
      text-align: left;
    }
    tr {
      &:first-child {
        td {
          padding-top: 0.85rem;
        }
      }
    }
  }
  .export-container {
    text-align: right;
  }
  .btn-print-options {
    color: $color-secundary;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 0.95em;
    i {
      margin-right: 0.4em;
    }
    &:hover {
      color: rgba($color-secundary, 0.65);
      color: $color-primary;
    }
  }
}

.staff-notifications {
  .table {
    th {
      color: $color-secundary;
      border-top: none;
      font-weight: 600;
    }
    td {
      text-align: left;
    }
    tr {
      &:first-child {
        td {
          padding-top: 0.85rem;
        }
      }
    }
  }
  .export-container {
    text-align: right;
  }
  .btn-print-options {
    color: $color-secundary;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 0.95em;
    i {
      margin-right: 0.4em;
    }
    &:hover {
      color: rgba($color-secundary, 0.65);
      color: $color-primary;
    }
  }
  .circle-wrapper {
    position: relative;
    width: 10px;
    height: 20px;
    float: left;
  }
  .circle {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    display: flex;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $color-not-notified;
  }
  .Notified {
    background-color: $color-notified;
  }
  .Accepted {
    background-color: $color-accepted;
  }
  .Rejected {
    background-color: $color-rejected;
  }
}

.paycom-info {
  margin-bottom: 10px;
  margin-top: -30px;
}

.pay-rate-col {
  text-align: center;
}
