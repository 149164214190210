@import "../../../_mixins/variables";
@import "../../../_mixins/mixins";

.ClothesSettings {
  
  .ClothesSizeCard{

    .card-header {
      background-color: transparent;
      border-bottom: none;

      .form-group {
        margin-bottom: 0;
      }
    }

    .ClothesSizeCardTitle {
      color: $color-secundary;
      font-size: 0.98rem;
      font-weight: 600;
      text-transform: uppercase;
      .icon {
        display: flex;
        width: 17px;
        height: 17px;
        margin-right: 0.3rem;
      }
    }
    .fa-trash {
      cursor: pointer;
      color: rgba($color-gray, 0.75);
      padding: 0.25rem 0.35rem;
      &:hover {
        color: $color-danger;
        cursor: pointer;
      }
    }
  }
}
