.Event {
  .form-wrapper {
    padding-top: 0;
    min-height: calc(100vh - 275px);
  }
  .page-header {
    margin-bottom: 0.8rem;
  }
  .cms-tabs + div {
    overflow: hidden;
  }
}

.event-datepicker {
  font-size: 0.8rem;
  line-height: 0.95rem;

  .react-datepicker__day,
  .react-datepicker__time-name {
    line-height: 1.6;
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header {
    line-height: 1.2;
    font-size: 1.25em;
  }

  .react-datepicker__time-container {
    width: 92px;
    .react-datepicker__time {
      .react-datepicker__time-box {
        width: 92px;
        ul.react-datepicker__time-list {
          li.react-datepicker__time-list-item {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
  .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 30%;
  }

  .react-datepicker__input-time-container
  .react-datepicker-time__input-container
  .react-datepicker-time__input input {
    width: 94px;
  }

  .react-datepicker__input-container input {
    background-size: 0.9em;
    font-size: 0.93em;
    text-align: center;
    padding-right: 1rem;
    padding-left: 0.28rem;
  }
}

.external-link-alt-custom-xsmall {
  color: #00B5F3;
  font-size: x-small;
}

.external-link-alt-custom-small {
  color: #00B5F3;
  font-size: small;
  vertical-align: middle;
}
