@import "../../../../../_mixins/variables";
@import "../../../../../_mixins/mixins";

.EventStaffAssigned {

  .EventStaffAssigned-toolsbar  {
    margin-bottom: 1em;
    .title {
      margin: 0;
      width: 185px;
    }
    .EventStaffAssigned-toolsbar-items {
      background-color: $color-gray-light;
      border-radius: 3px;
      margin-left: 0.8em;
      width: 90%;
      .btn-toolsbar-item {
        color: $color-secundary;
        font-size: 0.8em;
        text-transform: uppercase;
        margin: 0 0.25em;
        &:hover {
          color: $color-primary;
        }
        &:disabled, &.disabled {
          &:hover {
            color: $color-secundary;
          }
        }
      }
    }
  }

  .EventStaffAssigned-footer {
    .addPositions {
      .title {
        color: $color-secundary;
        margin: 0 1.2em 0 0;
      }
    }
    .EventStaffAssigned-footer-toolsbar {
      width: 87.5%;
    }
  }

  .btn-print-options {
    color: $color-secundary;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 0.95em;
    i {
      margin-right: 0.4em;
    }
    &:hover {
      color: rgba($color-secundary, 0.65);
      color: $color-primary;
    }
  }

  .btn-overtime {
    padding: 0;
  }
  .btn-overlap {
    padding: 0;
  }
}

table.eventStaffAssignedList {
  font-size: 0.85em;
  tr {
    border-bottom: 1px solid $color-gray;
    border-bottom: 1px solid $color-gray-lighter;
    &.row-selected {
      border:1px solid rgba($color-primary-dark, 0.55);
      box-shadow: inset 0px 0px 7px $color-primary;

      td {
        border-top: 1px solid rgba($color-primary-dark, 0.55);
      }
    }
    &.empty {
      td {
        opacity: 0.4;
      }
      .rowSelected {
        opacity: 1;
      }
    }
  }
  th,
  td {
    position: relative;
    text-align: center;
    vertical-align: middle;
    padding: 0.25rem 0.25rem;
  }
  td {
    border: none;
  }
  .form-group {
    display: inline-block;
    margin: 0 0 0 0.5em;
  }

  .checkbox {
    .fa-square {
      color: rgba($primary-font-color, 0.6);
    }
    .fa-check-square {
      color: $color-primary;
    }
  }

  td.rowSelected {
    width: 20px;
    padding: 0.4rem 0rem 0.4rem 0.45rem;
    .checkbox {
      padding: 0;
    }
  }
  td.employee_num {
    width: 65px;
    padding: 0.4rem 0.5rem;
  }

  td.staff_name {
    text-align: left;
    min-width: 180px;
  }

  .staff-member-info {
    position: relative;
    cursor: default;
    .avatar {
      width: 18px;
      height: 18px;
      margin-right: 0.5em;
    }
    .staff-name {
      margin: 0;
      line-height: 24px;
    }

    &:hover {
      .staff-member-info-overlay {
        display: block;
      }
    }
  }

  td.rating {
    width: 70px;
    padding: 0.4rem 0.25rem;
  }

  .date-wrapper {
    width: 310px;
    margin: 0 auto;
    .btn-datepicker {
      min-width: 145px;
    }

    .form-group {
      margin: 0 0.3rem;
    }
    i {
      color: rgba($primary-font-color, 0.6);
      cursor: pointer;
      font-size: 0.85em;
      width: 15px;
      line-height: 16px;
      margin-left: 0.5em;
      position: absolute;

      &:hover {
        color: $color-primary;
      }
      &.fa-times {
        top: 30%;
      }
    }
  }

  td.pay_rate {
    min-width: 90px;
    .payrate-wrapper {
      i {
        color: rgba($primary-font-color, 0.6);
        cursor: pointer;
        font-size: 0.85em;
        width: 15px;
        line-height: 16px;
        z-index: 1;
        margin-left: 0.5em;
        position: absolute;

        &:hover {
          color: $color-primary;
        }
      }
    }
  }

  td.group {
    width: 90px;

    .css-1hwfws3 {
      justify-content: center;
      padding-right: 0;
      padding-left: 0;
    }
    .css-tlfecz-indicatorContainer,
    .css-1gtu0rj-indicatorContainer {
      padding: 4px;
    }
  }
  td.volunteer {
    width: 70px;
  }

  td.salary {
    width: 60px;
  }
}

.stations-center {
  label {
    margin-bottom: 0 !important;
    padding-right: 15px;
  }
  padding-bottom: 10px;
  margin: 0 auto;
  width: 190px;
}

.testing-mode-container {
  width: 450px;
  padding: 8px 16px 1px 16px;
  margin-bottom: 20px;
  background-color: $color-gray;
  color: #FFFFFF;
}
