@import "../../_mixins/variables";
@import "../../_mixins/mixins";

.table.users {
  th,
  td {
    text-align: center;
  }
  td {
    vertical-align: middle;
  }

  .first_name, .last_name {
    text-align: left;
    width: 20%;
  }
  .edit,
  .delete {
    width: 3rem;
  }
}

.btn-add {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0.15em 0 0;
  width: 31px;
  height: 31px;
}

.modal-user-change-password {
  .modal-dialog {
    width: 400px;
  }
}