@import "../../../../_mixins/variables";
@import "../../../../_mixins/mixins";

.EventCheckIn {
  min-height: 72vh;
  padding-bottom: 13rem;
  .EventCheckIn-header {
    margin-bottom: 0.5em;
  }

  .mt-3, .my-3 {
    margin-top: 0 !important;
    font-size: 0.82em;
  }

  .filter-by-shift {
    min-width: 200px;
  }
  .OptionsContainer {
    width: 100%;
    text-align: right;
  }
  .SearchBox {
    margin-left: 32px;
    width: 200px;
  }
  .form-control {
    height: calc(1.5em + 0.75rem + 5px);
  }

  table.CheckInList {
    th, td {
      &.staff_name,
      &.position_name {
        width: 15%;
      }

      &.volunteer {
        text-align: center;
        width: 5%;
      }

      &.start_date,
      &.end_date {
        text-align: center;
        width: 15%;
      }

      &.shirt_number,
      &.jacket_number,
      &.radio_number,
      &.flagger_number {
        text-align: center;
      }

      &.check_in,
      &.check_out {
        text-align: center;
        width: 9%;
      }
    }
  }

  .btn-print-options {
    color: $color-secundary;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 0.95em;
    i {
      margin-right: 0.4em;
    }
    &:hover {
      color: rgba($color-secundary, 0.65);
      color: $color-primary;
    }
  }
}

.EventsCheckInSummary {
  border: 1px solid rgba($primary-font-color, 0.4);
  border-radius: 5px;
  width: 300px;
  
  .fa-spin {
    color: $color-secundary;
    font-size: 0.8rem;
  }

  .EventsCheckInSummary-item {
    padding: 0.8em 0 0.55em;
    text-align: center;

    .EventsCheckInSummary-item-label {
      color: lighten($primary-font-color, 35);
      font-size: 0.95em;
      margin: 0;
    }
    .EventsCheckInSummary-item-info {
      color: $color-secundary;
      font-size: 1.25em;
      font-weight: 600;
      margin: 0;
    }
  }
}
