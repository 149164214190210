@import "../../_mixins/variables";
@import "../../_mixins/mixins";

.volunteers-page {
  .table.organizations {
    th,
    td {
      text-align: center;
      vertical-align: middle;

      &.name {
        text-align: left;
        width: 23vw;
      }
      &.available_all_positions {
        width: 15vw;
      }
      &.edit,
      &.delete {
        width: 3rem;
      }
    }
    
  }
  .form-footer {
    padding: 1.5rem 0 2rem;
    text-align: right;
  }

  .volunteers-form {
    width: 98%;
    .add-volunteer {
      background-color: $color-gray-light;
      padding: 1rem 1rem 0;
    }
    .card-title {
      color: $color-secundary;
      font-size: 0.98rem;
      font-weight: 600;
    }

    .table {
      &.volunteers {
        th,
        td {
          text-align: center;
          vertical-align: middle;

          &.first_name,
          &.last_name {
            text-align: left;
          }
        
          &.edit,
          &.delete {
            width: 3rem;
          }
        }
      }
    }
  }
}

