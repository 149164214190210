@import "./_mixins/variables";
@import "./_mixins/mixins";

html {
  height: 100%;
  background-color: $color-white;
}
body {
  color: $primary-font-color;
  margin: 0;
  padding: 0 0 0 0;
  font-family: $primary-font-family;
  height: 100%;
  overflow-x: auto;
  font-size: 13px;
  @include linear-gradient(to top, $color-white 15%, lighten($color-gray, 45) 90%);
}
#root {
  height: 100%;
}
.full-height {
  height: 100%;
}
.clickable {
  cursor: pointer;
}

.blueIcon {
  color: $color-primary-dark;
}
.redIcon {
  color: $color-red;
}

