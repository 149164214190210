@import "../../../_mixins/variables";
@import "../../../_mixins/mixins";

.modal-delete-confirmation {
  text-align: center;
  padding: 0!important;

  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
  }

  .modal-header {
    border: none;
    .modal-title {
      color: $color-secundary;
    }
  }
  .modal-body {
    padding-bottom: 0.25rem;
    padding-top: 0.25rem;
  }
  .modal-footer {
    border: none;
  }

  .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    width: 380px;

    p {
      b {
        font-weight: 600;
      }
    }
  }

  .btn-success {
    background-color: $btn-primary;
    border-color: $btn-primary;
    padding: 0.375rem 2.75rem;
    &:hover {
      background-color: darken($btn-primary,  13);
    }
  }
}
