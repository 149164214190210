@import "../../_mixins/variables";
@import "../../_mixins/mixins";

.SettingsPage {
  .page-body {
    padding-bottom: 1.5rem;
  }
  .content-wrapper {
    padding-top: 0.5rem;
  }
}

