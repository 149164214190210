
.public {
  font-size: 1.1rem;

  h1 {
    color: #6B779B;
    font-size: 1.3rem;
    font-weight: 600;
  }
  h2{
    color: #6B779B;
    font-size: 1.2rem;
    font-weight: 600; 
  }
  p {
    color: #a3a3a3;
  }
  body {
    font-family: "Source Sans Pro", sans-serif;
    background: linear-gradient(to top, #FFFFFF 15%, #f3f3f3 90%);
  }
  .main {
    background-color: #fefefe;
    padding: 1.25rem 1.5rem;
    border-radius: 8px;
    margin: 0 auto;
    box-shadow: 2px 2px 4px rgba(#808080, 0.45);
  }
  .header {
    background-color: #ffffff;
    margin: 0 auto;
    padding: 70px 20px 20px 20px;
    text-align: center;
  }
  .content {
    background-color: #ffffff;
    margin: 0 auto;
    padding: 20px 20px 20px 20px;
  }
  .content-center {
    text-align: center;
  }
  .message {
    width: 100%;
  }
  .team {
    color: #6B779B;
    font-size: 1rem;
    font-weight: 500; 
  }
  .rights  {
    text-align: center;
    color: #6B779B;
    font-size: 0.75rem;
    font-weight: 400;
  }
  .disclaimer {
    text-align: center;
    color: #6B779B;
    font-size: 0.75rem;
    font-weight: 400;
    padding-top: 30px;
  }
  .btn {
    width: 180px;
    margin-left: 6px;
  }
  @media (min-width:320px)  { 
    .main {
      width: 90%;
    }
    .content {
      padding: 0;
    }
    .btn {
      margin-top: 10px;
    }
  }
  @media (min-width:481px)  { 
    .maint {
      width: 80%;
    }
    .btn {
      margin-top: 10px;
    }
  }
  @media (min-width:641px)  { 
    .main {
      width: 70%;
    }
  }
  @media (min-width:961px)  { 
    .main {
      width: 70%;
    }
  }
  @media (min-width:1025px) { 
    .main {
      width: 60%;
    }
  }
  @media (min-width:1281px) {
    .main {
      width: 40%;
    }
  }
}