@import "../../../_mixins/mixins";
@import "../../../_mixins/variables";

.Loading {
  display: flex;
  justify-content: center;
  align-items: center;

  .loader {
    color: $color-primary;
    font-size: 2rem;
    // position: absolute;
    // top: 30vh;
    // left: 48vw;
  }

  &.tabsContentLoading {
    height: 50vh;

    .loader {
      color: $color-primary;
      font-size: 2rem;
      height: 50px;
      width: 50px;
      text-align: center;
    }
  }

  &.absolute {
    background-color:rgba($color-white, 0.8);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9998;
  }

  &.innerSwipeView {
    position: relative;
    height: 50vh;

    .loader {
      color: $color-primary;
      font-size: 2rem;
      height: 50px;
      width: 50px;
    }
  }

  &.staffMembers {
    position: relative;
    height: 283px;
    .loader {
      font-size: 1.5em;
    }
  }

  &.assignedStaffMembers {
    position: relative;
    height: 35vh;
    .loader {
      font-size: 1.8em;
    }
  }
}
