@import "../../_mixins/variables";
@import "../../_mixins/mixins";

.DashboardPage {

  .DashboardPageHeader {
    margin-bottom: 1.75em;
  }

  .DashboardList {
    .DashboardList-header {
      text-align: center;
      padding:2.5rem 0 1rem;
      h2 {
        color: $color-primary-dark;
        font-size: 1.65rem;
      }
    }
    .DashboardList-wrapper {
      // .col-widgets{
      //   width: 100%;
      // }
  
      .col-auto{
        float: left;
      }

      .DashboardList-item {
        border-radius: 0.25rem;
        border: none;
        box-shadow: 1px 1px 4px rgba($color-black, 0.25);
        color: $color-secundary;
        margin-bottom: 1.5em;
        min-height: 285px;
        text-align: left;
        width: 370px;
        border-bottom: 7px solid transparent; 
        border-top: 7px solid transparent; 
  
        .fa, .fas, .far {
          font-size: 1em;
        }

        i {
          font-size: 1.45em;
          margin-bottom: 0.25em;
        }

        a {
          color: $color-secundary;
        }
  
        &:hover {
          color: $primary-font-color;
          border-top: 7px solid $color-primary; 
          border-bottom: 7px solid transparent; 
        }
        &.selected {
          color: $color-primary;
        }

        .message{
          padding-top: 8px;
        }

        .no-data {
          margin-top: 70px;
          text-align: center;
        }
      }

      .systemItem{
        background-color: $color-system-messages;
        margin-bottom: 1em;
      }

      @media only screen and (min-width : 1367px) {
        .systemItem {
          width: 768px;
        }
      }

      @media only screen 
      and (min-device-width : 375px) 
      and (max-device-width : 812px) 
      and (-webkit-device-pixel-ratio : 3) {
        .row {
          display: inline;
        }
        .DashboardList-item {
          width: 280px;
        }
      }

      @media only screen 
      and (min-device-width : 768px) 
      and (max-device-width : 1024px) 
      and (orientation : landscape) { 
        .row {
          display: inline;
        }
      }

      @media only screen 
      and (min-device-width : 768px) 
      and (max-device-width : 1024px) 
      and (orientation : portrait) { 
        .row {
          display: inline;
        }
        .DashboardList-item {
          width: 320px;
        }
      }
    }
  }
}
