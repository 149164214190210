
@import "../../../_mixins/variables";
@import "../../../_mixins/mixins";

.DepartmentRate {
  .DepartmentRateTable {
    th,
    td {
      &.created_date {
        width: 25%;
      }
      &.user {
        width: 25%;
      }
      &.department {
        width: 25%;
      }
      &.hourly_rate {
        width: 25%;
      }
    }
  }

  .table-console-left {
    padding: 0 0 1rem;
  }
}
