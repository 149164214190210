@import "../../../_mixins/variables";
@import "../../../_mixins/mixins";

.StaffPayRateSettings {
  .table {
    tbody {
      tr {
        &.add {
          background-color: rgba($color-secundary, 0.1);
        }
        &.editing {
          background-color: rgba($color-primary, 0.05);
        }
      }
    }
    th, td {
      text-align: center;
      &:first-child {
        text-align: left;
      }
    }

    th {
      &.last_name {
        width: 20vw;
      }
      &.sort_date {
        width: 10vw;
      }
      &.pay_rates {
        width: 70vw;
      }
    }

    td {
      vertical-align: middle;
      position: relative;
      .form-group {
        margin: 0;
      }
    }

    .input-group {
      flex-wrap: inherit;
    }
    .input-group-prepend {
      .input-group-text {
        font-size: 0.85rem;
        line-height: 1;
      }
    }
  }

  .StaffPayRateSettingsFilters {
    margin-bottom: 1.5rem;
    padding: 0.25rem 0;
    .setting-label {
      color: $color-secundary;
      font-size: 0.98rem;
      font-weight: 600;
      text-transform: uppercase;
      margin: 0;
    }
    .form-group {
      margin: 0;
    }
  }


  .StaffPayRateSettingsCard {
    margin: 0.5rem;
    padding: 0.5rem;
    float: left;

    .card-title {
      color: $color-secundary;
      font-size: 0.98rem;
      font-weight: 600;
    }
    .card-subtitle {
      font-size: 0.75rem;
      margin-top: -0.375rem;
      margin-bottom: 0;
      color: $primary-color-light;
    }

    .ratesContainer {
      margin: 1px;
    }

    .travelPayRateColumn {
      float: right;
      max-width: 130px;
      margin: 5px;
    }

    .regularPayRateColumn {
      float: left;
      max-width: 130px;
      margin: 5px;
    }

    .StaffPayRateSettingsEdit {
      .form-group {
        margin: 0.5rem 0;
      }

      label {
        font-size: 0.9rem;
      }
      .input-group-prepend {
        .input-group-text {
          font-size: 0.85rem;
          line-height: 1;
        }
      }

      .alert {
        position: absolute;
        border: none;
        border-radius: 0;
        font-size: 0.83rem;
        padding: 0;
        bottom: -1.4rem;
    
        &.alert-danger {
          color: $color-danger;
          background-color: transparent;
        }
      }

      .read-only {
        p {
          line-height: 2rem;
          font-size: 1.3rem;
          font-weight: 300;
          margin-bottom: 0;
        }
      }
    }
  }

}
