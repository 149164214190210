@import "../../../../../_mixins/variables";
@import "../../../../../_mixins/mixins";

.EventsStaffStats {
  border: 1px solid rgba($primary-font-color, 0.4);
  border-radius: 5px;
  width: 100%;

  .shared-resources {
    margin-left: 10px;
  }

  .volunteers {
    margin-left: 10px;

    &.badge-volunteer {
      color: $color-volunteer;
    }
  }
  
  .fa-spin {
    color: $color-secundary;
    font-size: 0.8rem;
  }

  .btn-shared-resources {
    color: $color-travel;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 0.95em;
    height: 30px;
    margin-top: -10px;
    padding: 0.375rem;
    i {
      margin-right: 0.4em;
      transform: rotate(-45deg);
    }
    &:hover {
      color: rgba($color-secundary, 0.65);
      color: $color-primary;
    }
  }

  .btn-volunteers {
    color: $color-volunteer;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 0.95em;
    height: 30px;
    margin-top: -10px;
    padding: 0.375rem;
    i {
      margin-right: 0.4em;
    }
    &:hover {
      color: rgba($color-secundary, 0.65);
      color: $color-primary;
    }
  }

  .EventsStaffStats-item {
    padding: 0.8em 0 0.55em;
    text-align: center;
    .EventsStaffStats-item-label {
      color: lighten($primary-font-color, 35);
      font-size: 0.95em;
      margin: 0;
    }
    .EventsStaffStats-item-info {
      color: $color-secundary;
      font-size: 1.25em;
      font-weight: 600;
      margin: 0;
    }

    &.current-hourly-rate {
      .EventsStaffStats-item-info {
        color: $color-danger;
        .fa-check  {
          font-size: 0.75em;
        }
      }

      &.on-target {
        .EventsStaffStats-item-info {
          color: $color-success;
        }
      }
    }
  }
}

.modal-shared {
  .modal-dialog { width: 1060px; }
  .modal-body {
    .col {
      border: 1px solid $divider-color;
      margin: 10px;
      float: left;
      padding: 10px;
      width: 320px;

      h6{
        text-align: center;
      }
    }
  }
}