@import "../../_mixins/variables";
@import "../../_mixins/mixins";

.login-page {
  min-height: 100%;
  background-color: lighten($color-primary, 40);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .login-wrapper {
    background-color: $color-white;
    padding: 2.5rem;
    margin: 2.5em;
    @include respondTo-small {
      margin: 2.5em auto;
    }
    @include respondTo-medium {
      margin: 2.5em 0;
    }

    .brand {
      margin: 2rem auto 2.5rem;
    }
    h4 {
      color: $secundary-font-color;
      font-size: 1.3rem;
      font-weight: 600;
    }
    p {
      color: lighten($primary-font-color, 35);
      font-size: 0.85rem;
    }
    .btn {
      cursor: pointer;
      padding: 0.375rem 2.3rem;
      text-transform: uppercase;
      &:disabled {
        cursor: default;
      }
    }
  }

  .login-hero {
    color: $color-white;
    text-align: center;
    @include respondTo-medium {
      text-align: left;
    }
    h2 {
      font-size: 1.8rem;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 7.5px;
    }
    h3 {
      font-size: 3rem;
      font-weight: 300;
      line-height: 3rem;
      text-transform: uppercase;
      margin: 2rem 0;
    }
    p {
      font-size: 1.5rem;
      a {
        color: $color-white;
        &:hover {
          color: rgba($color-white, 0.7);
          text-decoration: none;
        }
      }
    }
  }

  .recoverLink {
    background-color: transparent;
    border: 0;
    color: #6B779B;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 0.95em;

    &:hover {
      color: $color-primary;
    }
  }

  .footer {
    color: $color-white;
    color: rgba($color-white, 0.4);
    font-size: 1.15rem;
    .left-content-footer {
      float: left;
    }
    .right-content-footer {
      float: right;
    }
    .copyright {
      text-align: center;
      margin: 2rem 0 0;
      line-height: 1;
      @include respondTo-small {
        text-align: left;
        margin: 0 0 0.5rem;
        line-height: inherit;
      }
    }
    .version {
      text-align: center;
      line-height: 1.2;
      @include respondTo-small {
        margin-bottom: 0.5rem;
        text-align: right;
        line-height: inherit;
      }
    }

    a {
      color: $color-white;
      &:hover {
        color: rgba($color-white, 0.5);
        text-decoration: none;
      }
    }
  }
}

.modal-recover-password {
  .table {
    thead {
      th {
        color: $color-secundary;
        font-weight: 600;
        border-top: none;
        border-bottom: 2px solid rgba($color-secundary, 0.7);
        tr {
          display: block;
        }
      }
    }
  }
  .scroll-wrapper {
    overflow-x: auto;
    height: 120px;
  }
}
