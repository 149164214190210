@import "../../../_mixins/mixins";
@import "../../../_mixins/variables";


.form-wrapper {
  padding: 1rem;
}
.form-group {
  margin-bottom: 2rem;
  
  label {
    display: inline-block;
  }
  .label-bold {
    font-weight: bold;
  }

  &.has-error {
    label {
      color: $color-danger;
    }
    input {
      border-color: $color-danger;
      background-color: rgba($color-danger, 0.04);

      &:focus {
        border-color: rgba($color-primary, 0.3);
        background-color: transparent;
        +label {
          color: inherit;
        }
      }
    }
    select {
      border-color: $color-danger;
      background-color: rgba($color-danger, 0.04);
    }
  }

  .alert {
    position: absolute;
    border: none;
    border-radius: 0;
    font-size: 0.83rem;
    padding: 0.28rem 0.3rem;

    &.alert-danger {
      color: $color-danger;
      background-color: transparent;
    }
  }
}
.avatar-upload {
  padding-top: 1rem;
  .avatar-wrapper {
    border-radius: 50%;
    width: 165px;
    height: 165px;;
    margin-bottom: 1rem;
    overflow: hidden;
  }
  label.btn {
    font-size: 0.9rem;
    margin-bottom: 0.8rem;
  }
  input {
    visibility: hidden;
  }
  .requisite {
    margin: 0 6rem;
    line-height: 1.25;
    width: 185px;
  }
}
.btn-wrapper {
  margin-top: 2rem;
}

.react-datepicker-wrapper {
  display: block;

  .react-datepicker__input-container {
    display: block;
  }
}

.form-group {
  label {
    &.required {
      &::after {
        color: $color-danger;
        content: "*";
        padding-left: 0.15rem;
      }
    }
  }
}
.form-group-label {
  color: $color-secundary;
  font-size: 1.25rem;
  margin: 1rem 0 0.5rem;
}

.form-control {
  font-size: 1em;
}

.checkbox {
  position: relative;
  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 1.2rem;
    height: 100%;
  }
  label {
    position: relative;
    display: inline-block;
    padding-left: 1.4rem;
    line-height: 1.4rem;
    min-height: 1.4rem;
    margin: 0;
    &::after,
    &::before {
      position: absolute;
      content: "";
      display: inline-block;
    }
    &::before{
      height: 1rem;
      width: 1rem;
      border: 1px solid;
      left: 0px;
      top: 3px;
      border-radius: 2px;
    }
    /*Checkmark of the fake checkbox*/
    &::after {
      height: 5px;
      width: 9px;
      border-left: 2px solid $color-white;
      border-bottom: 2px solid $color-white;
      transform: rotate(-45deg);
      left: 4px;
      top: 7px;
    }
  }

  /*Hide the checkmark by default*/
  input[type="checkbox"] + label::after {
    content: none;
  }

  /*Unhide on the checked state*/
  input[type="checkbox"]:checked + label::after {
    content: "";
  }
  input[type="checkbox"]:checked + label::before {
    background-color: $color-primary;
    border-color: $color-primary;
  }

  /*Adding focus styles on the outer-box of the fake checkbox*/
  input[type="checkbox"]:focus + label::before {
    outline: rgb(59, 153, 252) auto 5px;
  }
}

// DATEPICKER
.datepicker-input {
  background-image: url('/img/icn-calendar-day.svg');
  background-repeat: no-repeat;
  background-size: 1.35rem;
  background-position: 98% center;

  &.form-control-sm {
    background-size: 1.1em;
    font-size: 0.98em;
    text-align: center;
    padding-right: 1.3em;
    background-position: 97.5% center;
  }
}
.react-datepicker-popper {
  z-index: 5 !important;
}
.datepicker-calendar {
  .react-datepicker__input-time-container {
    float: none;
    width: auto;
    margin: 0.5em 1em 1.5em;
    .react-datepicker-time__input-container {
      width: 80%;
      margin-left: 0.8em;
      .react-datepicker-time__input {
        margin-left: 0;
        display: block;
        input {
          font-size: 13px;
          border-radius: 4px;
          box-shadow: inset 0 2px 2px #e9e9e9;
          border: 1px solid #aeaeae;
          line-height: 16px;
          padding: 6px 10px 5px;
          width: 100%;
        }
      }
    } 
  }
}

// TODO:  Update to top Clasess
.react-datepicker__input-container {
  input {
    background-image: url('/img/icn-calendar-day.svg');
    background-repeat: no-repeat;
    background-size: 1.35rem;
    background-position: 98% center;

    &.form-control-sm {
      background-size: 1.1em;
      font-size: 0.98em;
      text-align: center;
      padding-right: 1.3em;
      background-position: 97.5% center;
    }
    &.alert-danger {
      color: $color-danger;
      background-color: $color-white;
      border-color: $color-danger;
    }
  }
}

.form-control {
  &.icn-filter {
    &::after {
      font-family: "Font Awesome 5 Free";
      content: "\F0B0";
    }
  }
}

.switch {
  .label {
    margin-bottom: 0.5rem;
  }
  .strongTitle {
    font-weight: bold;
  }
  input[type=checkbox]{
    height: 0;
    width: 0;
    visibility: hidden;
  }
  
  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 50px;
    height: 28px;
    background: lighten($color-gray, 30);
    display: block;
    border-radius: 100px;
    position: relative;
  }
  
  label:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    width: 22px;
    height: 22px;
    background: $color-white;
    border-radius: 90px;
    transition: 0.3s;
  }
  
  input:checked + label {
    background: $color-primary;
  }
  
  input:checked + label:after {
    left: calc(100% - 3px);
    transform: translateX(-100%);
  }
  
  label:active:after {
    width: 85%;
  }

}

.currency-input {
  .input-group-prepend {
    .input-group-text {
      font-size: 0.85rem;
      line-height: 1;
    }
  }
  &.has-error {
    .input-group-prepend {
      .input-group-text {
        border: 1px solid $color-danger;
        border-right: none;
      }
    }
    .form-control {
      border-left: none;
    }
  }
}


.select-style-default {
  .css-yk16xz-control,
  .css-1pahdxg-control,
  .css-1fhf3k1-control  {
    border: none;
    background-color: transparent;
    box-shadow: none;
    
    .css-1okebmr-indicatorSeparator,
    .css-109onse-indicatorSeparator {
      display: none;
    }
    .css-tlfecz-indicatorContainer {
      color: $color-primary;
    }
  
    .css-tj5bde-Svg {
      height: 16px;
      width: 16px;
    }
  }

  &.underline {
    border-bottom: 1px solid $color-gray-lighter;
  }
}
