@import "../../../_mixins/mixins";
@import "../../../_mixins/variables";

.pagination-console {
  .over-loading{
    font-size: 1.1rem;
    color: $color-primary;
  }
  .pagination-info {
    color: lighten($primary-font-color, 35);
    font-size: 0.85rem;
    
    + .pagination-info {
      margin-right: 0.5rem;
    }

    b {
      font-weight: 600;
    }
  }
  .pagination {
    margin: 0 0 0 0.5rem;

    .page-link {
      color: lighten($primary-font-color, 15);
      &:disabled {
        cursor: not-allowed;
        color: rgba(lighten($primary-font-color, 15), 0.35);
        &:hover {
          background-color: transparent
        }
      }
    }

    .prev-page {
      border-right: 0;
    }
    .next-page {
      border-left: 0;
    }

    .goto-page {
      .form-group {
        text-align: center;
        width: 50px;
        margin: 0 0.05rem;
        input {
          text-align: center;
          border-radius: inherit;
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }
}
