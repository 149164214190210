@import "../../../../../_mixins/variables";
@import "../../../../../_mixins/mixins";

.EventStaffAvailable {
  background-color: $color-gray-light;
  border-radius: 5px;
  padding: 1em;

  .EventStaffAvailable-header {
    margin-bottom: 0.25em;
    .title {
      font-size: 1.1em;
      font-weight: 400;
      width: 62%;
      margin: 0;
    }
    .select-department {
      width: 38%;
    }

    .select-department {
      .css-yk16xz-control,
      .css-1pahdxg-control  {
        border: none;
        background-color: transparent;
        box-shadow: none;
        .css-1uccc91-singleValue {
          color: $color-secundary;
          font-size: 0.95em;
        }
        .css-1okebmr-indicatorSeparator {
          display: none;
        }
        .css-tlfecz-indicatorContainer {
          color: $color-primary;
        }
        .css-tj5bde-Svg {
          height: 16px;
          width: 16px;
        }
      }
    }
  }

  .EventStaffAvailable-filters {
    margin-bottom: 0.65em;
    font-size: 1.1em;

    .EventStaffAvailable-search {
      .EventStaffAvailable-filter {
        width: 30%;
      }
      .EventStaffAvailable-filter-wide {
        width: 53%;
      }
      .EventStaffAvailable-type {
        margin-left: 6px;
        width: 32%;
      }
      .EventStaffAvailable-status {
        margin-left: 6px;
        width: 26%;
      }
      .EventStaffAvailable-add {
        padding-top: 3px;
        margin-left: 6px;
        width: 5%;
      }
    }
  }

  .EventStaffAvailable-list {
    background-color: $color-gray-lightest;

    .alert-share-resource {
      background-color: rgba($color-secundary, 0.25);
      color: $color-secundary;
      font-size: 0.8em;
      padding: 0.45em 0.65em;
      p { margin: 0; }
      b { font-weight: 600; }
      i {font-size: 0.895em; margin-right: 0.2em;}
    }

    .EventStaffAvailable-item {
      padding: 0.3em 0.25em;
      border: 1px solid transparent;
      border-bottom: 1px solid lighten($color-secundary, 30);
      cursor: pointer;

      &.isStaff {
        cursor: default;
        .EventStaffAvailable-item-info {
          .avatar,
          .name {
            opacity: 0.6;
          }
          .staff-member-info-overlay {
            .name {opacity: 1;}
          }
        }
        .staff-badges {
          opacity: 0.6;
        }
        .organization-wrapper {
          opacity: 0.6;
        }
      }
      &.isSelected {
        border:1px solid rgba($color-primary-dark, 0.55);
        box-shadow: inset 0px 0px 3px $color-primary;
      }
      &.notSelected {
        &:hover{
          border:1px solid rgba($color-primary-dark, 0.55);
          box-shadow: inset 0px 0px 3px $color-primary;
        }
      }
      .EventStaffAvailable-item-info {
        width: 40%;
        position: relative;
        .avatar {
          width: 21px;
          margin-right: 0.35em;
        }
        .name {
          font-size: 0.85em;
          margin: 0;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 90%;
          white-space: nowrap;
          padding-left: 0.35em;
        }
        &:hover {
          .staff-member-info-overlay {
            display: block;
          }
        }
      }
      .staff-badges {
        width: 55%;
        text-align: right;
        .selected {
          margin: 0 0.35em;
          font-size: 0.75em;
          font-weight: 600;
          &.fa-check {
            color: $color-success;
          }
          &.fa-uncheck {
            width: 10px;
          }
        }
      }
      .organization-wrapper {
        width: 55%;
        text-align: center;
        margin: 0 0.35em;
        font-size: 0.9em;
        font-weight: 600;
      }
      .notification-status-general {
        padding-left: 10px;
        font-size: 0.85em;
        font-weight: 400;
      }
      .notification-status {
        width: 75px;
        font-size: 0.9em;
        font-weight: 400;
        float: left;
        text-align: left;
      }
      .circle-wrapper {
        position: relative;
        width: 10px;
        height: 20px;
        float: left;
      }
      .circle {
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        display: flex;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $color-not-notified;
      }
      .Notified {
        background-color: $color-notified;
      }
      .Accepted {
        background-color: $color-accepted;
      }
      .Rejected {
        background-color: $color-rejected;
      }
      .notification-type {
        width: 55px;
        font-size: 0.75em;
        font-weight: 400;
        float: left;
      }
    }
    .badge-notification-ban {
      color: $color-rejected;
    }
    .badge-notification-check {
      color: $color-accepted;
    }
    .staff-member-info-overlay {
      left: 0;
      width: 260px;
      font-size: 0.8em;
      &:after, &:before {
        left: 7.65%;
      }
    }
    .notice {
      color: $primary-font-color;
    }
  }

  .EventStaffAvailable-footer {
    padding: 1em 0 0;
    p {
      margin: 0;
    }
    .btn {
      text-transform: uppercase;
    }
  }
}