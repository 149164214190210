@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
html {
  height: 100%;
  background-color: #FFFFFF; }

body {
  color: #4A4A4A;
  margin: 0;
  padding: 0 0 0 0;
  font-family: "Source Sans Pro", sans-serif;
  height: 100%;
  overflow-x: auto;
  font-size: 13px;
  background: #FFFFFF;
  background: linear-gradient(to top, #FFFFFF 15%, #f3f3f3 90%); }

#root {
  height: 100%; }

.full-height {
  height: 100%; }

.clickable {
  cursor: pointer; }

.blueIcon {
  color: #0080C4; }

.redIcon {
  color: #ff0000; }

.navbar {
  padding: 0 0 0.15rem; }
  .navbar .navbar-brand {
    width: 70px;
    margin: 0 0.5rem 0 0.65rem; }
  .navbar .navbar-content-left {
    margin-right: 0.8rem; }
    .navbar .navbar-content-left .btn {
      background-color: transparent;
      color: #6B779B; }
    .navbar .navbar-content-left .btn-success {
      background-color: transparent;
      border-color: transparent; }
    .navbar .navbar-content-left .btn-success:not(:disabled):not(.disabled):active:focus,
    .navbar .navbar-content-left .btn-success:not(:disabled):not(.disabled).active:focus,
    .navbar .navbar-content-left .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0; }
  .navbar .navbar-nav {
    flex-direction: row; }
    .navbar .navbar-nav .nav-item {
      font-size: 0.95rem;
      color: #6B779B;
      line-height: 2.8rem;
      padding: 0.5rem 0.75rem 0;
      border-bottom: 0.15rem solid transparent;
      transition: all 0.4s; }
      .navbar .navbar-nav .nav-item:hover, .navbar .navbar-nav .nav-item.active {
        color: #00B5F3; }
  .navbar .profile-nav .profile-nav-item {
    color: #6B779B;
    display: inline-block;
    margin: 0 0.5rem; }
    .navbar .profile-nav .profile-nav-item.profile span {
      display: inline-block; }
    .navbar .profile-nav .profile-nav-item.profile .profile-name {
      font-size: 0.95rem;
      color: #6B779B; }
    .navbar .profile-nav .profile-nav-item.profile .profile-avatar {
      width: 1.6rem;
      margin-left: 0.5rem; }
    .navbar .profile-nav .profile-nav-item.logout {
      color: #FFFFFF;
      background-color: rgba(107, 119, 155, 0.5);
      border-radius: 50%;
      margin: 0;
      text-align: center;
      width: 1.98rem;
      font-size: 0.98rem;
      line-height: 1.98rem;
      transition: background-color 0.8s; }
      .navbar .profile-nav .profile-nav-item.logout:hover {
        background-color: #00B5F3; }

.form-wrapper {
  padding: 1rem; }

.form-group {
  margin-bottom: 2rem; }
  .form-group label {
    display: inline-block; }
  .form-group .label-bold {
    font-weight: bold; }
  .form-group.has-error label {
    color: #dc3545; }
  .form-group.has-error input {
    border-color: #dc3545;
    background-color: rgba(220, 53, 69, 0.04); }
    .form-group.has-error input:focus {
      border-color: rgba(0, 181, 243, 0.3);
      background-color: transparent; }
      .form-group.has-error input:focus + label {
        color: inherit; }
  .form-group.has-error select {
    border-color: #dc3545;
    background-color: rgba(220, 53, 69, 0.04); }
  .form-group .alert {
    position: absolute;
    border: none;
    border-radius: 0;
    font-size: 0.83rem;
    padding: 0.28rem 0.3rem; }
    .form-group .alert.alert-danger {
      color: #dc3545;
      background-color: transparent; }

.avatar-upload {
  padding-top: 1rem; }
  .avatar-upload .avatar-wrapper {
    border-radius: 50%;
    width: 165px;
    height: 165px;
    margin-bottom: 1rem;
    overflow: hidden; }
  .avatar-upload label.btn {
    font-size: 0.9rem;
    margin-bottom: 0.8rem; }
  .avatar-upload input {
    visibility: hidden; }
  .avatar-upload .requisite {
    margin: 0 6rem;
    line-height: 1.25;
    width: 185px; }

.btn-wrapper {
  margin-top: 2rem; }

.react-datepicker-wrapper {
  display: block; }
  .react-datepicker-wrapper .react-datepicker__input-container {
    display: block; }

.form-group label.required::after {
  color: #dc3545;
  content: "*";
  padding-left: 0.15rem; }

.form-group-label {
  color: #6B779B;
  font-size: 1.25rem;
  margin: 1rem 0 0.5rem; }

.form-control {
  font-size: 1em; }

.checkbox {
  position: relative;
  /*Hide the checkmark by default*/
  /*Unhide on the checked state*/
  /*Adding focus styles on the outer-box of the fake checkbox*/ }
  .checkbox input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 1.2rem;
    height: 100%; }
  .checkbox label {
    position: relative;
    display: inline-block;
    padding-left: 1.4rem;
    line-height: 1.4rem;
    min-height: 1.4rem;
    margin: 0;
    /*Checkmark of the fake checkbox*/ }
    .checkbox label::after, .checkbox label::before {
      position: absolute;
      content: "";
      display: inline-block; }
    .checkbox label::before {
      height: 1rem;
      width: 1rem;
      border: 1px solid;
      left: 0px;
      top: 3px;
      border-radius: 2px; }
    .checkbox label::after {
      height: 5px;
      width: 9px;
      border-left: 2px solid #FFFFFF;
      border-bottom: 2px solid #FFFFFF;
      transform: rotate(-45deg);
      left: 4px;
      top: 7px; }
  .checkbox input[type="checkbox"] + label::after {
    content: none; }
  .checkbox input[type="checkbox"]:checked + label::after {
    content: ""; }
  .checkbox input[type="checkbox"]:checked + label::before {
    background-color: #00B5F3;
    border-color: #00B5F3; }
  .checkbox input[type="checkbox"]:focus + label::before {
    outline: #3b99fc auto 5px; }

.datepicker-input {
  background-image: url("/img/icn-calendar-day.svg");
  background-repeat: no-repeat;
  background-size: 1.35rem;
  background-position: 98% center; }
  .datepicker-input.form-control-sm {
    background-size: 1.1em;
    font-size: 0.98em;
    text-align: center;
    padding-right: 1.3em;
    background-position: 97.5% center; }

.react-datepicker-popper {
  z-index: 5 !important; }

.datepicker-calendar .react-datepicker__input-time-container {
  float: none;
  width: auto;
  margin: 0.5em 1em 1.5em; }
  .datepicker-calendar .react-datepicker__input-time-container .react-datepicker-time__input-container {
    width: 80%;
    margin-left: 0.8em; }
    .datepicker-calendar .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
      margin-left: 0;
      display: block; }
      .datepicker-calendar .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
        font-size: 13px;
        border-radius: 4px;
        box-shadow: inset 0 2px 2px #e9e9e9;
        border: 1px solid #aeaeae;
        line-height: 16px;
        padding: 6px 10px 5px;
        width: 100%; }

.react-datepicker__input-container input {
  background-image: url("/img/icn-calendar-day.svg");
  background-repeat: no-repeat;
  background-size: 1.35rem;
  background-position: 98% center; }
  .react-datepicker__input-container input.form-control-sm {
    background-size: 1.1em;
    font-size: 0.98em;
    text-align: center;
    padding-right: 1.3em;
    background-position: 97.5% center; }
  .react-datepicker__input-container input.alert-danger {
    color: #dc3545;
    background-color: #FFFFFF;
    border-color: #dc3545; }

.form-control.icn-filter::after {
  font-family: "Font Awesome 5 Free";
  content: "\F0B0"; }

.switch .label {
  margin-bottom: 0.5rem; }

.switch .strongTitle {
  font-weight: bold; }

.switch input[type=checkbox] {
  height: 0;
  width: 0;
  visibility: hidden; }

.switch label {
  cursor: pointer;
  text-indent: -9999px;
  width: 50px;
  height: 28px;
  background: #cdcdcd;
  display: block;
  border-radius: 100px;
  position: relative; }

.switch label:after {
  content: '';
  position: absolute;
  top: 3px;
  left: 3px;
  width: 22px;
  height: 22px;
  background: #FFFFFF;
  border-radius: 90px;
  transition: 0.3s; }

.switch input:checked + label {
  background: #00B5F3; }

.switch input:checked + label:after {
  left: calc(100% - 3px);
  transform: translateX(-100%); }

.switch label:active:after {
  width: 85%; }

.currency-input .input-group-prepend .input-group-text {
  font-size: 0.85rem;
  line-height: 1; }

.currency-input.has-error .input-group-prepend .input-group-text {
  border: 1px solid #dc3545;
  border-right: none; }

.currency-input.has-error .form-control {
  border-left: none; }

.select-style-default .css-yk16xz-control,
.select-style-default .css-1pahdxg-control,
.select-style-default .css-1fhf3k1-control {
  border: none;
  background-color: transparent;
  box-shadow: none; }
  .select-style-default .css-yk16xz-control .css-1okebmr-indicatorSeparator,
  .select-style-default .css-yk16xz-control .css-109onse-indicatorSeparator,
  .select-style-default .css-1pahdxg-control .css-1okebmr-indicatorSeparator,
  .select-style-default .css-1pahdxg-control .css-109onse-indicatorSeparator,
  .select-style-default .css-1fhf3k1-control .css-1okebmr-indicatorSeparator,
  .select-style-default .css-1fhf3k1-control .css-109onse-indicatorSeparator {
    display: none; }
  .select-style-default .css-yk16xz-control .css-tlfecz-indicatorContainer,
  .select-style-default .css-1pahdxg-control .css-tlfecz-indicatorContainer,
  .select-style-default .css-1fhf3k1-control .css-tlfecz-indicatorContainer {
    color: #00B5F3; }
  .select-style-default .css-yk16xz-control .css-tj5bde-Svg,
  .select-style-default .css-1pahdxg-control .css-tj5bde-Svg,
  .select-style-default .css-1fhf3k1-control .css-tj5bde-Svg {
    height: 16px;
    width: 16px; }

.select-style-default.underline {
  border-bottom: 1px solid #dee2e6; }

.login-page {
  min-height: 100%;
  background-color: #c0efff;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }
  .login-page .login-wrapper {
    background-color: #FFFFFF;
    padding: 2.5rem;
    margin: 2.5em; }
    @media (min-width: 768px) {
      .login-page .login-wrapper {
        margin: 2.5em auto; } }
    @media (min-width: 992px) {
      .login-page .login-wrapper {
        margin: 2.5em 0; } }
    .login-page .login-wrapper .brand {
      margin: 2rem auto 2.5rem; }
    .login-page .login-wrapper h4 {
      color: #6B779B;
      font-size: 1.3rem;
      font-weight: 600; }
    .login-page .login-wrapper p {
      color: #a3a3a3;
      font-size: 0.85rem; }
    .login-page .login-wrapper .btn {
      cursor: pointer;
      padding: 0.375rem 2.3rem;
      text-transform: uppercase; }
      .login-page .login-wrapper .btn:disabled {
        cursor: default; }
  .login-page .login-hero {
    color: #FFFFFF;
    text-align: center; }
    @media (min-width: 992px) {
      .login-page .login-hero {
        text-align: left; } }
    .login-page .login-hero h2 {
      font-size: 1.8rem;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 7.5px; }
    .login-page .login-hero h3 {
      font-size: 3rem;
      font-weight: 300;
      line-height: 3rem;
      text-transform: uppercase;
      margin: 2rem 0; }
    .login-page .login-hero p {
      font-size: 1.5rem; }
      .login-page .login-hero p a {
        color: #FFFFFF; }
        .login-page .login-hero p a:hover {
          color: rgba(255, 255, 255, 0.7);
          text-decoration: none; }
  .login-page .recoverLink {
    background-color: transparent;
    border: 0;
    color: #6B779B;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 0.95em; }
    .login-page .recoverLink:hover {
      color: #00B5F3; }
  .login-page .footer {
    color: #FFFFFF;
    color: rgba(255, 255, 255, 0.4);
    font-size: 1.15rem; }
    .login-page .footer .left-content-footer {
      float: left; }
    .login-page .footer .right-content-footer {
      float: right; }
    .login-page .footer .copyright {
      text-align: center;
      margin: 2rem 0 0;
      line-height: 1; }
      @media (min-width: 768px) {
        .login-page .footer .copyright {
          text-align: left;
          margin: 0 0 0.5rem;
          line-height: inherit; } }
    .login-page .footer .version {
      text-align: center;
      line-height: 1.2; }
      @media (min-width: 768px) {
        .login-page .footer .version {
          margin-bottom: 0.5rem;
          text-align: right;
          line-height: inherit; } }
    .login-page .footer a {
      color: #FFFFFF; }
      .login-page .footer a:hover {
        color: rgba(255, 255, 255, 0.5);
        text-decoration: none; }

.modal-recover-password .table thead th {
  color: #6B779B;
  font-weight: 600;
  border-top: none;
  border-bottom: 2px solid rgba(107, 119, 155, 0.7); }
  .modal-recover-password .table thead th tr {
    display: block; }

.modal-recover-password .scroll-wrapper {
  overflow-x: auto;
  height: 120px; }

.Loading {
  display: flex;
  justify-content: center;
  align-items: center; }
  .Loading .loader {
    color: #00B5F3;
    font-size: 2rem; }
  .Loading.tabsContentLoading {
    height: 50vh; }
    .Loading.tabsContentLoading .loader {
      color: #00B5F3;
      font-size: 2rem;
      height: 50px;
      width: 50px;
      text-align: center; }
  .Loading.absolute {
    background-color: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9998; }
  .Loading.innerSwipeView {
    position: relative;
    height: 50vh; }
    .Loading.innerSwipeView .loader {
      color: #00B5F3;
      font-size: 2rem;
      height: 50px;
      width: 50px; }
  .Loading.staffMembers {
    position: relative;
    height: 283px; }
    .Loading.staffMembers .loader {
      font-size: 1.5em; }
  .Loading.assignedStaffMembers {
    position: relative;
    height: 35vh; }
    .Loading.assignedStaffMembers .loader {
      font-size: 1.8em; }

.DashboardPage .DashboardPageHeader {
  margin-bottom: 1.75em; }

.DashboardPage .DashboardList .DashboardList-header {
  text-align: center;
  padding: 2.5rem 0 1rem; }
  .DashboardPage .DashboardList .DashboardList-header h2 {
    color: #0080C4;
    font-size: 1.65rem; }

.DashboardPage .DashboardList .DashboardList-wrapper .col-auto {
  float: left; }

.DashboardPage .DashboardList .DashboardList-wrapper .DashboardList-item {
  border-radius: 0.25rem;
  border: none;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  color: #6B779B;
  margin-bottom: 1.5em;
  min-height: 285px;
  text-align: left;
  width: 370px;
  border-bottom: 7px solid transparent;
  border-top: 7px solid transparent; }
  .DashboardPage .DashboardList .DashboardList-wrapper .DashboardList-item .fa, .DashboardPage .DashboardList .DashboardList-wrapper .DashboardList-item .fas, .DashboardPage .DashboardList .DashboardList-wrapper .DashboardList-item .far {
    font-size: 1em; }
  .DashboardPage .DashboardList .DashboardList-wrapper .DashboardList-item i {
    font-size: 1.45em;
    margin-bottom: 0.25em; }
  .DashboardPage .DashboardList .DashboardList-wrapper .DashboardList-item a {
    color: #6B779B; }
  .DashboardPage .DashboardList .DashboardList-wrapper .DashboardList-item:hover {
    color: #4A4A4A;
    border-top: 7px solid #00B5F3;
    border-bottom: 7px solid transparent; }
  .DashboardPage .DashboardList .DashboardList-wrapper .DashboardList-item.selected {
    color: #00B5F3; }
  .DashboardPage .DashboardList .DashboardList-wrapper .DashboardList-item .message {
    padding-top: 8px; }
  .DashboardPage .DashboardList .DashboardList-wrapper .DashboardList-item .no-data {
    margin-top: 70px;
    text-align: center; }

.DashboardPage .DashboardList .DashboardList-wrapper .systemItem {
  background-color: #c8ebf7;
  margin-bottom: 1em; }

@media only screen and (min-width: 1367px) {
  .DashboardPage .DashboardList .DashboardList-wrapper .systemItem {
    width: 768px; } }

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) {
  .DashboardPage .DashboardList .DashboardList-wrapper .row {
    display: inline; }
  .DashboardPage .DashboardList .DashboardList-wrapper .DashboardList-item {
    width: 280px; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .DashboardPage .DashboardList .DashboardList-wrapper .row {
    display: inline; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .DashboardPage .DashboardList .DashboardList-wrapper .row {
    display: inline; }
  .DashboardPage .DashboardList .DashboardList-wrapper .DashboardList-item {
    width: 320px; } }

.pagination-console .over-loading {
  font-size: 1.1rem;
  color: #00B5F3; }

.pagination-console .pagination-info {
  color: #a3a3a3;
  font-size: 0.85rem; }
  .pagination-console .pagination-info + .pagination-info {
    margin-right: 0.5rem; }
  .pagination-console .pagination-info b {
    font-weight: 600; }

.pagination-console .pagination {
  margin: 0 0 0 0.5rem; }
  .pagination-console .pagination .page-link {
    color: #707070; }
    .pagination-console .pagination .page-link:disabled {
      cursor: not-allowed;
      color: rgba(112, 112, 112, 0.35); }
      .pagination-console .pagination .page-link:disabled:hover {
        background-color: transparent; }
  .pagination-console .pagination .prev-page {
    border-right: 0; }
  .pagination-console .pagination .next-page {
    border-left: 0; }
  .pagination-console .pagination .goto-page .form-group {
    text-align: center;
    width: 50px;
    margin: 0 0.05rem; }
    .pagination-console .pagination .goto-page .form-group input {
      text-align: center;
      border-radius: inherit; }
      .pagination-console .pagination .goto-page .form-group input:focus {
        box-shadow: none; }

.modal-delete-confirmation {
  text-align: center;
  padding: 0 !important; }
  .modal-delete-confirmation:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px; }
  .modal-delete-confirmation .modal-header {
    border: none; }
    .modal-delete-confirmation .modal-header .modal-title {
      color: #6B779B; }
  .modal-delete-confirmation .modal-body {
    padding-bottom: 0.25rem;
    padding-top: 0.25rem; }
  .modal-delete-confirmation .modal-footer {
    border: none; }
  .modal-delete-confirmation .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    width: 380px; }
    .modal-delete-confirmation .modal-dialog p b {
      font-weight: 600; }
  .modal-delete-confirmation .btn-success {
    background-color: #77BF66;
    border-color: #77BF66;
    padding: 0.375rem 2.75rem; }
    .modal-delete-confirmation .btn-success:hover {
      background-color: #55a043; }

.table-loading {
  min-height: 40vh; }
  .table-loading .loader {
    color: #00B5F3;
    font-size: 1.5rem; }

.empty-data {
  text-align: center;
  margin: 5em 0 0;
  width: 100%; }
  .empty-data .image-wrapper {
    margin: 0 auto 1.5rem; }
  .empty-data .empty-data-title {
    color: #6B779B;
    font-size: 1.15rem;
    font-weight: 600; }
  .empty-data .empty-data-copy {
    color: #a3a3a3;
    font-size: 0.9rem; }
  .empty-data.type-dashboard .image-wrapper {
    width: 160px; }
    .empty-data.type-dashboard .image-wrapper img {
      margin-left: -2.5rem; }
  .empty-data.type-files .image-wrapper, .empty-data.type-notes .image-wrapper {
    width: 170px; }
  .empty-data.type-datatable {
    min-height: 25vw; }
    .empty-data.type-datatable .image-wrapper {
      width: 115px; }

.staff-page .form-wrapper {
  padding: 2rem 1rem; }
  .staff-page .form-wrapper.staff-licenses-form {
    padding-bottom: 6.5rem; }
  .staff-page .form-wrapper.staff-cms-info {
    padding-bottom: 4rem; }

.staff-page .table.staff th,
.staff-page .table.staff td {
  text-align: center; }

.staff-page .table.staff tr.inactive {
  background-color: rgba(128, 128, 128, 0.1); }

.staff-page .table.staff td {
  vertical-align: middle; }

.staff-page .table.staff .thumbnail {
  text-align: center;
  width: 2.5rem;
  padding: 0 0.25rem;
  vertical-align: middle; }

.staff-page .table.staff .first_name,
.staff-page .table.staff .last_name {
  text-align: left; }

.staff-page .table.staff .edit,
.staff-page .table.staff .delete {
  width: 3rem; }

.staff-page .form-footer {
  padding: 1.5rem 0 2rem;
  text-align: right; }

.staff-page .staff-psotions-payrates-form {
  padding-top: 0; }
  .staff-page .staff-psotions-payrates-form .form-group-label {
    margin: 0 0 1.5rem; }
  .staff-page .staff-psotions-payrates-form .card {
    margin-bottom: 2rem; }
    .staff-page .staff-psotions-payrates-form .card.disabled {
      opacity: 0.5; }
    .staff-page .staff-psotions-payrates-form .card .card-body .checkbox {
      position: absolute;
      right: 0.6rem;
      top: 0.5rem;
      height: auto; }
      .staff-page .staff-psotions-payrates-form .card .card-body .checkbox label {
        padding-left: 1rem; }
    .staff-page .staff-psotions-payrates-form .card .card-link {
      color: #00B5F3;
      padding: 0; }
      .staff-page .staff-psotions-payrates-form .card .card-link:hover {
        color: #008fc0; }
      .staff-page .staff-psotions-payrates-form .card .card-link:focus {
        outline: none;
        box-shadow: none; }
    .staff-page .staff-psotions-payrates-form .card .payrate-form-group .form-group {
      margin-bottom: 1.5rem;
      margin-top: 1.3rem; }
    .staff-page .staff-psotions-payrates-form .card .payrate-form-group .alert {
      bottom: -0.8rem; }

.staff-page .staff-availability-form .table {
  text-align: center; }
  .staff-page .staff-availability-form .table th {
    color: #6B779B;
    border-top: none;
    font-weight: 600; }
    .staff-page .staff-availability-form .table th.hour-head-icon {
      border-bottom-color: transparent; }
    .staff-page .staff-availability-form .table th.hour-head {
      cursor: pointer; }
  .staff-page .staff-availability-form .table td {
    border: none; }
    .staff-page .staff-availability-form .table td.day {
      color: #6B779B;
      font-weight: 600;
      cursor: pointer; }
  .staff-page .staff-availability-form .table tr:first-child td {
    padding-top: 0.85rem; }

.staff-page .staff-availability-form .fas {
  cursor: pointer; }

.staff-page .staff-availability-form .fa-check {
  color: #77BF66;
  font-size: 1rem; }

.staff-page .staff-availability-form .fa-circle {
  color: #aeaeae;
  font-size: 0.5rem; }

.staff-page .react-datepicker {
  font-size: 0.75rem; }

.staff-page .react-datepicker__day,
.staff-page .react-datepicker__time-name {
  line-height: 1.6; }

.staff-page .react-datepicker__current-month,
.staff-page .react-datepicker-time__header {
  line-height: 1.2;
  font-size: 1.2em; }

.staff-page .react-datepicker__time-container {
  width: 92px; }
  .staff-page .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 92px; }
    .staff-page .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
      display: flex;
      align-items: center; }

.staff-page .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 30%; }

.staff-page .react-datepicker__input-container input {
  background-size: 1.1rem;
  font-size: 0.93rem;
  text-align: center;
  padding-right: 1rem;
  padding-left: 0.28rem; }

.modal-staff-payrates-history-logs .table thead th {
  color: #6B779B;
  font-weight: 600;
  border-top: none;
  border-bottom: 2px solid rgba(107, 119, 155, 0.7); }
  .modal-staff-payrates-history-logs .table thead th tr {
    display: block; }

.modal-staff-payrates-history-logs .scroll-wrapper {
  overflow-x: auto;
  height: 400px; }

.modal-staff-paycom-sync .disclaimer {
  text-align: center;
  font-style: italic; }

.modal-staff-paycom-sync .table thead th {
  color: #6B779B;
  font-weight: 600;
  border-top: none;
  border-bottom: 2px solid rgba(107, 119, 155, 0.7); }
  .modal-staff-paycom-sync .table thead th tr {
    display: block; }

.modal-staff-paycom-sync .scroll-wrapper {
  overflow-x: auto;
  height: 150px; }

.staff-rating .google-visualization-table-table {
  font-family: 'Source Sans Pro', sans-serif; }

.staff-rating .google-visualization-table-table th {
  font-family: 'Source Sans Pro', sans-serif; }

.staff-rating .google-visualization-table-td {
  text-align: center; }

.staff-rating td.google-visualization-table-type-number, .staff-rating .google-visualization-table-type-number {
  text-align: center; }

.staff-rating .RatingSummary {
  border-radius: 0.25rem;
  border: none;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  color: #6B779B;
  margin-bottom: 1.5em;
  min-height: 285px;
  text-align: left;
  width: 100%;
  border-bottom: 7px solid transparent; }
  .staff-rating .RatingSummary .rating-chart-container {
    border: 3px solid #fff;
    padding: 20px;
    display: flex; }
  .staff-rating .RatingSummary .rating-chart {
    float: left;
    width: 25%; }

.staff-rating .table th {
  color: #6B779B;
  border-top: none;
  font-weight: 600; }

.staff-rating .table th.date, .staff-rating .table th.hours, .staff-rating .table th.rating, .staff-rating .table th.pay_rate, .staff-rating .table th.amount,
.staff-rating .table th.salary, .staff-rating .table th.tardy, .staff-rating .table th.no_show, .staff-rating .table th.short_notice, .staff-rating .table th.cancel {
  text-align: center; }

.staff-rating .table td {
  text-align: left; }

.staff-rating .table td.date, .staff-rating .table td.hours, .staff-rating .table td.rating, .staff-rating .table td.pay_rate, .staff-rating .table td.amount,
.staff-rating .table td.salary, .staff-rating .table td.tardy, .staff-rating .table td.no_show, .staff-rating .table td.short_notice, .staff-rating .table td.cancel {
  text-align: center; }

.staff-rating .table td.note {
  text-align: left; }

.staff-rating .table tr:first-child td {
  padding-top: 0.85rem; }

.staff-rating .export-container {
  text-align: right; }

.staff-rating .btn-print-options {
  color: #6B779B;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 0.95em; }
  .staff-rating .btn-print-options i {
    margin-right: 0.4em; }
  .staff-rating .btn-print-options:hover {
    color: rgba(107, 119, 155, 0.65);
    color: #00B5F3; }

.staff-notifications .table th {
  color: #6B779B;
  border-top: none;
  font-weight: 600; }

.staff-notifications .table td {
  text-align: left; }

.staff-notifications .table tr:first-child td {
  padding-top: 0.85rem; }

.staff-notifications .export-container {
  text-align: right; }

.staff-notifications .btn-print-options {
  color: #6B779B;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 0.95em; }
  .staff-notifications .btn-print-options i {
    margin-right: 0.4em; }
  .staff-notifications .btn-print-options:hover {
    color: rgba(107, 119, 155, 0.65);
    color: #00B5F3; }

.staff-notifications .circle-wrapper {
  position: relative;
  width: 10px;
  height: 20px;
  float: left; }

.staff-notifications .circle {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #cccccc; }

.staff-notifications .Notified {
  background-color: #0099c6; }

.staff-notifications .Accepted {
  background-color: #109618; }

.staff-notifications .Rejected {
  background-color: #dc3912; }

.paycom-info {
  margin-bottom: 10px;
  margin-top: -30px; }

.pay-rate-col {
  text-align: center; }

.dropzone-uploader .dropzone {
  border: 2px dashed #6B779B;
  color: #6B779B;
  padding: 1.8rem 1rem;
  text-align: center;
  cursor: pointer; }
  .dropzone-uploader .dropzone:hover {
    background-color: rgba(107, 119, 155, 0.1); }
  .dropzone-uploader .dropzone:focus {
    outline: none; }
  .dropzone-uploader .dropzone.drag-hover {
    color: #00B5F3;
    border-color: #00B5F3;
    background-color: rgba(0, 181, 243, 0.1); }
  .dropzone-uploader .dropzone.drag-hover-error {
    color: #dc3545;
    border-color: #dc3545;
    background-color: rgba(220, 53, 69, 0.1); }
  .dropzone-uploader .dropzone .fas {
    font-size: 1.5rem; }
  .dropzone-uploader .dropzone p {
    margin: 0; }

.dropzone-uploader .list-group {
  margin: 0; }
  .dropzone-uploader .list-group .list-group-item {
    margin-top: 0.85rem;
    padding: 0.5rem 0.7rem;
    font-size: 0.8rem;
    text-align: left; }
    .dropzone-uploader .list-group .list-group-item i {
      margin-right: 0.35rem;
      font-size: 1rem; }

.files-manager .loader {
  color: #00B5F3;
  font-size: 2rem;
  position: absolute;
  top: 30vh;
  left: 50%;
  width: 50px;
  text-align: center;
  margin-left: -25px; }

.files-manager .file-card {
  text-align: center;
  padding: 0;
  position: relative;
  margin: 0 0.75rem 1.5rem;
  width: 39vw;
  min-height: 170px; }
  @media (min-width: 480px) {
    .files-manager .file-card {
      width: 210px; } }
  @media (min-width: 768px) {
    .files-manager .file-card {
      width: 205px; } }
  @media (min-width: 1200px) {
    .files-manager .file-card {
      width: 195px;
      width: 12vw; } }
  @media (min-width: 1900px) {
    .files-manager .file-card {
      width: 200px;
      width: 8.8vw; } }
  .files-manager .file-card:hover {
    border-color: #00B5F3;
    background-color: rgba(0, 181, 243, 0.05); }
  .files-manager .file-card p {
    margin: 0; }
  .files-manager .file-card .fa-trash {
    color: rgba(128, 128, 128, 0.85);
    position: absolute;
    top: 0.5rem;
    right: 0.5rem; }
    .files-manager .file-card .fa-trash:hover {
      color: #dc3545;
      cursor: pointer; }
  .files-manager .file-card .file-icon {
    color: #7d7d7d;
    font-size: 3rem;
    font-weight: 100; }
  .files-manager .file-card .file-name {
    font-weight: 600; }
  .files-manager .file-card .file-description {
    font-size: 0.8rem;
    font-style: italic;
    min-height: 1.1rem; }
  .files-manager .file-card .file-info {
    cursor: pointer;
    height: 100%; }

.applicant-page .table.applicants th,
.applicant-page .table.applicants td {
  text-align: center; }

.applicant-page .table.applicants td {
  vertical-align: middle; }

.applicant-page .table.applicants .application_date,
.applicant-page .table.applicants .first_name,
.applicant-page .table.applicants .last_name {
  text-align: left;
  width: 20%; }

.applicant-page .table.applicants .edit {
  width: 3rem; }

.applicant-page .form-footer {
  padding: 1.5rem 0 2rem;
  text-align: right; }

.applicant-page .row-details {
  margin-bottom: 30px; }

.applicant-page .applicant-navigation {
  border: 1px solid rgba(74, 74, 74, 0.4);
  border-radius: 5px;
  padding-top: 20px;
  margin-bottom: 20px;
  height: 100px;
  width: 100%; }
  .applicant-page .applicant-navigation .applicant-navigation-item {
    padding: 0.8em 0 0.55em;
    display: flex; }
  .applicant-page .applicant-navigation .text-right {
    margin-right: 20px;
    margin-left: 20px; }
  .applicant-page .applicant-navigation .text-left {
    margin-right: 20px;
    margin-left: 20px; }
  .applicant-page .applicant-navigation .float-center {
    margin-left: 50px;
    text-align: center;
    width: 300px; }
  .applicant-page .applicant-navigation .float-left {
    text-align: left;
    width: auto; }
  .applicant-page .applicant-navigation .float-right {
    text-align: right;
    width: auto; }

.applicant-page .table.applicants th,
.applicant-page .table.applicants td {
  text-align: center; }

.applicant-page .table.applicants td {
  vertical-align: middle; }

.applicant-page .table.applicants .application_date {
  text-align: left;
  width: 15%; }

.applicant-page .table.applicants .first_name,
.applicant-page .table.applicants .last_name {
  text-align: left;
  width: 15%; }

.applicant-page .table.applicants .department {
  width: 10%; }

.applicant-page .table.applicants .contact_phone {
  width: 10%; }

.applicant-page .table.applicants .email {
  width: 20%; }

.applicant-page .table.applicants .status {
  width: 10%; }

.applicant-page .table.applicants .edit {
  width: 3rem; }

.applicant-page .applicant-table {
  width: 95%; }
  .applicant-page .applicant-table tr {
    text-align: center;
    border: 1px #B5BBCD solid; }
  .applicant-page .applicant-table td {
    padding: 10px; }

.volunteers-page .table.organizations th,
.volunteers-page .table.organizations td {
  text-align: center;
  vertical-align: middle; }
  .volunteers-page .table.organizations th.name,
  .volunteers-page .table.organizations td.name {
    text-align: left;
    width: 23vw; }
  .volunteers-page .table.organizations th.available_all_positions,
  .volunteers-page .table.organizations td.available_all_positions {
    width: 15vw; }
  .volunteers-page .table.organizations th.edit, .volunteers-page .table.organizations th.delete,
  .volunteers-page .table.organizations td.edit,
  .volunteers-page .table.organizations td.delete {
    width: 3rem; }

.volunteers-page .form-footer {
  padding: 1.5rem 0 2rem;
  text-align: right; }

.volunteers-page .volunteers-form {
  width: 98%; }
  .volunteers-page .volunteers-form .add-volunteer {
    background-color: #F2F2F2;
    padding: 1rem 1rem 0; }
  .volunteers-page .volunteers-form .card-title {
    color: #6B779B;
    font-size: 0.98rem;
    font-weight: 600; }
  .volunteers-page .volunteers-form .table.volunteers th,
  .volunteers-page .volunteers-form .table.volunteers td {
    text-align: center;
    vertical-align: middle; }
    .volunteers-page .volunteers-form .table.volunteers th.first_name, .volunteers-page .volunteers-form .table.volunteers th.last_name,
    .volunteers-page .volunteers-form .table.volunteers td.first_name,
    .volunteers-page .volunteers-form .table.volunteers td.last_name {
      text-align: left; }
    .volunteers-page .volunteers-form .table.volunteers th.edit, .volunteers-page .volunteers-form .table.volunteers th.delete,
    .volunteers-page .volunteers-form .table.volunteers td.edit,
    .volunteers-page .volunteers-form .table.volunteers td.delete {
      width: 3rem; }

.customers-page .table.customers .department,
.customers-page .table.customers .past_due_check,
.customers-page .table.customers .red_flag,
.customers-page .table.customers .edit,
.customers-page .table.customers .delete {
  text-align: center; }

.customers-page .table.customers tr.alert {
  background-color: rgba(220, 53, 69, 0.07); }

.customers-page .table.customers td {
  vertical-align: middle; }
  .customers-page .table.customers td i.fas.fa-flag {
    color: #ff0000; }
  .customers-page .table.customers td i.far.fa-flag {
    color: rgba(74, 74, 74, 0.5); }

.customers-page .table.customers .phone {
  min-width: 7rem; }

.customers-page .table.customers .edit,
.customers-page .table.customers .delete {
  width: 3rem; }

.customers-page .form-footer {
  padding: 1.5rem 0 2rem;
  text-align: right; }

.customers-page .personal-info-form .fas.fa-flag {
  color: #ff0000; }

.customers-page .personal-info-form .far.fa-flag {
  color: rgba(74, 74, 74, 0.7); }

.ClothesSettings .ClothesSizeCard .card-header {
  background-color: transparent;
  border-bottom: none; }
  .ClothesSettings .ClothesSizeCard .card-header .form-group {
    margin-bottom: 0; }

.ClothesSettings .ClothesSizeCard .ClothesSizeCardTitle {
  color: #6B779B;
  font-size: 0.98rem;
  font-weight: 600;
  text-transform: uppercase; }
  .ClothesSettings .ClothesSizeCard .ClothesSizeCardTitle .icon {
    display: flex;
    width: 17px;
    height: 17px;
    margin-right: 0.3rem; }

.ClothesSettings .ClothesSizeCard .fa-trash {
  cursor: pointer;
  color: rgba(128, 128, 128, 0.75);
  padding: 0.25rem 0.35rem; }
  .ClothesSettings .ClothesSizeCard .fa-trash:hover {
    color: #dc3545;
    cursor: pointer; }

.DepartmentsSettings .table tbody tr.add {
  background-color: rgba(107, 119, 155, 0.1); }

.DepartmentsSettings .table tbody tr.editing {
  background-color: rgba(0, 181, 243, 0.05); }

.DepartmentsSettings .table th, .DepartmentsSettings .table td {
  text-align: center; }
  .DepartmentsSettings .table th:first-child, .DepartmentsSettings .table td:first-child {
    text-align: left; }

.DepartmentsSettings .table th.name {
  width: 20vw; }

.DepartmentsSettings .table th.office_phone {
  width: 20vw; }

.DepartmentsSettings .table th.target_hourly_rate {
  width: 15vw; }

.DepartmentsSettings .table th.company_code {
  width: 15vw; }

.DepartmentsSettings .table th.state {
  width: 10vw; }

.DepartmentsSettings .table th.division {
  width: 10vw; }

.DepartmentsSettings .table th.city_code {
  width: 10vw; }

.DepartmentsSettings .table td {
  vertical-align: middle;
  position: relative; }
  .DepartmentsSettings .table td .form-group {
    margin: 0; }

.DepartmentsSettings .table .input-group {
  flex-wrap: inherit; }

.DepartmentsSettings .table .input-group-prepend .input-group-text {
  font-size: 0.85rem;
  line-height: 1; }

.DepartmentsSettings .btn {
  display: block; }
  .DepartmentsSettings .btn:focus {
    outline: none;
    box-shadow: none; }
  .DepartmentsSettings .btn.btn-add {
    padding-top: 0.2rem;
    padding-bottom: 0.14rem; }
    .DepartmentsSettings .btn.btn-add:disabled, .DepartmentsSettings .btn.btn-add.disabled {
      opacity: 0.4; }
  .DepartmentsSettings .btn.btn-save {
    color: #6B779B;
    margin-right: 0.2rem; }
    .DepartmentsSettings .btn.btn-save:hover {
      color: #FFFFFF;
      background-color: #6B779B; }
    .DepartmentsSettings .btn.btn-save:disabled, .DepartmentsSettings .btn.btn-save.disabled {
      color: #6B779B;
      background-color: transparent;
      opacity: 0.4; }
  .DepartmentsSettings .btn.btn-cancel {
    color: rgba(74, 74, 74, 0.35);
    font-size: 0.7rem;
    padding: 0.25rem 0.35rem; }
    .DepartmentsSettings .btn.btn-cancel:hover {
      color: #4A4A4A; }

.PositionsPayRateSettings .PositionsPayRateSettingsFilters {
  margin-bottom: 1.5rem;
  padding: 0.25rem 0; }
  .PositionsPayRateSettings .PositionsPayRateSettingsFilters .setting-label {
    color: #6B779B;
    font-size: 0.98rem;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0; }
  .PositionsPayRateSettings .PositionsPayRateSettingsFilters .form-group {
    margin: 0; }

.PositionsPayRateSettings .PositionsPayRateSettingsCard {
  margin-bottom: 2rem; }
  .PositionsPayRateSettings .PositionsPayRateSettingsCard .card-title {
    font-size: 1.1rem; }
    .PositionsPayRateSettings .PositionsPayRateSettingsCard .card-title i {
      margin-right: 0.25rem; }
  .PositionsPayRateSettings .PositionsPayRateSettingsCard .PositionsPayRateSettingsEdit .form-group {
    margin: 0.5rem 0; }
  .PositionsPayRateSettings .PositionsPayRateSettingsCard .PositionsPayRateSettingsEdit label {
    font-size: 0.9rem; }
  .PositionsPayRateSettings .PositionsPayRateSettingsCard .PositionsPayRateSettingsEdit .input-group-prepend .input-group-text {
    font-size: 0.85rem;
    line-height: 1; }
  .PositionsPayRateSettings .PositionsPayRateSettingsCard .PositionsPayRateSettingsEdit .alert {
    position: absolute;
    border: none;
    border-radius: 0;
    font-size: 0.83rem;
    padding: 0;
    bottom: -1.4rem; }
    .PositionsPayRateSettings .PositionsPayRateSettingsCard .PositionsPayRateSettingsEdit .alert.alert-danger {
      color: #dc3545;
      background-color: transparent; }
  .PositionsPayRateSettings .PositionsPayRateSettingsCard .PositionsPayRateSettingsEdit .read-only p {
    line-height: 2rem;
    font-size: 1.3rem;
    font-weight: 300;
    margin-bottom: 0; }

.StaffPayRateSettings .table tbody tr.add {
  background-color: rgba(107, 119, 155, 0.1); }

.StaffPayRateSettings .table tbody tr.editing {
  background-color: rgba(0, 181, 243, 0.05); }

.StaffPayRateSettings .table th, .StaffPayRateSettings .table td {
  text-align: center; }
  .StaffPayRateSettings .table th:first-child, .StaffPayRateSettings .table td:first-child {
    text-align: left; }

.StaffPayRateSettings .table th.last_name {
  width: 20vw; }

.StaffPayRateSettings .table th.sort_date {
  width: 10vw; }

.StaffPayRateSettings .table th.pay_rates {
  width: 70vw; }

.StaffPayRateSettings .table td {
  vertical-align: middle;
  position: relative; }
  .StaffPayRateSettings .table td .form-group {
    margin: 0; }

.StaffPayRateSettings .table .input-group {
  flex-wrap: inherit; }

.StaffPayRateSettings .table .input-group-prepend .input-group-text {
  font-size: 0.85rem;
  line-height: 1; }

.StaffPayRateSettings .StaffPayRateSettingsFilters {
  margin-bottom: 1.5rem;
  padding: 0.25rem 0; }
  .StaffPayRateSettings .StaffPayRateSettingsFilters .setting-label {
    color: #6B779B;
    font-size: 0.98rem;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0; }
  .StaffPayRateSettings .StaffPayRateSettingsFilters .form-group {
    margin: 0; }

.StaffPayRateSettings .StaffPayRateSettingsCard {
  margin: 0.5rem;
  padding: 0.5rem;
  float: left; }
  .StaffPayRateSettings .StaffPayRateSettingsCard .card-title {
    color: #6B779B;
    font-size: 0.98rem;
    font-weight: 600; }
  .StaffPayRateSettings .StaffPayRateSettingsCard .card-subtitle {
    font-size: 0.75rem;
    margin-top: -0.375rem;
    margin-bottom: 0;
    color: #8B909E; }
  .StaffPayRateSettings .StaffPayRateSettingsCard .ratesContainer {
    margin: 1px; }
  .StaffPayRateSettings .StaffPayRateSettingsCard .travelPayRateColumn {
    float: right;
    max-width: 130px;
    margin: 5px; }
  .StaffPayRateSettings .StaffPayRateSettingsCard .regularPayRateColumn {
    float: left;
    max-width: 130px;
    margin: 5px; }
  .StaffPayRateSettings .StaffPayRateSettingsCard .StaffPayRateSettingsEdit .form-group {
    margin: 0.5rem 0; }
  .StaffPayRateSettings .StaffPayRateSettingsCard .StaffPayRateSettingsEdit label {
    font-size: 0.9rem; }
  .StaffPayRateSettings .StaffPayRateSettingsCard .StaffPayRateSettingsEdit .input-group-prepend .input-group-text {
    font-size: 0.85rem;
    line-height: 1; }
  .StaffPayRateSettings .StaffPayRateSettingsCard .StaffPayRateSettingsEdit .alert {
    position: absolute;
    border: none;
    border-radius: 0;
    font-size: 0.83rem;
    padding: 0;
    bottom: -1.4rem; }
    .StaffPayRateSettings .StaffPayRateSettingsCard .StaffPayRateSettingsEdit .alert.alert-danger {
      color: #dc3545;
      background-color: transparent; }
  .StaffPayRateSettings .StaffPayRateSettingsCard .StaffPayRateSettingsEdit .read-only p {
    line-height: 2rem;
    font-size: 1.3rem;
    font-weight: 300;
    margin-bottom: 0; }

.RolesResourcesSettings .table th {
  text-align: center;
  color: #6B779B;
  border-top: none;
  font-weight: 600; }
  .RolesResourcesSettings .table th.left-heading {
    text-align: left; }

.RolesResourcesSettings .table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6; }
  .RolesResourcesSettings .table td.access {
    text-align: center;
    color: #6B779B;
    font-weight: 600;
    cursor: pointer; }

.StatesSettings .StatesSettingsList .access {
  font-weight: 600;
  cursor: pointer; }

.StatesSettings .StatesSettingsCard {
  margin-bottom: 1rem;
  height: 60px; }
  .StatesSettings .StatesSettingsCard .card-title {
    float: left;
    width: 93%; }
  .StatesSettings .StatesSettingsCard .card-access {
    float: left;
    width: 3%; }

.StatesSettings .active {
  background-color: #e9ecef; }

.MessagesSettings .form-wrapper {
  padding: 2rem 1rem; }

.MessagesSettings .table.messages tbody tr.add {
  background-color: rgba(107, 119, 155, 0.1); }

.MessagesSettings .table.messages tbody tr.editing {
  background-color: rgba(0, 181, 243, 0.05); }

.MessagesSettings .table.messages th,
.MessagesSettings .table.messages td {
  text-align: center; }

.MessagesSettings .table.messages tr.inactive {
  background-color: rgba(128, 128, 128, 0.1); }

.MessagesSettings .table.messages td {
  vertical-align: top; }

.MessagesSettings .table.messages .title {
  text-align: left;
  width: 15%; }

.MessagesSettings .table.messages .content {
  text-align: left;
  width: 50%; }

.MessagesSettings .table.messages .background_color, .MessagesSettings .table.messages .text_color {
  width: 8%; }

.MessagesSettings .table.messages .start_date, .MessagesSettings .table.messages .end_date {
  width: 10%; }

.MessagesSettings .table.messages .edit,
.MessagesSettings .table.messages .delete {
  width: 3rem; }

.preview-container {
  position: absolute;
  top: 0;
  left: 130px;
  float: right;
  border-color: transparent;
  width: 20px; }

.fa-eye {
  color: rgba(0, 181, 243, 0.7);
  cursor: pointer;
  height: 2.5rem;
  width: 2.5rem;
  font-size: 1rem; }
  .fa-eye:hover {
    color: #00B5F3; }

.SettingsPage .page-body {
  padding-bottom: 1.5rem; }

.SettingsPage .content-wrapper {
  padding-top: 0.5rem; }

.PayrollHistory .PayrollHistoryTable th.created_date,
.PayrollHistory .PayrollHistoryTable td.created_date {
  width: 13.5%; }

.PayrollHistory .PayrollHistoryTable th.department,
.PayrollHistory .PayrollHistoryTable td.department {
  text-align: center;
  width: 15%; }

.PayrollHistory .PayrollHistoryTable th.user,
.PayrollHistory .PayrollHistoryTable td.user {
  width: 16%; }

.PayrollHistory .PayrollHistoryTable th.reports_files a,
.PayrollHistory .PayrollHistoryTable td.reports_files a {
  color: #6B779B;
  padding: 0 1.5rem 0 0; }
  .PayrollHistory .PayrollHistoryTable th.reports_files a:hover,
  .PayrollHistory .PayrollHistoryTable td.reports_files a:hover {
    color: #00B5F3;
    text-decoration: none; }

.ActivityHistory .ActivityHistoryTable th.created_date,
.ActivityHistory .ActivityHistoryTable td.created_date {
  width: 10%; }

.ActivityHistory .ActivityHistoryTable th.user,
.ActivityHistory .ActivityHistoryTable td.user {
  width: 15%; }

.ActivityHistory .ActivityHistoryTable th.section,
.ActivityHistory .ActivityHistoryTable td.section {
  width: 10%; }

.ActivityHistory .ActivityHistoryTable th.subsection,
.ActivityHistory .ActivityHistoryTable td.subsection {
  width: 15%; }

.ActivityHistory .ActivityHistoryTable th.activity,
.ActivityHistory .ActivityHistoryTable td.activity {
  width: 50%; }

.ActivityHistory .table-console-left {
  padding: 0 0 1rem; }

.ErrorHistory .ErrorHistoryTable th.created_date,
.ErrorHistory .ErrorHistoryTable td.created_date {
  width: 13.5%; }

.ErrorHistory .ErrorHistoryTable th.user,
.ErrorHistory .ErrorHistoryTable td.user {
  width: 12%; }

.ErrorHistory .btn-delete-logs {
  float: right;
  color: #6B779B;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 0.95em; }
  .ErrorHistory .btn-delete-logs i {
    margin-right: 0.4em; }
  .ErrorHistory .btn-delete-logs:hover {
    color: rgba(107, 119, 155, 0.65);
    color: #00B5F3; }

.PaycomHistory .PaycomHistoryTable th.start_date,
.PaycomHistory .PaycomHistoryTable td.start_date {
  width: 13.5%; }

.PaycomHistory .PaycomHistoryTable th.end_date,
.PaycomHistory .PaycomHistoryTable td.end_date {
  width: 13.5%; }

.PaycomHistory .PaycomHistoryTable th.user,
.PaycomHistory .PaycomHistoryTable td.user {
  width: 13.5%; }

.DepartmentRate .DepartmentRateTable th.created_date,
.DepartmentRate .DepartmentRateTable td.created_date {
  width: 25%; }

.DepartmentRate .DepartmentRateTable th.user,
.DepartmentRate .DepartmentRateTable td.user {
  width: 25%; }

.DepartmentRate .DepartmentRateTable th.department,
.DepartmentRate .DepartmentRateTable td.department {
  width: 25%; }

.DepartmentRate .DepartmentRateTable th.hourly_rate,
.DepartmentRate .DepartmentRateTable td.hourly_rate {
  width: 25%; }

.DepartmentRate .table-console-left {
  padding: 0 0 1rem; }

.LogsPage .page-body {
  padding-bottom: 1.5rem; }

.LogsPage .content-wrapper {
  padding-top: 0.5rem; }

.venues-page .table.venues th,
.venues-page .table.venues td {
  text-align: center;
  vertical-align: middle; }
  .venues-page .table.venues th.name,
  .venues-page .table.venues td.name {
    text-align: left;
    width: 23vw; }
  .venues-page .table.venues th.edit, .venues-page .table.venues th.delete,
  .venues-page .table.venues td.edit,
  .venues-page .table.venues td.delete {
    width: 3rem; }

.venues-page .form-footer {
  padding: 1.5rem 0 2rem;
  text-align: right; }

.venues-page .venue-configuration-form .configurations-list-card .add-configuration {
  background-color: rgba(107, 119, 155, 0.15);
  padding: 1rem 1rem 0; }

.venues-page .venue-configuration-form .configurations-list-card .card-title {
  color: #6B779B;
  font-size: 0.98rem;
  font-weight: 600; }

.venues-page .venue-configuration-form .configurations-list-card .form-group {
  margin-bottom: 1.5rem; }

.venues-page .venue-configuration-form .configurations-list-card .list-group .list-group-item {
  padding: 0;
  cursor: pointer; }
  .venues-page .venue-configuration-form .configurations-list-card .list-group .list-group-item.active {
    color: #00B5F3;
    cursor: default;
    background-color: transparent;
    border-color: #00B5F3;
    box-shadow: inset 0px 0px 3px #00B5F3; }
  .venues-page .venue-configuration-form .configurations-list-card .list-group .list-group-item .item-info {
    padding: 0.75rem 0.35rem 0.75rem 0.8rem;
    width: 100%; }
  .venues-page .venue-configuration-form .configurations-list-card .list-group .list-group-item .configuration-title {
    width: 50%; }
  .venues-page .venue-configuration-form .configurations-list-card .list-group .list-group-item .configuration-badges {
    text-align: right;
    width: 50%; }
  .venues-page .venue-configuration-form .configurations-list-card .list-group .list-group-item .badge {
    font-weight: 400;
    font-size: 0.7rem;
    line-height: 1.3;
    margin-right: 0.35rem; }
  .venues-page .venue-configuration-form .configurations-list-card .list-group .list-group-item .list-group-item-console {
    padding-right: 0.35rem; }

.venues-page .venue-configuration-form .configurations-list-card .fa-trash {
  cursor: pointer;
  color: rgba(128, 128, 128, 0.5);
  padding: 0.25rem 0.35rem; }
  .venues-page .venue-configuration-form .configurations-list-card .fa-trash:hover {
    color: #dc3545;
    cursor: pointer; }

.venues-page .venue-configuration-form .configuration-position-list {
  min-height: 40vh; }
  .venues-page .venue-configuration-form .configuration-position-list .table-console {
    padding: 0.25rem 0.25rem 1rem; }

.venues-page .venue-configuration-form .table.venues-configuration th,
.venues-page .venue-configuration-form .table.venues-configuration td {
  text-align: center;
  vertical-align: middle; }
  .venues-page .venue-configuration-form .table.venues-configuration th.assignment_name,
  .venues-page .venue-configuration-form .table.venues-configuration td.assignment_name {
    text-align: left; }

.venues-page .venue-configuration-form .table.venues-configuration td.assignment_name {
  width: 12vw; }

.venues-page .venue-configuration-form .table.venues-configuration td.edit, .venues-page .venue-configuration-form .table.venues-configuration td.delete {
  width: 3rem; }

.modal-configuration-position-form .form-group-label {
  margin-top: 0; }

.modal-configuration-position-form .checkbox {
  min-height: 3rem; }

.modal-configuration-position-form .input-radio-qty .form-group {
  margin: 0; }

.modal-configuration-position-form .modal-footer {
  border: none;
  padding-top: 0; }

.notes-manager .loader {
  color: #00B5F3;
  font-size: 2rem;
  position: absolute;
  top: 30vh;
  left: 50%;
  width: 50px;
  text-align: center;
  margin-left: -25px; }

.notes-manager .note-form {
  min-height: 388px; }
  .notes-manager .note-form .form-group:first-child {
    margin-bottom: 1rem; }
  .notes-manager .note-form .btn-cancel {
    margin-right: 0.85rem; }

.notes-manager .note-view {
  background-color: #c0efff;
  position: relative;
  padding: 1.8rem 1.5rem;
  min-height: 333px; }
  .notes-manager .note-view::before {
    content: '';
    background-color: #FFFFFF;
    height: 2.3rem;
    width: 2.3rem;
    display: block;
    right: 0;
    top: 0;
    position: absolute;
    box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.07);
    background: #c0efff;
    background: linear-gradient(45deg, #c0efff 0%, #c0efff 50%, #FFFFFF 50%); }
  .notes-manager .note-view .title {
    font-size: 1.6rem; }
  .notes-manager .note-view .data {
    font-size: 1.25rem;
    font-weight: 300; }
  .notes-manager .note-view .date {
    font-size: 0.8rem;
    font-weight: 300;
    margin: 0 0 0.25rem; }
  .notes-manager .note-view .note-console {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0; }
    .notes-manager .note-view .note-console .author-info {
      padding-left: 1rem;
      font-weight: 300;
      font-size: 0.8rem;
      font-style: italic; }
      .notes-manager .note-view .note-console .author-info b {
        font-weight: 400; }
  .notes-manager .note-view .fa-pencil {
    color: rgba(74, 74, 74, 0.7);
    cursor: pointer;
    height: 2.5rem;
    width: 2.5rem;
    font-size: 1rem; }
    .notes-manager .note-view .fa-pencil:hover {
      color: #4A4A4A; }

.notes-manager .note-view-console {
  padding: 0.5rem 0; }

.notes-manager .btn-create-note {
  color: rgba(74, 74, 74, 0.7); }
  .notes-manager .btn-create-note i {
    margin-right: 0.25rem; }
  .notes-manager .btn-create-note:hover {
    color: #4A4A4A; }

.notes-manager .notes-card {
  text-align: center;
  padding: 0;
  position: relative;
  margin: 0 0.75rem 1.5rem;
  width: 39vw;
  min-height: 170px; }
  @media (min-width: 480px) {
    .notes-manager .notes-card {
      width: 210px; } }
  @media (min-width: 768px) {
    .notes-manager .notes-card {
      width: 205px; } }
  @media (min-width: 1200px) {
    .notes-manager .notes-card {
      width: 195px;
      width: 12vw; } }
  @media (min-width: 1900px) {
    .notes-manager .notes-card {
      width: 200px;
      width: 8.8vw; } }
  .notes-manager .notes-card:hover, .notes-manager .notes-card.active {
    border-color: #00B5F3;
    background-color: rgba(0, 181, 243, 0.05); }
  .notes-manager .notes-card p {
    margin: 0; }
  .notes-manager .notes-card .fa-trash {
    color: rgba(128, 128, 128, 0.85);
    position: absolute;
    top: 0.5rem;
    right: 0.5rem; }
    .notes-manager .notes-card .fa-trash:hover {
      color: #dc3545;
      cursor: pointer; }
  .notes-manager .notes-card .file-icon {
    color: #7d7d7d;
    font-size: 3rem;
    font-weight: 100;
    line-height: 1.3; }
  .notes-manager .notes-card .file-name {
    font-weight: 600; }
  .notes-manager .notes-card .file-description {
    font-size: 0.8rem;
    font-style: italic;
    min-height: 1.1rem; }
  .notes-manager .notes-card .file-date {
    font-size: 0.75rem;
    font-weight: 300;
    margin: 0.2rem 0; }
  .notes-manager .notes-card .file-info {
    cursor: pointer;
    height: 100%; }

.modal-clone-confirmation {
  text-align: center;
  padding: 0 !important; }
  .modal-clone-confirmation:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px; }
  .modal-clone-confirmation .modal-header {
    border: none; }
    .modal-clone-confirmation .modal-header .modal-title {
      color: #6B779B; }
  .modal-clone-confirmation .modal-body {
    padding-bottom: 0.25rem;
    padding-top: 0.25rem; }
  .modal-clone-confirmation .modal-footer {
    border: none; }
  .modal-clone-confirmation .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    width: 500px; }
    .modal-clone-confirmation .modal-dialog p b {
      font-weight: 600; }
  .modal-clone-confirmation .btn-success {
    background-color: #77BF66;
    border-color: #77BF66;
    padding: 0.375rem 2.75rem; }
    .modal-clone-confirmation .btn-success:hover {
      background-color: #55a043; }
  .modal-clone-confirmation .date-list {
    padding: 10px;
    width: 500px; }

.table.events th,
.table.events td {
  text-align: center; }

.table.events td {
  vertical-align: middle; }

.table.events .name {
  text-align: left;
  width: 20%; }

.table.events .customer,
.table.events .venue {
  text-align: left;
  width: 12%; }

.table.events .department,
.table.events .event_number,
.table.events .start_date,
.table.events .status {
  text-align: left;
  width: 10%; }

.table.events .number_days,
.table.events .staff_totals {
  text-align: center;
  width: 5%; }

.table.events .clone,
.table.events .edit,
.table.events .delete {
  width: 3%; }

.btn-add {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0.15em 0 0;
  width: 31px;
  height: 31px; }

.events-dates-form {
  margin: .25em 0 0; }
  .events-dates-form .form-group {
    margin: 0;
    width: 46%; }
  .events-dates-form .btn-add {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.15em 0 0;
    width: 35px;
    height: 35px; }

.filter-by-status {
  vertical-align: top;
  min-width: 200px;
  min-height: 50px; }

.filter-by-section {
  vertical-align: top;
  min-width: 200px;
  min-height: 50px;
  margin-left: 32px; }

.ReportsPage .ReportsPageHeader .btn-back {
  color: rgba(107, 119, 155, 0.5);
  padding: 0.5em 0.25em 0.5em 0;
  margin-right: 0.3em;
  line-height: 1; }
  .ReportsPage .ReportsPageHeader .btn-back:hover {
    color: #0080C4; }

.ReportsPage .ReportsList .ReportsList-header {
  text-align: center;
  padding: 2.5rem 0 1rem; }
  .ReportsPage .ReportsList .ReportsList-header h2 {
    color: #0080C4;
    font-size: 1.65rem; }

.ReportsPage .ReportsList .ReportsList-wrapper {
  margin: 0 auto;
  width: 960px; }
  .ReportsPage .ReportsList .ReportsList-wrapper .ReportsList-item {
    background-color: #F2F2F2;
    border-radius: 0.25rem;
    border: none;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    color: #6B779B;
    text-align: center;
    font-size: 1.15rem;
    margin-bottom: 1.5em;
    min-height: 145px;
    width: 210px;
    border-bottom: 7px solid transparent;
    border-top: 7px solid transparent; }
    .ReportsPage .ReportsList .ReportsList-wrapper .ReportsList-item i {
      font-size: 1.45em;
      margin-bottom: 0.25em; }
    .ReportsPage .ReportsList .ReportsList-wrapper .ReportsList-item:hover {
      color: #00B5F3;
      border-top: 7px solid #00B5F3;
      border-bottom: 7px solid transparent; }
    .ReportsPage .ReportsList .ReportsList-wrapper .ReportsList-item.selected {
      color: #00B5F3; }

@media (max-width: 768px) {
  .ReportsPage .ReportsList .ReportsList-wrapper {
    margin: 0 auto;
    width: 460px; } }

.ReportsPage .ReportToolFilters {
  padding: 0.8em 0;
  min-height: 82px; }
  .ReportsPage .ReportToolFilters .filters .filter-item {
    width: 140px;
    margin-right: 2em; }
    .ReportsPage .ReportToolFilters .filters .filter-item label {
      margin: 0;
      color: rgba(74, 74, 74, 0.5); }
    .ReportsPage .ReportToolFilters .filters .filter-item .react-datepicker-wrapper {
      margin-top: 0.55rem; }
    .ReportsPage .ReportToolFilters .filters .filter-item .form-group {
      margin: 0; }
  .ReportsPage .ReportToolFilters .btn-generate-report {
    width: 110px; }

.ReportsPage .ReportToolFiltersConfirmation {
  margin: auto;
  text-align: center;
  width: 90%; }
  .ReportsPage .ReportToolFiltersConfirmation .btn {
    margin-right: 1em;
    margin-bottom: 0.5em; }
    .ReportsPage .ReportToolFiltersConfirmation .btn.btn-close {
      display: none; }
  .ReportsPage .ReportToolFiltersConfirmation .ReportToolFiltersConfirmation-message {
    font-size: 1.15rem;
    margin-bottom: 20px; }
  .ReportsPage .ReportToolFiltersConfirmation .ReportToolFiltersConfirmation-message-confirmation {
    font-size: 1.15rem;
    margin-top: 20px; }
  .ReportsPage .ReportToolFiltersConfirmation .ReportToolFiltersConfirmation-badge-wrapper {
    border: 1px #a5a4a4 solid;
    margin: 1em 0 0.25em;
    padding-bottom: 1em;
    padding-top: 1em;
    padding-left: 1em; }
    .ReportsPage .ReportToolFiltersConfirmation .ReportToolFiltersConfirmation-badge-wrapper .badge {
      margin-right: 0.8em;
      margin-bottom: 0.8em;
      font-size: 0.9em;
      font-weight: 400;
      line-height: 1.2;
      padding: 0.3em 0.7em; }
    .ReportsPage .ReportToolFiltersConfirmation .ReportToolFiltersConfirmation-badge-wrapper .header {
      font-size: 1.1em;
      font-weight: bold; }
    .ReportsPage .ReportToolFiltersConfirmation .ReportToolFiltersConfirmation-badge-wrapper .name {
      text-align: left; }
    .ReportsPage .ReportToolFiltersConfirmation .ReportToolFiltersConfirmation-badge-wrapper .button {
      text-align: right; }
  .ReportsPage .ReportToolFiltersConfirmation .ReportToolPreviewConfirmation-badge-wrapper {
    border: 1px #a5a4a4 solid;
    margin: 1em 0 0.25em;
    padding-bottom: 1em;
    padding-top: 1em; }
    .ReportsPage .ReportToolFiltersConfirmation .ReportToolPreviewConfirmation-badge-wrapper .badge {
      margin-right: 0.8em;
      margin-bottom: 0.8em;
      font-size: 0.9em;
      font-weight: 400;
      line-height: 1.2;
      padding: 0.3em 0.7em; }
    .ReportsPage .ReportToolFiltersConfirmation .ReportToolPreviewConfirmation-badge-wrapper .header {
      font-size: 1.1em;
      font-weight: bold; }
    .ReportsPage .ReportToolFiltersConfirmation .ReportToolPreviewConfirmation-badge-wrapper .name {
      text-align: left; }

.ReportsPage .ReportResults {
  border: 1px solid #6B779B;
  border-radius: 5px;
  margin-top: 1.5em;
  padding-bottom: 3em;
  overflow: hidden; }
  .ReportsPage .ReportResults .ReportResults-header {
    background-color: #F2F2F2;
    text-align: right; }
    .ReportsPage .ReportResults .ReportResults-header .btn {
      margin: 0 0.5rem 0 0; }
      .ReportsPage .ReportResults .ReportResults-header .btn i {
        margin-right: 0.25rem; }
    .ReportsPage .ReportResults .ReportResults-header .exportfiles-wrapper {
      position: relative;
      display: inline-block; }
      .ReportsPage .ReportResults .ReportResults-header .exportfiles-wrapper .dropdown {
        position: absolute;
        background-color: #F2F2F2;
        list-style: none;
        margin: 0;
        padding: 0 1em;
        z-index: 10;
        min-width: 100%;
        right: 0.5em; }
        .ReportsPage .ReportResults .ReportResults-header .exportfiles-wrapper .dropdown li {
          cursor: pointer;
          margin: 0.6em 0; }
          .ReportsPage .ReportResults .ReportResults-header .exportfiles-wrapper .dropdown li:hover {
            color: #00B5F3; }
  .ReportsPage .ReportResults .ReportResults-body .ReportResults-info {
    margin: 1.5rem 0; }
    .ReportsPage .ReportResults .ReportResults-body .ReportResults-info p {
      margin: 0 0 0.5rem; }
    .ReportsPage .ReportResults .ReportResults-body .ReportResults-info .cms-logo {
      width: 110px; }
  .ReportsPage .ReportResults table.report th, .ReportsPage .ReportResults table.report td {
    text-align: right; }
  .ReportsPage .ReportResults table.report td:first-child,
  .ReportsPage .ReportResults table.report th:first-child {
    text-align: left; }
    .ReportsPage .ReportResults table.report td:first-child + td, .ReportsPage .ReportResults table.report td:first-child + th,
    .ReportsPage .ReportResults table.report th:first-child + td,
    .ReportsPage .ReportResults table.report th:first-child + th {
      text-align: left; }
  .ReportsPage .ReportResults table.report th {
    border-top: none;
    color: #6B779B;
    font-weight: 600; }
  .ReportsPage .ReportResults table.report tr.total {
    font-weight: 600; }
  .ReportsPage .ReportResults table.report tr.region {
    font-weight: 600; }
    .ReportsPage .ReportResults table.report tr.region td {
      border-top: 1px solid rgba(107, 119, 155, 0.7); }
  .ReportsPage .ReportResults table.report.with-total tr:last-child {
    font-weight: 700; }

@media print {
  .ReportResults-body {
    background-color: #FFFFFF;
    height: 100%;
    padding: 50px 80px; }
    .ReportResults-body .ReportResults-info {
      margin-bottom: 30px; }
      .ReportResults-body .ReportResults-info p {
        margin: 0 0 7px; }
    .ReportResults-body table {
      text-align: right; }
      .ReportResults-body table th {
        border-top: none;
        color: rgba(74, 74, 74, 0.5);
        color: #b0b0b0;
        font-weight: 600; }
      .ReportResults-body table td:first-child,
      .ReportResults-body table th:first-child {
        text-align: left; }
        .ReportResults-body table td:first-child + td, .ReportResults-body table td:first-child + th,
        .ReportResults-body table th:first-child + td,
        .ReportResults-body table th:first-child + th {
          text-align: left; }
      .ReportResults-body table.with-total tr:last-child {
        font-weight: 700; } }

.table.users th,
.table.users td {
  text-align: center; }

.table.users td {
  vertical-align: middle; }

.table.users .first_name, .table.users .last_name {
  text-align: left;
  width: 20%; }

.table.users .edit,
.table.users .delete {
  width: 3rem; }

.btn-add {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0.15em 0 0;
  width: 31px;
  height: 31px; }

.modal-user-change-password .modal-dialog {
  width: 400px; }

.EventStatus .EventStatus-label {
  color: #919191;
  font-size: 0.85em;
  margin: 0;
  text-align: right;
  text-transform: uppercase; }

.EventStatus .EventStatus-info {
  text-align: right;
  margin-top: 0em; }

.EventStatus .EventStatus-value {
  color: #6B779B;
  margin: 0 0 0 0.2em;
  font-size: 1.05em;
  line-height: 1.5; }
  .EventStatus .EventStatus-value.pre-event {
    color: #ffbc00; }
  .EventStatus .EventStatus-value.initiated {
    color: #77BF66; }
  .EventStatus .EventStatus-value.finalized {
    color: #00B5F3; }
  .EventStatus .EventStatus-value.reconciled {
    color: #7c1bc7; }
  .EventStatus .EventStatus-value.closed {
    color: #a5a5a5; }

.EventStatus .EventStatus-icon {
  color: #a3a3a3;
  color: #FFFFFF;
  background-color: #6B779B;
  border-radius: 3px;
  width: 34px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1em;
  margin-left: 0.5em; }
  .EventStatus .EventStatus-icon.pre-event {
    background-color: #ffbc00; }
  .EventStatus .EventStatus-icon.initiated {
    background-color: #77BF66; }
  .EventStatus .EventStatus-icon.finalized {
    background-color: #00B5F3; }
  .EventStatus .EventStatus-icon.reconciled {
    background-color: #7c1bc7; }
  .EventStatus .EventStatus-icon.closed {
    background-color: #a5a5a5; }

.EventStatus .EventStatusCountdown {
  color: #919191;
  font-size: 0.78em; }
  .EventStatus .EventStatusCountdown .EventStatusCountdown-number {
    background-color: #FFFFFF;
    border: 1px solid #dee2e6;
    border-radius: 3px;
    padding: 0.25em 0.3em;
    text-align: center;
    display: inline-block;
    margin: 0 0.25em;
    line-height: 10px; }
  .EventStatus .EventStatusCountdown .EventStatusCountdown-label {
    color: #00B5F3; }

.EventInfoDates .EventInfoDates-label {
  color: #6B779B;
  font-size: 1.25rem;
  margin: 1rem 0; }

.EventInfoDates .EventInfoDates-list .options {
  width: 12%;
  text-align: left; }
  .EventInfoDates .EventInfoDates-list .options .btn-add {
    margin-left: 10px; }

.EventInfoDates .EventInfoDates-list .form-group {
  margin: 0 auto; }

.EventInfoDates .EventInfoDates-list label {
  display: none; }

.EventInfoDates .EventInfoDates-list .day {
  text-align: left;
  width: 6%; }

.EventInfoDates .EventInfoDates-list .date {
  width: 5%; }

.EventInfoDates .EventInfoDates-list .positions {
  margin: 0 auto; }

.EventInfoDates .EventInfoDates-list .numbers {
  float: left;
  width: 30%; }

.EventInfoDates .EventInfoDates-list .clean {
  float: left;
  width: 10%; }

.EventInfoDates .EventInfoDates-list th {
  text-align: center; }

.EventInfoDates .EventInfoDates-list td {
  text-align: center; }

.EventInfoDates .EventInfoDates-list .event-date-item .event-date-info {
  min-width: 55%; }
  .EventInfoDates .EventInfoDates-list .event-date-item .event-date-info h6 {
    color: #00B5F3;
    margin: 0 0 0.25em;
    width: 17%; }
  .EventInfoDates .EventInfoDates-list .event-date-item .event-date-info p {
    margin: 0;
    width: 45%; }

.EventInfoDates .EventInfoDates-list .event-date-item .fa-trash {
  cursor: pointer;
  color: rgba(128, 128, 128, 0.75);
  padding: 0.25rem 0.3rem;
  margin-left: 15px; }
  .EventInfoDates .EventInfoDates-list .event-date-item .fa-trash:hover {
    color: #dc3545;
    cursor: pointer; }

.EventInfoDates .EventInfoDates-list .event-date-item .fa-eraser {
  cursor: pointer;
  color: rgba(128, 128, 128, 0.75);
  margin-left: 5px; }
  .EventInfoDates .EventInfoDates-list .event-date-item .fa-eraser:hover {
    color: #dc3545;
    cursor: pointer; }

.EventInfoDates .EventInfoDates-range-alert {
  padding-top: 0;
  text-align: center;
  color: #dc3545;
  font-size: 0.88em; }

.EventInfoDates .EventInfoDates-add .btn.btn-datepicker {
  min-width: 155px;
  font-size: 0.8rem; }

.EventStaffPositions {
  width: 100%;
  margin: 0.4rem 0; }
  .EventStaffPositions .label {
    color: #6B779B;
    font-size: 1.2em;
    text-transform: uppercase;
    margin: 0; }
    .EventStaffPositions .label i {
      font-size: 0.9em; }
  .EventStaffPositions .position-title {
    line-height: 10px;
    padding: 10px 0;
    float: left;
    margin-top: -3px; }
  .EventStaffPositions .add-position-button {
    padding-left: 5px;
    display: flex; }
  .EventStaffPositions .positions-select {
    clear: both; }
  .EventStaffPositions .btn-add-position {
    color: #00B5F3;
    background-color: transparent;
    border-color: transparent; }
    .EventStaffPositions .btn-add-position:hover {
      color: #FFFFFF;
      background-color: #00B5F3; }
  .EventStaffPositions .select-position .css-yk16xz-control,
  .EventStaffPositions .select-position .css-1pahdxg-control {
    box-shadow: none; }
  .EventStaffPositions .select-position > div {
    border: none; }
    .EventStaffPositions .select-position > div .css-62g3xt-dummyInput {
      position: absolute; }
  .EventStaffPositions .select-position .css-tlfecz-indicatorContainer {
    color: #00B5F3; }
  .EventStaffPositions .select-position .css-1okebmr-indicatorSeparator {
    display: none; }
  .EventStaffPositions .select-position .css-1hwfws3 {
    padding-right: 0; }
  .EventStaffPositions .select-position .position-option .position-option-info .name {
    font-size: 1.15em; }
  .EventStaffPositions .select-position .position-option .position-option-info .position {
    font-size: 0.85em;
    color: #808080; }
  .EventStaffPositions .select-position .position-option .position-option-info .counter {
    text-align: left; }
  .EventStaffPositions .position-option-info {
    width: 100%; }
    .EventStaffPositions .position-option-info .name {
      font-size: 1.25em;
      font-weight: 600; }
    .EventStaffPositions .position-option-info i {
      color: #6B779B; }
    .EventStaffPositions .position-option-info .counter {
      width: 73px;
      text-align: right; }
    .EventStaffPositions .position-option-info .count {
      color: #6B779B;
      padding: 0 0 0 0.25em; }
      .EventStaffPositions .position-option-info .count::after {
        content: '|';
        padding: 0 0.25em; }
    .EventStaffPositions .position-option-info .total {
      color: rgba(128, 128, 128, 0.6); }
  .EventStaffPositions .position-option.css-9gakcf-option {
    background-color: #00B5F3; }
    .EventStaffPositions .position-option.css-9gakcf-option .position-option-info .count,
    .EventStaffPositions .position-option.css-9gakcf-option .position-option-info i {
      color: #FFFFFF; }
    .EventStaffPositions .position-option.css-9gakcf-option .position-option-info .total {
      color: rgba(255, 255, 255, 0.5); }
  .EventStaffPositions .position-option.css-1n7v3ny-option {
    background-color: rgba(0, 181, 243, 0.25); }
  .EventStaffPositions .position-option.isFull {
    background-color: rgba(119, 191, 102, 0.25); }
    .EventStaffPositions .position-option.isFull.css-9gakcf-option {
      background-color: #00B5F3; }
    .EventStaffPositions .position-option.isFull.css-1n7v3ny-option {
      background-color: rgba(0, 181, 243, 0.25);
      background-color: rgba(119, 191, 102, 0.6); }

.EventStaffAvailable {
  background-color: #F2F2F2;
  border-radius: 5px;
  padding: 1em; }
  .EventStaffAvailable .EventStaffAvailable-header {
    margin-bottom: 0.25em; }
    .EventStaffAvailable .EventStaffAvailable-header .title {
      font-size: 1.1em;
      font-weight: 400;
      width: 62%;
      margin: 0; }
    .EventStaffAvailable .EventStaffAvailable-header .select-department {
      width: 38%; }
    .EventStaffAvailable .EventStaffAvailable-header .select-department .css-yk16xz-control,
    .EventStaffAvailable .EventStaffAvailable-header .select-department .css-1pahdxg-control {
      border: none;
      background-color: transparent;
      box-shadow: none; }
      .EventStaffAvailable .EventStaffAvailable-header .select-department .css-yk16xz-control .css-1uccc91-singleValue,
      .EventStaffAvailable .EventStaffAvailable-header .select-department .css-1pahdxg-control .css-1uccc91-singleValue {
        color: #6B779B;
        font-size: 0.95em; }
      .EventStaffAvailable .EventStaffAvailable-header .select-department .css-yk16xz-control .css-1okebmr-indicatorSeparator,
      .EventStaffAvailable .EventStaffAvailable-header .select-department .css-1pahdxg-control .css-1okebmr-indicatorSeparator {
        display: none; }
      .EventStaffAvailable .EventStaffAvailable-header .select-department .css-yk16xz-control .css-tlfecz-indicatorContainer,
      .EventStaffAvailable .EventStaffAvailable-header .select-department .css-1pahdxg-control .css-tlfecz-indicatorContainer {
        color: #00B5F3; }
      .EventStaffAvailable .EventStaffAvailable-header .select-department .css-yk16xz-control .css-tj5bde-Svg,
      .EventStaffAvailable .EventStaffAvailable-header .select-department .css-1pahdxg-control .css-tj5bde-Svg {
        height: 16px;
        width: 16px; }
  .EventStaffAvailable .EventStaffAvailable-filters {
    margin-bottom: 0.65em;
    font-size: 1.1em; }
    .EventStaffAvailable .EventStaffAvailable-filters .EventStaffAvailable-search .EventStaffAvailable-filter {
      width: 30%; }
    .EventStaffAvailable .EventStaffAvailable-filters .EventStaffAvailable-search .EventStaffAvailable-filter-wide {
      width: 53%; }
    .EventStaffAvailable .EventStaffAvailable-filters .EventStaffAvailable-search .EventStaffAvailable-type {
      margin-left: 6px;
      width: 32%; }
    .EventStaffAvailable .EventStaffAvailable-filters .EventStaffAvailable-search .EventStaffAvailable-status {
      margin-left: 6px;
      width: 26%; }
    .EventStaffAvailable .EventStaffAvailable-filters .EventStaffAvailable-search .EventStaffAvailable-add {
      padding-top: 3px;
      margin-left: 6px;
      width: 5%; }
  .EventStaffAvailable .EventStaffAvailable-list {
    background-color: #F9FAFA; }
    .EventStaffAvailable .EventStaffAvailable-list .alert-share-resource {
      background-color: rgba(107, 119, 155, 0.25);
      color: #6B779B;
      font-size: 0.8em;
      padding: 0.45em 0.65em; }
      .EventStaffAvailable .EventStaffAvailable-list .alert-share-resource p {
        margin: 0; }
      .EventStaffAvailable .EventStaffAvailable-list .alert-share-resource b {
        font-weight: 600; }
      .EventStaffAvailable .EventStaffAvailable-list .alert-share-resource i {
        font-size: 0.895em;
        margin-right: 0.2em; }
    .EventStaffAvailable .EventStaffAvailable-list .EventStaffAvailable-item {
      padding: 0.3em 0.25em;
      border: 1px solid transparent;
      border-bottom: 1px solid #c6cbd9;
      cursor: pointer; }
      .EventStaffAvailable .EventStaffAvailable-list .EventStaffAvailable-item.isStaff {
        cursor: default; }
        .EventStaffAvailable .EventStaffAvailable-list .EventStaffAvailable-item.isStaff .EventStaffAvailable-item-info .avatar,
        .EventStaffAvailable .EventStaffAvailable-list .EventStaffAvailable-item.isStaff .EventStaffAvailable-item-info .name {
          opacity: 0.6; }
        .EventStaffAvailable .EventStaffAvailable-list .EventStaffAvailable-item.isStaff .EventStaffAvailable-item-info .staff-member-info-overlay .name {
          opacity: 1; }
        .EventStaffAvailable .EventStaffAvailable-list .EventStaffAvailable-item.isStaff .staff-badges {
          opacity: 0.6; }
        .EventStaffAvailable .EventStaffAvailable-list .EventStaffAvailable-item.isStaff .organization-wrapper {
          opacity: 0.6; }
      .EventStaffAvailable .EventStaffAvailable-list .EventStaffAvailable-item.isSelected {
        border: 1px solid rgba(0, 128, 196, 0.55);
        box-shadow: inset 0px 0px 3px #00B5F3; }
      .EventStaffAvailable .EventStaffAvailable-list .EventStaffAvailable-item.notSelected:hover {
        border: 1px solid rgba(0, 128, 196, 0.55);
        box-shadow: inset 0px 0px 3px #00B5F3; }
      .EventStaffAvailable .EventStaffAvailable-list .EventStaffAvailable-item .EventStaffAvailable-item-info {
        width: 40%;
        position: relative; }
        .EventStaffAvailable .EventStaffAvailable-list .EventStaffAvailable-item .EventStaffAvailable-item-info .avatar {
          width: 21px;
          margin-right: 0.35em; }
        .EventStaffAvailable .EventStaffAvailable-list .EventStaffAvailable-item .EventStaffAvailable-item-info .name {
          font-size: 0.85em;
          margin: 0;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 90%;
          white-space: nowrap;
          padding-left: 0.35em; }
        .EventStaffAvailable .EventStaffAvailable-list .EventStaffAvailable-item .EventStaffAvailable-item-info:hover .staff-member-info-overlay {
          display: block; }
      .EventStaffAvailable .EventStaffAvailable-list .EventStaffAvailable-item .staff-badges {
        width: 55%;
        text-align: right; }
        .EventStaffAvailable .EventStaffAvailable-list .EventStaffAvailable-item .staff-badges .selected {
          margin: 0 0.35em;
          font-size: 0.75em;
          font-weight: 600; }
          .EventStaffAvailable .EventStaffAvailable-list .EventStaffAvailable-item .staff-badges .selected.fa-check {
            color: #77BF66; }
          .EventStaffAvailable .EventStaffAvailable-list .EventStaffAvailable-item .staff-badges .selected.fa-uncheck {
            width: 10px; }
      .EventStaffAvailable .EventStaffAvailable-list .EventStaffAvailable-item .organization-wrapper {
        width: 55%;
        text-align: center;
        margin: 0 0.35em;
        font-size: 0.9em;
        font-weight: 600; }
      .EventStaffAvailable .EventStaffAvailable-list .EventStaffAvailable-item .notification-status-general {
        padding-left: 10px;
        font-size: 0.85em;
        font-weight: 400; }
      .EventStaffAvailable .EventStaffAvailable-list .EventStaffAvailable-item .notification-status {
        width: 75px;
        font-size: 0.9em;
        font-weight: 400;
        float: left;
        text-align: left; }
      .EventStaffAvailable .EventStaffAvailable-list .EventStaffAvailable-item .circle-wrapper {
        position: relative;
        width: 10px;
        height: 20px;
        float: left; }
      .EventStaffAvailable .EventStaffAvailable-list .EventStaffAvailable-item .circle {
        margin: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #cccccc; }
      .EventStaffAvailable .EventStaffAvailable-list .EventStaffAvailable-item .Notified {
        background-color: #0099c6; }
      .EventStaffAvailable .EventStaffAvailable-list .EventStaffAvailable-item .Accepted {
        background-color: #109618; }
      .EventStaffAvailable .EventStaffAvailable-list .EventStaffAvailable-item .Rejected {
        background-color: #dc3912; }
      .EventStaffAvailable .EventStaffAvailable-list .EventStaffAvailable-item .notification-type {
        width: 55px;
        font-size: 0.75em;
        font-weight: 400;
        float: left; }
    .EventStaffAvailable .EventStaffAvailable-list .badge-notification-ban {
      color: #dc3912; }
    .EventStaffAvailable .EventStaffAvailable-list .badge-notification-check {
      color: #109618; }
    .EventStaffAvailable .EventStaffAvailable-list .staff-member-info-overlay {
      left: 0;
      width: 260px;
      font-size: 0.8em; }
      .EventStaffAvailable .EventStaffAvailable-list .staff-member-info-overlay:after, .EventStaffAvailable .EventStaffAvailable-list .staff-member-info-overlay:before {
        left: 7.65%; }
    .EventStaffAvailable .EventStaffAvailable-list .notice {
      color: #4A4A4A; }
  .EventStaffAvailable .EventStaffAvailable-footer {
    padding: 1em 0 0; }
    .EventStaffAvailable .EventStaffAvailable-footer p {
      margin: 0; }
    .EventStaffAvailable .EventStaffAvailable-footer .btn {
      text-transform: uppercase; }

.EventsStaffStats {
  border: 1px solid rgba(74, 74, 74, 0.4);
  border-radius: 5px;
  width: 100%; }
  .EventsStaffStats .shared-resources {
    margin-left: 10px; }
  .EventsStaffStats .volunteers {
    margin-left: 10px; }
    .EventsStaffStats .volunteers.badge-volunteer {
      color: #77BF66; }
  .EventsStaffStats .fa-spin {
    color: #6B779B;
    font-size: 0.8rem; }
  .EventsStaffStats .btn-shared-resources {
    color: #f1634b;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 0.95em;
    height: 30px;
    margin-top: -10px;
    padding: 0.375rem; }
    .EventsStaffStats .btn-shared-resources i {
      margin-right: 0.4em;
      transform: rotate(-45deg); }
    .EventsStaffStats .btn-shared-resources:hover {
      color: rgba(107, 119, 155, 0.65);
      color: #00B5F3; }
  .EventsStaffStats .btn-volunteers {
    color: #77BF66;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 0.95em;
    height: 30px;
    margin-top: -10px;
    padding: 0.375rem; }
    .EventsStaffStats .btn-volunteers i {
      margin-right: 0.4em; }
    .EventsStaffStats .btn-volunteers:hover {
      color: rgba(107, 119, 155, 0.65);
      color: #00B5F3; }
  .EventsStaffStats .EventsStaffStats-item {
    padding: 0.8em 0 0.55em;
    text-align: center; }
    .EventsStaffStats .EventsStaffStats-item .EventsStaffStats-item-label {
      color: #a3a3a3;
      font-size: 0.95em;
      margin: 0; }
    .EventsStaffStats .EventsStaffStats-item .EventsStaffStats-item-info {
      color: #6B779B;
      font-size: 1.25em;
      font-weight: 600;
      margin: 0; }
    .EventsStaffStats .EventsStaffStats-item.current-hourly-rate .EventsStaffStats-item-info {
      color: #dc3545; }
      .EventsStaffStats .EventsStaffStats-item.current-hourly-rate .EventsStaffStats-item-info .fa-check {
        font-size: 0.75em; }
    .EventsStaffStats .EventsStaffStats-item.current-hourly-rate.on-target .EventsStaffStats-item-info {
      color: #77BF66; }

.modal-shared .modal-dialog {
  width: 1060px; }

.modal-shared .modal-body .col {
  border: 1px solid #F2F2F2;
  margin: 10px;
  float: left;
  padding: 10px;
  width: 320px; }
  .modal-shared .modal-body .col h6 {
    text-align: center; }

.EventDatesNav {
  font-size: 1.15em;
  margin: 0.5rem 0; }
  .EventDatesNav .btn {
    padding: 0.375rem 0.5rem; }
    .EventDatesNav .btn:hover {
      color: #6B779B; }
    .EventDatesNav .btn:disabled, .EventDatesNav .btn.disabled {
      opacity: 0.25; }
      .EventDatesNav .btn:disabled:hover, .EventDatesNav .btn.disabled:hover {
        color: inherit; }
    .EventDatesNav .btn.btn-double-angle {
      padding: 0.375rem 0.25rem; }
  .EventDatesNav .EventDatesNav-label .day-counter {
    color: #6B779B;
    text-transform: uppercase; }
  .EventDatesNav .EventDates-select {
    width: 250px;
    text-align: center;
    text-align-last: center;
    -moz-text-align-last: center; }

.EventReconciliation .EventReconciliation-header {
  margin-bottom: 0.5em; }
  .EventReconciliation .EventReconciliation-header .EventReconciliation-label {
    color: #6B779B;
    text-transform: uppercase;
    font-size: 1.2em;
    font-weight: 400;
    margin: 0.5rem 0; }

.EventReconciliation .EventInfoSummary {
  border-bottom: none; }
  .EventReconciliation .EventInfoSummary .name {
    color: #0080C4;
    font-size: 1.5em;
    margin: 0 0 0.1em;
    display: block; }
  .EventReconciliation .EventInfoSummary .info {
    font-size: 1em;
    font-weight: 400;
    margin: 0;
    display: block; }

.EventReconciliation table.reconciliation {
  font-size: 0.9em; }
  .EventReconciliation table.reconciliation td, .EventReconciliation table.reconciliation th {
    text-align: center; }
  .EventReconciliation table.reconciliation td {
    vertical-align: middle;
    position: relative; }
  .EventReconciliation table.reconciliation tr.reconciled.exported .btn.checkbox .fa-square {
    color: rgba(74, 74, 74, 0.6); }
  .EventReconciliation table.reconciliation tr.reconciled.exported .btn.checkbox .fa-check-square {
    color: rgba(74, 74, 74, 0.8); }
  .EventReconciliation table.reconciliation tr.reconciled.exported td.reconciled .fa-check-square {
    color: rgba(74, 74, 74, 0.6); }
  .EventReconciliation table.reconciliation tr.reconciled td {
    background-color: #F2F2F2; }
  .EventReconciliation table.reconciliation p {
    margin: 0; }
  .EventReconciliation table.reconciliation .form-group {
    margin: 0; }
  .EventReconciliation table.reconciliation .staff_name {
    text-align: left; }
    .EventReconciliation table.reconciliation .staff_name .staff-info .avatar {
      width: 20px;
      margin-right: 0.5em; }
  .EventReconciliation table.reconciliation .date-wrapper {
    width: 160px;
    margin: 0 auto; }
    .EventReconciliation table.reconciliation .date-wrapper i {
      color: rgba(74, 74, 74, 0.6);
      cursor: pointer;
      position: absolute;
      padding: 0.35em;
      right: -22px;
      top: 50%;
      font-size: 0.85em;
      margin-top: -10px; }
      .EventReconciliation table.reconciliation .date-wrapper i:hover {
        color: #00B5F3; }
    .EventReconciliation table.reconciliation .date-wrapper .read-only {
      position: relative; }
      .EventReconciliation table.reconciliation .date-wrapper .read-only p:first-child {
        margin-bottom: 0.25em; }
      .EventReconciliation table.reconciliation .date-wrapper .read-only i {
        right: -15px; }
    .EventReconciliation table.reconciliation .date-wrapper .editable {
      position: relative; }
      .EventReconciliation table.reconciliation .date-wrapper .editable .form-group:first-child {
        margin-bottom: 0.5em; }
      .EventReconciliation table.reconciliation .date-wrapper .editable .fa-save {
        color: #00B5F3;
        top: 32%; }
        .EventReconciliation table.reconciliation .date-wrapper .editable .fa-save:hover {
          color: #77BF66; }
        .EventReconciliation table.reconciliation .date-wrapper .editable .fa-save.disabled {
          opacity: 0.7;
          color: rgba(74, 74, 74, 0.6);
          cursor: default; }
  .EventReconciliation table.reconciliation .tardy {
    width: 80px; }
  .EventReconciliation table.reconciliation th.note {
    text-align: center; }
  .EventReconciliation table.reconciliation .note {
    width: 150px;
    text-align: left; }
    .EventReconciliation table.reconciliation .note p {
      font-size: 0.9em; }
  .EventReconciliation table.reconciliation td.pay_rate {
    min-width: 90px; }
    .EventReconciliation table.reconciliation td.pay_rate .payrate-wrapper i {
      color: rgba(74, 74, 74, 0.6);
      cursor: pointer;
      font-size: 0.85em;
      width: 15px;
      line-height: 16px;
      z-index: 1;
      margin-left: 0.5em;
      position: absolute; }
      .EventReconciliation table.reconciliation td.pay_rate .payrate-wrapper i:hover {
        color: #00B5F3; }

.EventReconciliation .OptionsContainer {
  width: 100%;
  text-align: right;
  padding-bottom: 5px; }

.EventReconciliation .btn-overlap {
  padding: 0; }

.EventReconciliation .badge {
  color: #FFFFFF;
  background-color: #6B779B;
  border: 1px solid #6B779B;
  border-radius: 0.35em;
  font-size: 0.6rem;
  padding: 0.4em 0.5em 0.2em;
  text-align: center;
  font-weight: 400;
  display: block;
  margin: 0 0.3em;
  min-width: 22px;
  display: inline-block;
  line-height: 1.2; }
  .EventReconciliation .badge.badge-overlap {
    min-width: 33px;
    color: #F2F2F2;
    background-color: #ff8341;
    border-color: transparent; }
    .EventReconciliation .badge.badge-overlap i.fa-calendar-alt {
      font-size: 1.1em;
      font-weight: 800; }
      .EventReconciliation .badge.badge-overlap i.fa-calendar-alt span {
        font-weight: 800;
        font-family: "Source Sans Pro", sans-serif; }

.event-datepicker {
  font-size: 0.95em;
  line-height: 0.95em;
  width: 335px; }

.EventReconciliationToolBulkEndTime-modal .modal-dialog {
  width: 360px; }

.EventReconciliationToolBulkEndTime-modal .form-group {
  margin: 0.35em 0 0.5em; }
  .EventReconciliationToolBulkEndTime-modal .form-group .event-datepicker {
    font-size: 0.95em;
    line-height: 0.95em;
    width: 335px; }
    .EventReconciliationToolBulkEndTime-modal .form-group .event-datepicker .react-datepicker__day,
    .EventReconciliationToolBulkEndTime-modal .form-group .event-datepicker .react-datepicker__time-name {
      line-height: 1.6; }
    .EventReconciliationToolBulkEndTime-modal .form-group .event-datepicker .react-datepicker__current-month,
    .EventReconciliationToolBulkEndTime-modal .form-group .event-datepicker .react-datepicker-time__header {
      line-height: 1.2;
      font-size: 1.25em; }
    .EventReconciliationToolBulkEndTime-modal .form-group .event-datepicker .react-datepicker__time-container {
      width: 92px; }
      .EventReconciliationToolBulkEndTime-modal .form-group .event-datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
        width: 92px; }
        .EventReconciliationToolBulkEndTime-modal .form-group .event-datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
          display: flex;
          align-items: center; }
    .EventReconciliationToolBulkEndTime-modal .form-group .event-datepicker .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
      right: 30%; }
    .EventReconciliationToolBulkEndTime-modal .form-group .event-datepicker .react-datepicker__input-time-container
.react-datepicker-time__input-container
.react-datepicker-time__input input {
      width: 94px; }
    .EventReconciliationToolBulkEndTime-modal .form-group .event-datepicker .react-datepicker__input-container input {
      background-size: 0.9em;
      font-size: 0.93em;
      text-align: center;
      padding-right: 1rem;
      padding-left: 0.28rem; }

.EventStaffAssigned .EventStaffAssigned-toolsbar {
  margin-bottom: 1em; }
  .EventStaffAssigned .EventStaffAssigned-toolsbar .title {
    margin: 0;
    width: 185px; }
  .EventStaffAssigned .EventStaffAssigned-toolsbar .EventStaffAssigned-toolsbar-items {
    background-color: #F2F2F2;
    border-radius: 3px;
    margin-left: 0.8em;
    width: 90%; }
    .EventStaffAssigned .EventStaffAssigned-toolsbar .EventStaffAssigned-toolsbar-items .btn-toolsbar-item {
      color: #6B779B;
      font-size: 0.8em;
      text-transform: uppercase;
      margin: 0 0.25em; }
      .EventStaffAssigned .EventStaffAssigned-toolsbar .EventStaffAssigned-toolsbar-items .btn-toolsbar-item:hover {
        color: #00B5F3; }
      .EventStaffAssigned .EventStaffAssigned-toolsbar .EventStaffAssigned-toolsbar-items .btn-toolsbar-item:disabled:hover, .EventStaffAssigned .EventStaffAssigned-toolsbar .EventStaffAssigned-toolsbar-items .btn-toolsbar-item.disabled:hover {
        color: #6B779B; }

.EventStaffAssigned .EventStaffAssigned-footer .addPositions .title {
  color: #6B779B;
  margin: 0 1.2em 0 0; }

.EventStaffAssigned .EventStaffAssigned-footer .EventStaffAssigned-footer-toolsbar {
  width: 87.5%; }

.EventStaffAssigned .btn-print-options {
  color: #6B779B;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 0.95em; }
  .EventStaffAssigned .btn-print-options i {
    margin-right: 0.4em; }
  .EventStaffAssigned .btn-print-options:hover {
    color: rgba(107, 119, 155, 0.65);
    color: #00B5F3; }

.EventStaffAssigned .btn-overtime {
  padding: 0; }

.EventStaffAssigned .btn-overlap {
  padding: 0; }

table.eventStaffAssignedList {
  font-size: 0.85em; }
  table.eventStaffAssignedList tr {
    border-bottom: 1px solid #808080;
    border-bottom: 1px solid #dee2e6; }
    table.eventStaffAssignedList tr.row-selected {
      border: 1px solid rgba(0, 128, 196, 0.55);
      box-shadow: inset 0px 0px 7px #00B5F3; }
      table.eventStaffAssignedList tr.row-selected td {
        border-top: 1px solid rgba(0, 128, 196, 0.55); }
    table.eventStaffAssignedList tr.empty td {
      opacity: 0.4; }
    table.eventStaffAssignedList tr.empty .rowSelected {
      opacity: 1; }
  table.eventStaffAssignedList th,
  table.eventStaffAssignedList td {
    position: relative;
    text-align: center;
    vertical-align: middle;
    padding: 0.25rem 0.25rem; }
  table.eventStaffAssignedList td {
    border: none; }
  table.eventStaffAssignedList .form-group {
    display: inline-block;
    margin: 0 0 0 0.5em; }
  table.eventStaffAssignedList .checkbox .fa-square {
    color: rgba(74, 74, 74, 0.6); }
  table.eventStaffAssignedList .checkbox .fa-check-square {
    color: #00B5F3; }
  table.eventStaffAssignedList td.rowSelected {
    width: 20px;
    padding: 0.4rem 0rem 0.4rem 0.45rem; }
    table.eventStaffAssignedList td.rowSelected .checkbox {
      padding: 0; }
  table.eventStaffAssignedList td.employee_num {
    width: 65px;
    padding: 0.4rem 0.5rem; }
  table.eventStaffAssignedList td.staff_name {
    text-align: left;
    min-width: 180px; }
  table.eventStaffAssignedList .staff-member-info {
    position: relative;
    cursor: default; }
    table.eventStaffAssignedList .staff-member-info .avatar {
      width: 18px;
      height: 18px;
      margin-right: 0.5em; }
    table.eventStaffAssignedList .staff-member-info .staff-name {
      margin: 0;
      line-height: 24px; }
    table.eventStaffAssignedList .staff-member-info:hover .staff-member-info-overlay {
      display: block; }
  table.eventStaffAssignedList td.rating {
    width: 70px;
    padding: 0.4rem 0.25rem; }
  table.eventStaffAssignedList .date-wrapper {
    width: 310px;
    margin: 0 auto; }
    table.eventStaffAssignedList .date-wrapper .btn-datepicker {
      min-width: 145px; }
    table.eventStaffAssignedList .date-wrapper .form-group {
      margin: 0 0.3rem; }
    table.eventStaffAssignedList .date-wrapper i {
      color: rgba(74, 74, 74, 0.6);
      cursor: pointer;
      font-size: 0.85em;
      width: 15px;
      line-height: 16px;
      margin-left: 0.5em;
      position: absolute; }
      table.eventStaffAssignedList .date-wrapper i:hover {
        color: #00B5F3; }
      table.eventStaffAssignedList .date-wrapper i.fa-times {
        top: 30%; }
  table.eventStaffAssignedList td.pay_rate {
    min-width: 90px; }
    table.eventStaffAssignedList td.pay_rate .payrate-wrapper i {
      color: rgba(74, 74, 74, 0.6);
      cursor: pointer;
      font-size: 0.85em;
      width: 15px;
      line-height: 16px;
      z-index: 1;
      margin-left: 0.5em;
      position: absolute; }
      table.eventStaffAssignedList td.pay_rate .payrate-wrapper i:hover {
        color: #00B5F3; }
  table.eventStaffAssignedList td.group {
    width: 90px; }
    table.eventStaffAssignedList td.group .css-1hwfws3 {
      justify-content: center;
      padding-right: 0;
      padding-left: 0; }
    table.eventStaffAssignedList td.group .css-tlfecz-indicatorContainer,
    table.eventStaffAssignedList td.group .css-1gtu0rj-indicatorContainer {
      padding: 4px; }
  table.eventStaffAssignedList td.volunteer {
    width: 70px; }
  table.eventStaffAssignedList td.salary {
    width: 60px; }

.stations-center {
  padding-bottom: 10px;
  margin: 0 auto;
  width: 190px; }
  .stations-center label {
    margin-bottom: 0 !important;
    padding-right: 15px; }

.testing-mode-container {
  width: 450px;
  padding: 8px 16px 1px 16px;
  margin-bottom: 20px;
  background-color: #808080;
  color: #FFFFFF; }

.EventStaff.form-wrapper {
  padding-top: 0.5rem; }

.EventStaff .EventStaff-toolsbar {
  margin: 1.4em 0; }

.EventStaff .btn-clone-position {
  color: #6B779B;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 0.95em; }
  .EventStaff .btn-clone-position i {
    margin-right: 0.4em; }
  .EventStaff .btn-clone-position:hover {
    color: rgba(107, 119, 155, 0.65);
    color: #00B5F3; }

.EventStaff .badge {
  color: #FFFFFF;
  background-color: #6B779B;
  border: 1px solid #6B779B;
  border-radius: 0.35em;
  font-size: 0.6rem;
  padding: 0.4em 0.5em 0.2em;
  text-align: center;
  font-weight: 400;
  display: block;
  margin: 0 0.3em;
  min-width: 22px;
  display: inline-block;
  line-height: 1.2; }
  .EventStaff .badge.badge-outline {
    color: #6B779B;
    background-color: transparent;
    font-weight: 600; }
  .EventStaff .badge.badge-outline-blank {
    border: none;
    background-color: transparent;
    font-weight: 600; }
  .EventStaff .badge.badge-rating {
    min-width: 33px; }
    .EventStaff .badge.badge-rating .fa-star {
      font-size: 0.8em; }
  .EventStaff .badge.badge-placeholder {
    min-width: 33px; }
  .EventStaff .badge.badge-payrate {
    min-width: 40px; }
  .EventStaff .badge.badge-overtime {
    min-width: 33px; }
    .EventStaff .badge.badge-overtime i.fa-clock {
      font-size: 1.1em;
      font-weight: 800; }
      .EventStaff .badge.badge-overtime i.fa-clock span {
        font-weight: 800;
        font-family: "Source Sans Pro", sans-serif; }
  .EventStaff .badge.badge-overlap {
    min-width: 33px; }
    .EventStaff .badge.badge-overlap i.fa-calendar-alt {
      font-size: 1.1em;
      font-weight: 800; }
      .EventStaff .badge.badge-overlap i.fa-calendar-alt span {
        font-weight: 800;
        font-family: "Source Sans Pro", sans-serif; }
  .EventStaff .badge.badge-travel {
    border: none;
    background-color: transparent;
    color: #6B779B;
    font-size: 1em;
    padding: 0.4em 0em 0.2em; }
    .EventStaff .badge.badge-travel i.fa-plane {
      transform: rotate(-45deg); }
  .EventStaff .badge.badge-volunteer {
    border: none;
    background-color: transparent;
    color: #77BF66;
    font-size: 1em;
    padding: 0.4em 0em 0.2em; }

.EventStaff .travel-staff .badge {
  border-color: #f1634b;
  background-color: #f1634b; }
  .EventStaff .travel-staff .badge.badge.badge-outline {
    color: #f1634b;
    background-color: transparent; }
  .EventStaff .travel-staff .badge.badge-travel {
    color: #f1634b;
    background-color: transparent; }
  .EventStaff .travel-staff .badge.badge-volunteer {
    color: #77BF66;
    background-color: transparent; }

.EventStaff .overtime-staff .badge {
  border-color: #dc3545;
  background-color: #dc3545; }
  .EventStaff .overtime-staff .badge.badge.badge-outline {
    color: #dc3545;
    background-color: transparent; }
  .EventStaff .overtime-staff .badge.badge-overtime {
    color: #F2F2F2;
    background-color: #dc3545;
    border-color: transparent; }
  .EventStaff .overtime-staff .badge.badge-overlap {
    color: #F2F2F2;
    background-color: #ff8341;
    border-color: transparent; }

.EventStaff .staff-member-info-overlay {
  background-color: #e6e6e6;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  color: #4f4f4f;
  color: #4A4A4A;
  position: absolute;
  left: -12px;
  top: 135%;
  width: 280px;
  z-index: 10;
  border-radius: 5px;
  padding: 1em;
  display: none; }
  .EventStaff .staff-member-info-overlay:after, .EventStaff .staff-member-info-overlay:before {
    bottom: 100%;
    left: 3.65%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none; }
  .EventStaff .staff-member-info-overlay:after {
    border-bottom-color: #e6e6e6;
    border-width: 10px; }
  .EventStaff .staff-member-info-overlay:before {
    border-width: 10px; }
  .EventStaff .staff-member-info-overlay .staff-member-info-overlay-name {
    font-size: 1.1em; }
  .EventStaff .staff-member-info-overlay p {
    margin: 0.5em 0 0; }
  .EventStaff .staff-member-info-overlay .travel {
    color: #f1634b;
    font-size: 1.1em;
    font-weight: 400; }
    .EventStaff .staff-member-info-overlay .travel i.fa-plane {
      transform: rotate(-45deg);
      font-size: 0.88em; }
  .EventStaff .staff-member-info-overlay .staff-members-badges {
    padding: 1em 0 0; }
  .EventStaff .staff-member-info-overlay .badge {
    font-size: 0.85em;
    line-height: 1.4;
    padding: 0.3em 0.5em 0.3em; }

.EventStaff .css-yk16xz-control,
.EventStaff .css-1pahdxg-control,
.EventStaff .css-1fhf3k1-control {
  min-height: 32px; }

.EventStaff .css-26l3qy-menu {
  margin-top: 0; }

.modal-dialog .modal-header .modal-title i {
  font-size: 0.85em; }

.modal-dialog .disclaimer {
  margin: 0.8em 0.5em 0;
  font-style: italic;
  font-size: 1em; }

.modal-dialog .select-style-default {
  border-bottom: 1px solid #dee2e6; }

.modal-dialog .modal-footer {
  border-top: none; }

.modal-payrate .modal-dialog {
  width: 340px; }

.modal-payrate .form-group {
  margin: 1em 0 1.2em; }
  .modal-payrate .form-group.currency-input .alert {
    bottom: -12px; }

.modal-payrate .label .additional-rate-event {
  color: #dc3545; }

.modal-clone .modal-dialog {
  width: 350px; }

.modal-clone .modal-body .select-clone-date {
  border-bottom: 1px solid #dee2e6; }

.modal-clone .modal-body .disclaimer {
  margin: 0.8em 0.5em 0;
  font-style: italic;
  font-size: 1em; }

.modal-clone .modal-body .warning {
  margin: 1em 0.5em 0;
  font-size: 1.2em;
  color: #dc3545;
  text-align: center; }

.modal-print .btn {
  min-width: 130px; }

.modal-bulk-time label {
  color: #6B779B;
  text-transform: uppercase;
  margin: 0;
  font-weight: 600; }

.modal-bulk-time .select-style-default {
  border-bottom: 1px solid #dee2e6; }

.modal-bulk-time .form-group {
  margin-bottom: 0; }

.modal-bulk-group .modal-dialog {
  width: 380px; }

table.modal-table {
  font-size: 0.85em;
  width: 100%; }
  table.modal-table tr {
    border-bottom: 1px solid #808080; }
  table.modal-table th {
    font-weight: bold;
    position: relative;
    text-align: center;
    vertical-align: middle;
    padding: 0.25rem 0.25rem; }
  table.modal-table td {
    position: relative;
    text-align: center;
    vertical-align: middle;
    padding: 0.25rem 0.25rem; }

.modal-checkin {
  text-align: center;
  padding: 0 !important; }
  .modal-checkin:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px; }
  .modal-checkin .modal-header {
    border: none; }
    .modal-checkin .modal-header .modal-title {
      color: #6B779B; }
  .modal-checkin .modal-body {
    padding-bottom: 1.25rem;
    padding-top: 1.25rem;
    text-align: center; }
    .modal-checkin .modal-body h1 {
      color: #6B779B;
      font-size: 2.3rem;
      font-weight: 600; }
    .modal-checkin .modal-body h2 {
      font-size: 1.3rem;
      font-weight: 600; }
    .modal-checkin .modal-body .equipment {
      border: 1px #8B909E solid;
      margin-top: 20px; }
  .modal-checkin .modal-footer {
    border: none; }
  .modal-checkin .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    width: 380px; }
    .modal-checkin .modal-dialog p b {
      font-weight: 600; }
  .modal-checkin .btn-success {
    background-color: #77BF66;
    border-color: #77BF66;
    padding: 0.375rem 2.75rem;
    width: 100%; }
    .modal-checkin .btn-success:hover {
      background-color: #55a043; }
  .modal-checkin .btn-terciary {
    width: 100%; }

.EventCheckIn {
  min-height: 72vh;
  padding-bottom: 13rem; }
  .EventCheckIn .EventCheckIn-header {
    margin-bottom: 0.5em; }
  .EventCheckIn .mt-3, .EventCheckIn .my-3 {
    margin-top: 0 !important;
    font-size: 0.82em; }
  .EventCheckIn .filter-by-shift {
    min-width: 200px; }
  .EventCheckIn .OptionsContainer {
    width: 100%;
    text-align: right; }
  .EventCheckIn .SearchBox {
    margin-left: 32px;
    width: 200px; }
  .EventCheckIn .form-control {
    height: calc(1.5em + 0.75rem + 5px); }
  .EventCheckIn table.CheckInList th.staff_name, .EventCheckIn table.CheckInList th.position_name, .EventCheckIn table.CheckInList td.staff_name, .EventCheckIn table.CheckInList td.position_name {
    width: 15%; }
  .EventCheckIn table.CheckInList th.volunteer, .EventCheckIn table.CheckInList td.volunteer {
    text-align: center;
    width: 5%; }
  .EventCheckIn table.CheckInList th.start_date, .EventCheckIn table.CheckInList th.end_date, .EventCheckIn table.CheckInList td.start_date, .EventCheckIn table.CheckInList td.end_date {
    text-align: center;
    width: 15%; }
  .EventCheckIn table.CheckInList th.shirt_number, .EventCheckIn table.CheckInList th.jacket_number, .EventCheckIn table.CheckInList th.radio_number, .EventCheckIn table.CheckInList th.flagger_number, .EventCheckIn table.CheckInList td.shirt_number, .EventCheckIn table.CheckInList td.jacket_number, .EventCheckIn table.CheckInList td.radio_number, .EventCheckIn table.CheckInList td.flagger_number {
    text-align: center; }
  .EventCheckIn table.CheckInList th.check_in, .EventCheckIn table.CheckInList th.check_out, .EventCheckIn table.CheckInList td.check_in, .EventCheckIn table.CheckInList td.check_out {
    text-align: center;
    width: 9%; }
  .EventCheckIn .btn-print-options {
    color: #6B779B;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 0.95em; }
    .EventCheckIn .btn-print-options i {
      margin-right: 0.4em; }
    .EventCheckIn .btn-print-options:hover {
      color: rgba(107, 119, 155, 0.65);
      color: #00B5F3; }

.EventsCheckInSummary {
  border: 1px solid rgba(74, 74, 74, 0.4);
  border-radius: 5px;
  width: 300px; }
  .EventsCheckInSummary .fa-spin {
    color: #6B779B;
    font-size: 0.8rem; }
  .EventsCheckInSummary .EventsCheckInSummary-item {
    padding: 0.8em 0 0.55em;
    text-align: center; }
    .EventsCheckInSummary .EventsCheckInSummary-item .EventsCheckInSummary-item-label {
      color: #a3a3a3;
      font-size: 0.95em;
      margin: 0; }
    .EventsCheckInSummary .EventsCheckInSummary-item .EventsCheckInSummary-item-info {
      color: #6B779B;
      font-size: 1.25em;
      font-weight: 600;
      margin: 0; }

.EventInfoSummary .name {
  color: #0080C4;
  font-size: 1.35rem;
  margin: 0 0.8rem 0.5rem 0; }

.EventInfoSummary .info {
  font-size: 0.85rem;
  font-weight: 400;
  margin-bottom: 0; }

.EventReport .ql-container {
  min-height: 20rem;
  flex: 1 1;
  display: flex;
  flex-direction: column; }

.EventReport .ql-editor {
  height: 100%;
  flex: 1 1;
  overflow-y: auto;
  width: 100%; }

.EventSummary .StatsConsole {
  clear: both;
  margin: 1rem 0 2rem; }

table.EventSummaryList th, table.EventSummaryList td {
  text-align: center; }
  table.EventSummaryList th.staff_name, table.EventSummaryList th.first_name, table.EventSummaryList th.last_name, table.EventSummaryList td.staff_name, table.EventSummaryList td.first_name, table.EventSummaryList td.last_name {
    text-align: left; }

.btn-print-pdf {
  float: right;
  color: #6B779B;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 0.95em; }
  .btn-print-pdf i {
    margin-right: 0.4em; }
  .btn-print-pdf:hover {
    color: rgba(107, 119, 155, 0.65);
    color: #00B5F3; }

.EventInvoicing .add-expense {
  background-color: #F2F2F2;
  padding: 1rem 1rem 0; }

.EventInvoicing .card-title {
  color: #6B779B;
  font-size: 0.98rem;
  font-weight: 600; }

table.EventInvoicingList th, table.EventInvoicingList td,
table.EventInvoicingTotal th,
table.EventInvoicingTotal td {
  text-align: left; }
  table.EventInvoicingList th.total_amount, table.EventInvoicingList th.total_label, table.EventInvoicingList td.total_amount, table.EventInvoicingList td.total_label,
  table.EventInvoicingTotal th.total_amount,
  table.EventInvoicingTotal th.total_label,
  table.EventInvoicingTotal td.total_amount,
  table.EventInvoicingTotal td.total_label {
    font-weight: bolder; }
  table.EventInvoicingList th.total_label, table.EventInvoicingList td.total_label,
  table.EventInvoicingTotal th.total_label,
  table.EventInvoicingTotal td.total_label {
    text-align: right; }
  table.EventInvoicingList th.created_date, table.EventInvoicingList th.description, table.EventInvoicingList td.created_date, table.EventInvoicingList td.description,
  table.EventInvoicingTotal th.created_date,
  table.EventInvoicingTotal th.description,
  table.EventInvoicingTotal td.created_date,
  table.EventInvoicingTotal td.description {
    width: 35%; }
  table.EventInvoicingList th.total_amount, table.EventInvoicingList th.amount, table.EventInvoicingList td.total_amount, table.EventInvoicingList td.amount,
  table.EventInvoicingTotal th.total_amount,
  table.EventInvoicingTotal th.amount,
  table.EventInvoicingTotal td.total_amount,
  table.EventInvoicingTotal td.amount {
    width: 30%; }
  table.EventInvoicingList th.total_label, table.EventInvoicingList td.total_label,
  table.EventInvoicingTotal th.total_label,
  table.EventInvoicingTotal td.total_label {
    width: 32%; }
  table.EventInvoicingList th.delete, table.EventInvoicingList td.delete,
  table.EventInvoicingTotal th.delete,
  table.EventInvoicingTotal td.delete {
    width: 10%; }

.row-print {
  height: 40px; }

.btn-print-pdf {
  float: right;
  color: #6B779B;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 0.95em; }
  .btn-print-pdf i {
    margin-right: 0.4em; }
  .btn-print-pdf:hover {
    color: rgba(107, 119, 155, 0.65);
    color: #00B5F3; }

.Event .form-wrapper {
  padding-top: 0;
  min-height: calc(100vh - 275px); }

.Event .page-header {
  margin-bottom: 0.8rem; }

.Event .cms-tabs + div {
  overflow: hidden; }

.event-datepicker {
  font-size: 0.8rem;
  line-height: 0.95rem; }
  .event-datepicker .react-datepicker__day,
  .event-datepicker .react-datepicker__time-name {
    line-height: 1.6; }
  .event-datepicker .react-datepicker__current-month,
  .event-datepicker .react-datepicker-time__header {
    line-height: 1.2;
    font-size: 1.25em; }
  .event-datepicker .react-datepicker__time-container {
    width: 92px; }
    .event-datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
      width: 92px; }
      .event-datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
        display: flex;
        align-items: center; }
  .event-datepicker .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 30%; }
  .event-datepicker .react-datepicker__input-time-container
.react-datepicker-time__input-container
.react-datepicker-time__input input {
    width: 94px; }
  .event-datepicker .react-datepicker__input-container input {
    background-size: 0.9em;
    font-size: 0.93em;
    text-align: center;
    padding-right: 1rem;
    padding-left: 0.28rem; }

.external-link-alt-custom-xsmall {
  color: #00B5F3;
  font-size: x-small; }

.external-link-alt-custom-small {
  color: #00B5F3;
  font-size: small;
  vertical-align: middle; }

.events-calendar-page {
  display: flex;
  flex-direction: column; }

.calendar-page-body h2 {
  text-align: center;
  color: #0080C4; }

.calendar-page-body .calendar-container .calendar-header {
  float: left;
  padding: 0;
  width: 14.25%;
  text-align: center;
  color: #6B779B;
  font-weight: 600; }

.calendar-page-body .calendar-container .calendar-day-wide {
  float: left; }

.calendar-page-body .calendar-container .wide-1 {
  width: 14.25%; }

.calendar-page-body .calendar-container .wide-2 {
  width: 28.5%; }

.calendar-page-body .calendar-container .wide-3 {
  width: 42.75%; }

.calendar-page-body .calendar-container .wide-4 {
  width: 57%; }

.calendar-page-body .calendar-container .wide-5 {
  width: 71.25%; }

.calendar-page-body .calendar-container .wide-6 {
  width: 85.5%; }

.calendar-page-body .calendar-container .current-day {
  background-color: #ced4da; }

.calendar-page-body .calendar-container .calendar-day {
  float: left;
  height: 240px;
  width: 14.25%;
  border: 1px solid #dee2e6; }
  .calendar-page-body .calendar-container .calendar-day .date {
    text-align: right;
    font-size: medium;
    font-weight: bolder;
    margin-top: 5px;
    margin-right: 5px; }
  .calendar-page-body .calendar-container .calendar-day .events {
    margin-bottom: 4px; }
    .calendar-page-body .calendar-container .calendar-day .events a {
      color: black;
      font-weight: 600; }
    .calendar-page-body .calendar-container .calendar-day .events .department {
      float: left;
      width: 48%;
      padding: 4px;
      margin: 2px; }
    .calendar-page-body .calendar-container .calendar-day .events .labels {
      font-size: smaller;
      font-weight: 600;
      min-height: 16px; }
      .calendar-page-body .calendar-container .calendar-day .events .labels .dept {
        float: left; }
      .calendar-page-body .calendar-container .calendar-day .events .labels .status {
        float: right; }
    .calendar-page-body .calendar-container .calendar-day .events .portland {
      background-color: #ccd5ae; }
    .calendar-page-body .calendar-container .calendar-day .events .seattle {
      background-color: #fcbf49; }
    .calendar-page-body .calendar-container .calendar-day .events .montana {
      background-color: #e7c6ff; }
    .calendar-page-body .calendar-container .calendar-day .events .eugene {
      background-color: #e5e5e5; }
    .calendar-page-body .calendar-container .calendar-day .events .yakima {
      background-color: #faedcd; }
    .calendar-page-body .calendar-container .calendar-day .events .spokane {
      background-color: #8ecae6; }
    .calendar-page-body .calendar-container .calendar-day .events .alaska {
      background-color: #ffc8dd; }
    .calendar-page-body .calendar-container .calendar-day .events .corporate {
      background-color: #e9edc9; }
  .calendar-page-body .calendar-container .calendar-day .view-all {
    font-size: smaller;
    text-align: right;
    font-weight: 200; }
    .calendar-page-body .calendar-container .calendar-day .view-all .btn {
      padding: 0; }

.modal-events-dates .modal-body .events {
  max-height: 800px; }
  .modal-events-dates .modal-body .events a {
    color: black;
    font-weight: 600;
    font-size: medium; }
  .modal-events-dates .modal-body .events .department {
    padding: 10px;
    margin: 2px;
    width: 210px;
    float: left;
    min-height: 160px; }
  .modal-events-dates .modal-body .events .labels {
    font-size: smaller;
    font-weight: 600;
    min-height: 16px; }
    .modal-events-dates .modal-body .events .labels .dept {
      float: left; }
    .modal-events-dates .modal-body .events .labels .status {
      float: right; }
  .modal-events-dates .modal-body .events .portland {
    background-color: #ccd5ae; }
  .modal-events-dates .modal-body .events .seattle {
    background-color: #fcbf49; }
  .modal-events-dates .modal-body .events .montana {
    background-color: #e7c6ff; }
  .modal-events-dates .modal-body .events .eugene {
    background-color: #e5e5e5; }
  .modal-events-dates .modal-body .events .yakima {
    background-color: #faedcd; }
  .modal-events-dates .modal-body .events .spokane {
    background-color: #8ecae6; }
  .modal-events-dates .modal-body .events .alaska {
    background-color: #ffc8dd; }
  .modal-events-dates .modal-body .events .corporate {
    background-color: #e9edc9; }

.public {
  font-size: 1.1rem; }
  .public h1 {
    color: #6B779B;
    font-size: 1.3rem;
    font-weight: 600; }
  .public h2 {
    color: #6B779B;
    font-size: 1.2rem;
    font-weight: 600; }
  .public p {
    color: #a3a3a3; }
  .public body {
    font-family: "Source Sans Pro", sans-serif;
    background: linear-gradient(to top, #FFFFFF 15%, #f3f3f3 90%); }
  .public .main {
    background-color: #fefefe;
    padding: 1.25rem 1.5rem;
    border-radius: 8px;
    margin: 0 auto;
    box-shadow: 2px 2px 4px rgba(128, 128, 128, 0.45); }
  .public .header {
    background-color: #ffffff;
    margin: 0 auto;
    padding: 70px 20px 20px 20px;
    text-align: center; }
  .public .content {
    background-color: #ffffff;
    margin: 0 auto;
    padding: 20px 20px 20px 20px; }
  .public .content-center {
    text-align: center; }
  .public .message {
    width: 100%; }
  .public .team {
    color: #6B779B;
    font-size: 1rem;
    font-weight: 500; }
  .public .rights {
    text-align: center;
    color: #6B779B;
    font-size: 0.75rem;
    font-weight: 400; }
  .public .disclaimer {
    text-align: center;
    color: #6B779B;
    font-size: 0.75rem;
    font-weight: 400;
    padding-top: 30px; }
  .public .btn {
    width: 180px;
    margin-left: 6px; }
  @media (min-width: 320px) {
    .public .main {
      width: 90%; }
    .public .content {
      padding: 0; }
    .public .btn {
      margin-top: 10px; } }
  @media (min-width: 481px) {
    .public .maint {
      width: 80%; }
    .public .btn {
      margin-top: 10px; } }
  @media (min-width: 641px) {
    .public .main {
      width: 70%; } }
  @media (min-width: 961px) {
    .public .main {
      width: 70%; } }
  @media (min-width: 1025px) {
    .public .main {
      width: 60%; } }
  @media (min-width: 1281px) {
    .public .main {
      width: 40%; } }

@media (min-width: 768px) {
  .page-wrapper {
    background-color: #fefefe;
    padding: 1.25rem 1.5rem;
    width: 97.5%;
    border-radius: 8px;
    margin: 0 auto;
    box-shadow: 2px 2px 4px rgba(128, 128, 128, 0.45);
    min-height: 84.5vh; } }

.page-wrapper .page-header {
  margin-bottom: 1rem; }

.page-wrapper .page-title {
  color: #0080C4;
  font-size: 1.85em;
  margin: 0; }

.page-wrapper .page-body {
  position: relative; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.btn {
  cursor: pointer;
  font-size: 1em; }
  .btn:disabled, .btn.disabled {
    cursor: default; }
  .btn:focus, .btn.focus {
    box-shadow: none; }
  .btn.btn-default {
    color: #00B5F3;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2); }
    .btn.btn-default:hover {
      background-color: #F2F2F2; }
  .btn.btn-primary {
    background-color: #77BF66;
    border-color: #77BF66; }
    .btn.btn-primary:hover, .btn.btn-primary:not(:disabled):not(.disabled):active {
      background-color: #5aab47;
      border-color: #5aab47; }
      .btn.btn-primary:hover:disabled, .btn.btn-primary:hover.disabled, .btn.btn-primary:not(:disabled):not(.disabled):active:disabled, .btn.btn-primary:not(:disabled):not(.disabled):active.disabled {
        background-color: #77BF66;
        border-color: #77BF66; }
  .btn.btn-outline-secondary {
    color: #6B779B;
    border-color: #6B779B; }
    .btn.btn-outline-secondary:hover {
      color: #FFFFFF;
      background-color: #6B779B;
      border-color: #6B779B; }
      .btn.btn-outline-secondary:hover:disabled, .btn.btn-outline-secondary:hover.disabled {
        color: #6B779B;
        border-color: #6B779B;
        background-color: transparent; }
  .btn.btn-secondary {
    color: #FFFFFF;
    background-color: #6B779B;
    border-color: #6B779B; }
    .btn.btn-secondary:hover {
      color: #FFFFFF;
      background-color: #555f7e;
      border-color: #555f7e; }
      .btn.btn-secondary:hover:disabled, .btn.btn-secondary:hover.disabled {
        color: #FFFFFF;
        background-color: #6B779B;
        border-color: #6B779B; }
  .btn.btn-color-primary {
    color: #FFFFFF;
    background-color: #00B5F3;
    border-color: #00B5F3; }
    .btn.btn-color-primary:hover {
      color: #FFFFFF;
      background-color: #008fc0;
      border-color: #008fc0; }
      .btn.btn-color-primary:hover:disabled, .btn.btn-color-primary:hover.disabled {
        color: #FFFFFF;
        background-color: #00B5F3;
        border-color: #00B5F3; }
  .btn.btn-color-primary-outline {
    color: #00B5F3;
    background-color: transparent;
    border-color: #00B5F3; }
    .btn.btn-color-primary-outline:hover {
      color: #FFFFFF;
      background-color: #00B5F3;
      border-color: #00B5F3; }
      .btn.btn-color-primary-outline:hover:disabled, .btn.btn-color-primary-outline:hover.disabled {
        color: #00B5F3;
        background-color: transparent;
        border-color: #00B5F3; }
  .btn.btn-terciary {
    color: #FFFFFF;
    background-color: #ff8341;
    border-color: #ff8341; }
    .btn.btn-terciary:hover {
      color: #FFFFFF;
      background-color: #ff620e;
      border-color: #ff620e; }
      .btn.btn-terciary:hover:disabled, .btn.btn-terciary:hover.disabled {
        color: #FFFFFF;
        background-color: #ff8341;
        border-color: #ff8341; }
  .btn.checkbox .fa-spinner {
    color: rgba(74, 74, 74, 0.6); }
  .btn.checkbox .fa-square {
    color: rgba(74, 74, 74, 0.6); }
  .btn.checkbox .fa-check-square {
    color: #00B5F3; }
  .btn.btn-datepicker {
    border: 1px solid #ced4da;
    padding: 0.25rem 0.3rem;
    padding-right: 20px;
    min-width: 150px;
    position: relative; }
    .btn.btn-datepicker .fa-calendar-day {
      font-size: 12px;
      position: absolute;
      margin: 0 0 0 0.3rem;
      color: #00B5F3;
      width: auto;
      right: 6%;
      top: 27%; }
    .btn.btn-datepicker.has-error {
      color: #dc3545;
      border-color: #dc3545; }

.badge.badge-primary {
  color: #FFFFFF;
  background-color: #00B5F3; }

.cms-tabs {
  margin-bottom: 1rem; }
  .cms-tabs .cms-tab {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 1rem;
    text-transform: inherit;
    min-width: 130px; }
    .cms-tabs .cms-tab:focus {
      outline: none; }
  .cms-tabs .cms-tabs-indicator {
    background-color: #0080C4; }

.cms-tabs-sm {
  margin-bottom: 1rem;
  max-width: 320px; }
  .cms-tabs-sm .cms-tab-sm {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 0.8rem;
    text-transform: inherit;
    min-width: 150px; }
    .cms-tabs-sm .cms-tab-sm:focus {
      outline: none; }
  .cms-tabs-sm .cms-tabs-indicator-sm {
    background-color: #0080C4; }

.SearchBox {
  width: 250px;
  margin-right: 1rem; }

.modal .modal-header {
  color: #6B779B;
  border-bottom: none;
  padding-bottom: 0.25rem; }
  .modal .modal-header .modal-title {
    font-size: 1.25rem; }

.table .td {
  vertical-align: middle; }

.table img {
  width: 1.8rem;
  border-radius: 50%; }

.table .border-none {
  border: none; }

.table-console {
  min-height: 50px; }
  .table-console .filter-department {
    width: 260px; }
    .table-console .filter-department .form-group {
      margin-bottom: 0; }
  .table-console .search {
    width: 250px; }

.btn-delete {
  color: #dc3545;
  background-color: transparent;
  border-color: transparent; }
  .btn-delete:hover {
    color: #FFFFFF;
    background-color: #dc3545; }

.btn-edit {
  color: #00B5F3;
  background-color: transparent;
  border-color: transparent; }
  .btn-edit:hover {
    color: #FFFFFF;
    background-color: #00B5F3; }

.form-not-editable-view .label {
  font-size: 1em; }

.form-not-editable-view .info {
  line-height: 2.2rem;
  font-size: 1.2rem;
  font-weight: 300; }
  .form-not-editable-view .info .icon {
    font-size: 1.5rem; }

.position-card {
  margin-bottom: 2rem; }
  .position-card .payrate-form-group .form-group {
    margin-bottom: 1.2rem;
    margin-top: 0.5rem; }
  .position-card .payrate-form-group label {
    font-size: 0.9rem; }
  .position-card .payrate-form-group .input-group-prepend .input-group-text {
    font-size: 0.85rem;
    line-height: 1; }
  .position-card .payrate-form-group .alert {
    position: absolute;
    border: none;
    border-radius: 0;
    font-size: 0.83rem;
    padding: 0;
    bottom: -1.4rem; }
    .position-card .payrate-form-group .alert.alert-danger {
      color: #dc3545;
      background-color: transparent; }

footer {
  padding: 0.5rem 0;
  color: #979797; }
  footer p {
    margin: 0 0.8rem; }

.pt-05 {
  padding-top: 0.5rem !important; }

.pt-1 {
  padding-top: 1rem !important; }

.StatsConsole {
  border: 1px solid rgba(74, 74, 74, 0.4);
  border-radius: 5px;
  width: 100%; }
  .StatsConsole .fa-spin {
    color: #6B779B;
    font-size: 0.8rem; }
  .StatsConsole .StatsConsole-item {
    padding: 0.8em 0 0.55em;
    text-align: center; }
    .StatsConsole .StatsConsole-item .StatsConsole-item-label {
      color: #a3a3a3;
      font-size: 0.95em;
      margin: 0; }
    .StatsConsole .StatsConsole-item .StatsConsole-item-info {
      color: #6B779B;
      font-size: 1.25em;
      font-weight: 600;
      margin: 0; }
    .StatsConsole .StatsConsole-item.current-hourly-rate .StatsConsole-item-info {
      color: #dc3545; }
      .StatsConsole .StatsConsole-item.current-hourly-rate .StatsConsole-item-info .fa-check {
        font-size: 0.75em; }
    .StatsConsole .StatsConsole-item.current-hourly-rate.on-target .StatsConsole-item-info {
      color: #77BF66; }

.PageNotFound .PageNotFound-wrapper .number {
  color: #00B5F3;
  font-size: 8rem;
  font-weight: 600;
  line-height: 1.3; }

.PageNotFound .PageNotFound-wrapper .img-wrapper {
  width: 120px;
  display: inline-block;
  margin: 0 0.8rem 0 1rem; }

.PageNotFound .PageNotFound-wrapper h1 {
  color: #00B5F3;
  text-align: center; }

.Notification .Notification-wrapper {
  width: 800px;
  margin: 0 auto; }
  .Notification .Notification-wrapper .logo-top {
    text-align: center;
    margin: 0 auto;
    width: 200px; }
  .Notification .Notification-wrapper .name {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.3;
    text-align: center;
    width: 800px;
    padding-top: 70px; }
  .Notification .Notification-wrapper .event {
    font-size: 1.3rem;
    line-height: 1;
    text-align: center;
    width: 800px; }
  .Notification .Notification-wrapper .title {
    color: #00B5F3;
    font-size: 3rem;
    font-weight: 600;
    line-height: 1.3;
    text-align: center;
    width: 800px;
    padding-bottom: 10px; }
  .Notification .Notification-wrapper .join {
    font-size: 1.3rem;
    line-height: 1;
    text-align: center;
    width: 800px; }
  .Notification .Notification-wrapper .content {
    font-size: 1.3rem;
    line-height: 1;
    text-align: center;
    width: 800px;
    padding-bottom: 40px; }
  .Notification .Notification-wrapper .buttons {
    margin: 0 auto;
    margin-top: 20px;
    width: 210px;
    padding-bottom: 40px; }
  .Notification .Notification-wrapper .terms {
    font-size: 1.2rem;
    line-height: 1;
    text-align: center;
    width: 800px; }
  .Notification .Notification-wrapper .logo-bottom {
    text-align: center;
    margin: 0 auto;
    width: 70px; }

