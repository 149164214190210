@import "../../../../../_mixins/variables";
@import "../../../../../_mixins/mixins";

.EventDatesNav {
  font-size: 1.15em;
  margin: 0.5rem 0;
  .btn {
    padding: 0.375rem 0.5rem;

    &:hover {
      color: $color-secundary;
    }
    &:disabled, &.disabled {
      opacity: 0.25;
      &:hover {
        color: inherit;
      }
    }
    &.btn-double-angle {
      padding: 0.375rem 0.25rem;
    }
  }
  .EventDatesNav-label {
    .day-counter {
      color: $color-secundary;
      text-transform: uppercase;
    }
  }
  .EventDates-select {
    width: 250px;
    text-align: center;
    text-align-last: center;
    -moz-text-align-last: center;
  }
}
