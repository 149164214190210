@import "../../../_mixins/variables";
@import "../../../_mixins/mixins";

.PositionsPayRateSettings {

  .PositionsPayRateSettingsFilters {
    margin-bottom: 1.5rem;
    padding: 0.25rem 0;
    .setting-label {
      color: $color-secundary;
      font-size: 0.98rem;
      font-weight: 600;
      text-transform: uppercase;
      margin: 0;
    }
    .form-group {
      margin: 0;
    }
  }

  .PositionsPayRateSettingsCard {
    margin-bottom: 2rem;

    .card-title {
      font-size: 1.1rem;
      i {
        margin-right: 0.25rem;
      }
    }

    .PositionsPayRateSettingsEdit {
      .form-group {
        margin: 0.5rem 0;
      }

      label {
        font-size: 0.9rem;
      }
      .input-group-prepend {
        .input-group-text {
          font-size: 0.85rem;
          line-height: 1;
        }
      }

      .alert {
        position: absolute;
        border: none;
        border-radius: 0;
        font-size: 0.83rem;
        padding: 0;
        bottom: -1.4rem;
    
        &.alert-danger {
          color: $color-danger;
          background-color: transparent;
        }
      }

      .read-only {
        p {
          line-height: 2rem;
          font-size: 1.3rem;
          font-weight: 300;
          margin-bottom: 0;
        }
      }
    }
  }

}
