@import "./_mixins/variables";
@import "./_mixins/mixins";

.page-wrapper {

  @include respondTo-small {
    background-color: #fefefe;
    padding: 1.25rem 1.5rem;
    width: 97.5%;
    border-radius: 8px;
    margin: 0 auto;
    box-shadow: 2px 2px 4px rgba($color-gray, 0.45);
    min-height: 84.5vh;
  }

  .page-header {
    margin-bottom: 1rem;
  }
  .page-title {
    color: $color-primary-dark;
    font-size:1.85em;
    margin: 0;
  }

  .page-body {
    position: relative;
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.btn {
  cursor: pointer;
  font-size: 1em;
  &:disabled,
  &.disabled {
    cursor: default;
  }
  &:focus, 
  &.focus {
    box-shadow: none;
  }
  &.btn-default {
    color: $color-primary;
    box-shadow: 0px 1px 3px rgba($color-black, 0.2);
    &:hover {
      background-color: $color-gray-light;
    }
  }
  &.btn-primary {
    background-color: $btn-primary;
    border-color: $btn-primary;
    &:hover,
    &:not(:disabled):not(.disabled):active {
      background-color: darken($btn-primary, 10);
      border-color: darken($btn-primary, 10);
      &:disabled,
      &.disabled {
        background-color: $btn-primary;
        border-color: $btn-primary;
      }
    }
  }
  &.btn-outline-secondary {
    color: $color-secundary;
    border-color: $color-secundary;
    &:hover {
      color: $color-white;
      background-color: $color-secundary;
      border-color: $color-secundary;
      &:disabled,
      &.disabled {
        color: $color-secundary;
        border-color: $color-secundary;
        background-color: transparent;
      }
    }
  }
  &.btn-secondary {
    color: $color-white;
    background-color: $color-secundary;
    border-color: $color-secundary;
    &:hover {
      color: $color-white;
      background-color: darken($color-secundary, 10);
      border-color: darken($color-secundary, 10 );
      &:disabled,
      &.disabled {
        color: $color-white;
        background-color: $color-secundary;
        border-color: $color-secundary;
      }
    }
  }
  &.btn-color-primary {
    color: $color-white;
    background-color: $color-primary;
    border-color: $color-primary;
    &:hover {
      color: $color-white;
      background-color: darken($color-primary, 10);
      border-color: darken($color-primary, 10 );
      &:disabled,
      &.disabled {
        color: $color-white;
        background-color: $color-primary;
        border-color: $color-primary;
      }
    }
  }
  &.btn-color-primary-outline {
    color: $color-primary;
    background-color: transparent;
    border-color: $color-primary;
    &:hover {
      color: $color-white;
      background-color: $color-primary;
      border-color: $color-primary;
      &:disabled,
      &.disabled {
        color: $color-primary;
        background-color: transparent;
        border-color: $color-primary;
      }
    }
  }
  &.btn-terciary {
    color: $color-white;
    background-color: $color-terciary;
    border-color: $color-terciary;
    &:hover {
      color: $color-white;
      background-color: darken($color-terciary, 10);
      border-color: darken($color-terciary, 10 );
      &:disabled,
      &.disabled {
        color: $color-white;
        background-color: $color-terciary;
        border-color: $color-terciary;
      }
    }
  }
  &.checkbox {
    .fa-spinner {
      color: rgba($primary-font-color, 0.6);
    }
    .fa-square {
      color: rgba($primary-font-color, 0.6);
    }
    .fa-check-square {
      color: $color-primary;
    }
  }

  &.btn-datepicker {
    border: 1px solid $color-border-input;
    padding: 0.25rem 0.3rem;
    padding-right: 20px;
    min-width: 150px;
    position: relative;

    .fa-calendar-day {
      font-size: 12px;
      position: absolute;
      margin: 0 0 0 0.3rem;
      color: $color-primary;
      width: auto;
      right: 6%;
      top: 27%;
    }

    &.has-error {
      color: $color-danger;
      border-color: $color-danger;
    }
  }
}

.badge {
  &.badge-primary {
    color: $color-white;
    background-color: $color-primary;
  }
}

.cms-tabs {
  margin-bottom: 1rem;
  .cms-tab {
    font-family: $primary-font-family;
    font-size: 1rem;
    text-transform: inherit;
    min-width: 130px;
    &:focus {
      outline: none;
    }
  }
  .cms-tabs-indicator {
    background-color: $color-primary-dark;
  }
}

.cms-tabs-sm {
  margin-bottom: 1rem;
  max-width: 320px;
  .cms-tab-sm {
    font-family: $primary-font-family;
    font-size: 0.8rem;
    text-transform: inherit;
    min-width: 150px;
    &:focus {
      outline: none;
    }
  }
  .cms-tabs-indicator-sm {
    background-color: $color-primary-dark;
  }
}


.SearchBox {
  width: 250px;
  margin-right: 1rem;
}

// MODAL
.modal {

  .modal-header {
    color: $color-secundary;
    border-bottom: none;
    padding-bottom: 0.25rem;
    
    .modal-title{
      font-size: 1.25rem;
    }
  }
}

// TABLE
.table {
  .td {
    vertical-align: middle;
  }
  img {
    width: 1.8rem;
    border-radius: 50%;
  }
  .border-none {
    border: none;
  }
}

// TABLE CONSOLE LIST
.table-console {
  min-height: 50px;
  .filter-department {
    width: 260px;
    .form-group {
      margin-bottom: 0;
    }
  }
  .search {
    width: 250px;
  }
}

// TABLE LIST BUTTONS
.btn-delete {
  color: $color-danger;
  background-color: transparent;
  border-color: transparent;
  &:hover {
    color: $color-white;
    background-color: $color-danger;
  }
}

.btn-edit {
  color: $color-primary;
  background-color: transparent;
  border-color: transparent;
  &:hover {
    color: $color-white;
    background-color: $color-primary;
  }
}

.form-not-editable-view  {
  .label {
    font-size: 1em;
  }
  .info {
    line-height: 2.2rem;
    font-size: 1.2rem;
    font-weight: 300;
    .icon {
      font-size: 1.5rem;
    }
  }
}

// POSITIONS PAY RATES CARD
.position-card {
  margin-bottom: 2rem;
  .payrate-form-group {
    .form-group {
      margin-bottom: 1.2rem;
      margin-top: 0.5rem;
    }

    label {
      font-size: 0.9rem;
    }
    .input-group-prepend {
      .input-group-text {
        font-size: 0.85rem;
        line-height: 1;
      }
    }

    .alert {
      position: absolute;
      border: none;
      border-radius: 0;
      font-size: 0.83rem;
      padding: 0;
      bottom: -1.4rem;
  
      &.alert-danger {
        color: $color-danger;
        background-color: transparent;
      }
    }
  }
}

footer {
  padding: 0.5rem 0;
  color: lighten($primary-font-color, 30);
  p {
    margin: 0 0.8rem;
  }
}


//** GLOBALS HELPERS
// ***************************************************************
.pt-05 {
  padding-top: 0.5rem !important;
}
.pt-1 {
  padding-top: 1rem !important;
}

//** GLOBALS COMPONENTS STYLES
// ***************************************************************

.StatsConsole {
  border: 1px solid rgba($primary-font-color, 0.4);
  border-radius: 5px;
  width: 100%;
  
  .fa-spin {
    color: $color-secundary;
    font-size: 0.8rem;
  }

  .StatsConsole-item {
    padding: 0.8em 0 0.55em;
    text-align: center;
    .StatsConsole-item-label {
      color: lighten($primary-font-color, 35);
      font-size: 0.95em;
      margin: 0;
    }
    .StatsConsole-item-info {
      color: $color-secundary;
      font-size: 1.25em;
      font-weight: 600;
      margin: 0;
    }

    &.current-hourly-rate {
      .StatsConsole-item-info {
        color: $color-danger;
        .fa-check  {
          font-size: 0.75em;
        }
      }

      &.on-target {
        .StatsConsole-item-info {
          color: $color-success;
        }
      }
    }
  }
}

.PageNotFound {
  .PageNotFound-wrapper {
    .number {
      color: $color-primary;
      font-size: 8rem;
      font-weight: 600;
      line-height: 1.3;
    }
    .img-wrapper {
      width: 120px;
      display: inline-block;
      margin: 0 0.8rem 0 1rem;
    }
    h1 {
      color: $color-primary;
      text-align: center;
    }
  }
}

.Notification {
  .Notification-wrapper {
    width: 800px;
    margin: 0 auto;

    .logo-top {
      text-align: center;
      margin: 0 auto;
      width: 200px;
    }
    .name {
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 1.3;
      text-align: center;
      width: 800px;
      padding-top: 70px;
    }
    .event {
      font-size: 1.3rem;
      line-height: 1;
      text-align: center;
      width: 800px;
    }
    .title {
      color: $color-primary;
      font-size: 3rem;
      font-weight: 600;
      line-height: 1.3;
      text-align: center;
      width: 800px;
      padding-bottom: 10px;
    }
    .join {
      font-size: 1.3rem;
      line-height: 1;
      text-align: center;
      width: 800px;
    }
    .content {
      font-size: 1.3rem;
      line-height: 1;
      text-align: center;
      width: 800px;
      padding-bottom: 40px;
    }
    .buttons {
      margin: 0 auto;
      margin-top: 20px;
      width: 210px;
      padding-bottom: 40px;
    }
    .terms {
      font-size: 1.2rem;
      line-height: 1;
      text-align: center;
      width: 800px;
    }
    .logo-bottom {
      text-align: center;
      margin: 0 auto;
      width: 70px;
    }
  }
}